footer {
    background-color: #010a1b;
    color: $color-white;

    .footer-contents-area {
        padding: 50px 0;

        .information {
            text-align: center;

            dl {
                margin-top: 30px;
                text-align: left;
                display: inline-block;
                margin-left: auto;
                margin-right: auto;

                dt {
                    font-weight: bold;
                }

                dd {
                    margin-top: 15px;
                    line-height: 1.4;

                    span {
                        display: block;
                        margin-left: 0;
                        margin-top: 10px;
                        font-size: 12px;
                        letter-spacing: -1px;

                        a {
                            display: block;
                            padding: 10px 20px;
                            height: inherit;
                            line-height: 1;
                            min-width: 0;
                            font-size: 16px;
                            max-width: 200px;
                            margin-top: 20px;
                            margin-left: auto;
                            margin-right: auto;
                            @media only screen and (min-width: 660px) {
                              display: inline-block;
                              max-width: none;
                              padding: 10px 10px;
                              position: relative;
                              top: -10px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            margin-top: 30px;

            > div {
                border: 1px solid $color-white;
                text-align: center;
                padding: 20px 0;
                width: 100%;

                .title {
                    font-weight: bold;

                    &::after {
                        content: '';
                        display: block;
                        width: 26px;
                        margin: 12px auto 23px;
                        border-bottom: 1px solid $color-white;
                    }
                }
            }

            .tel {

                dt {
                    font-weight: bold;
                    font-size: 22px;
                    font-family: $font-roboto;

                    a {
                        color: $color-white;
                    }
                }

                dd {
                    font-size: 14px;
                    margin-top: 15px;
                }
            }

            .mail {
                margin-top: 20px;

                .link {
                    width: 200px;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 14px;
                    font-weight: bold;

                    a {
                        display: block;
                        padding: 10px 0;
                        width: 100%;
                        height: auto;

                        .icon {
                            width: 16px;
                            height: auto;
                            margin: 0 8px 0 0;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    .footer-nav-copyright-area {
        border-top: 1px solid #4c5363;
        text-align: center;
        padding: 11px 0 14px;

        .copyright {
            opacity: 0.7;
            font-size: 13px;
            font-family: $font-roboto;
        }
    }
}
@media only screen and (min-width: 660px) {
    footer {

        .footer-contents-area {

            .information {

                dl {

                    dd {

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 35px;
                            margin-top: 0;
                        }
                    }
                }
            }

            .contact {
                display: flex;
                justify-content: center;

                > div {
                    width: 250px;
                }

                .mail {
                    margin-left: 25px;
                    margin-top: 0;
                }
            }
        }
    }
}
@media only screen and (min-width: 770px) {
    footer {

        .footer-contents-area {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            ]padding: 70px 0;

            .information {
                text-align: left;

                dl {

                    dd {

                        span {
                            display: block;
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .contact {
                justify-content: flex-start;
                margin-top: 0;
            }
        }
    }
}
@media only screen and (min-width: 1300px) {
    footer {

        .footer-contents-area {

            .information {

                dl {

                    dd {

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 35px;
                            margin-top: 0;
                        }
                    }
                }
            }

            .contact {

                > div {
                    width: 300px;
                }

                .mail {
                    margin-left: 40px;
                }
            }
        }
    }
}















/* footer */
/*----------------------------------------------------------------------------*/

.back-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10000;
    transition: opacity 0.5s ease-in-out 0s,
                transform 0.5s ease-in-out 0s;

    opacity: 1.0;
    transform: translate(0, 0);

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        bottom: 40px;
        right: 40px;
    }
    @media only screen and (min-width: 1000px) {

    }
    .btn {
        padding: 10px;
        // border-radius: 5px;
        // background: $color-link;
        // color: rgba(30, 62, 72, 0.75);
        color: #5a676b;
        font-size: 10px;
        line-height: 1;
        box-shadow: none;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        // border: 1px solid rgba(30, 62, 72, 0.75);
        border: 1px solid #5a676b;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        &:not(.btn-sm)::before {
            display: none;
        }
        &:not(.btn-sm)::after {
            // margin: 0 auto 5px auto;
            margin: auto;
            transform: none;
            position: static;

            font-size: 24px;
        }

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            &:not(.btn-sm):hover {
                // background: $color-link-hover;
                color: #3db5da;
                border-color: #3db5da;

                &::after {
                    transform: translate(0, -10px);
                }
            }
        }
        @media only screen and (min-width: 1000px) {

        }
    }
}

.back-top.inactive {
    opacity: 0;
    transform: translate(0, 200px);
}
.back-top.mode-footer {
    position: absolute;
    // margin-bottom: 10px;

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        // margin-bottom: 40px;
    }
    @media only screen and (min-width: 1000px) {

    }
}
