/*
*:first-child {
    margin-top: 0;
}
*:last-child {
    margin-bottom: 0;
}
*/
html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;
}

article,
.lead {
    padding: 80px 0;
}

main {

    .main-header {
    }

    .main-footer {
    }
}

section {

    padding: 40px 0 40px;

    &.section-large {
        padding: 40px 0;
    }

    @media only screen and (min-width: 660px) {
        padding: 70px 0 60px;

        &.section-large {
            padding: 70px 0;
        }
    }

    @media only screen and (min-width: 770px) {
        padding: 90px 0 70px;

        &.section-large {
            padding: 90px 0;
        }
    }

    @media only screen and (min-width: 1200px) {
        padding: 150px 0 100px;

        &.section-large {
            padding: 150px 0;
        }
    }
}

// section 2階層目
article section,
section section {
}

// section 3階層目
article section section,
section section section {
}

/* .container */
/*----------------------------------------------------------------------------*/
.container,
.container-md {
    padding-left: 20px;
    padding-right: 20px;
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1200px) {
    .container-md {
        margin: 0 auto;
        max-width: 1100px;
    }
}
@media only screen and (min-width: 1400px) {
    .container {
        margin: 0 auto;
        max-width: 1300px;
    }
}










.layout-group > *:not(:last-child) {
    margin-bottom: 20px;
}

.layout-group > *,
.layout {
}

.layout-lg {
    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
    }
}
