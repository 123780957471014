@media only screen and (min-width: 0px) and (max-width: 769px) {
    // スマホ

    header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        background: rgba(0, 0, 0, 0.3);

        .logo-nav {

            .logo {
                display: block;
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 50000;
                width: 216px;

                a {
                    display: block;
                }
            }
        }

        .global-nav-btn {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            bottom: 0;
            z-index: 1000;
            width: 50px;
            height: 50px;
        }

        .global-nav {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: scroll;
            box-sizing: border-box;
            padding-top: 80px;
            padding-bottom: 80px;
            opacity: 1;
            text-align: center;
            background-color: #010a1b;

            .nav {
              margin-top: 50px;
              @media only screen and (min-width: 660px) {
                margin-top: 0;
              }

                ul {
                    padding: 0;

                    li {
                        list-style-type: none;

                        a {
                            color: $color-white;
                            display: block;
                            padding: 20px 0;
                        }
                    }

                    & > li.contact-btn {
                        background-color: $color-red;
                        border-radius: 2px;
                        display: inline-block;
                        margin-top: 20px;
                        @media only screen and (min-width: 660px) {
                          margin-top: 10px;
                        }

                        a {
                            padding: 15px 35px;
                            height: auto;

                            .icon {
                                width: 16px;
                                height: auto;
                                margin: 5px 8px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    html:not(.global-nav-active) {
        .global-nav {
            opacity: 0;
            pointer-events: none;
        }

        .nav-copyright-area {
            display: none;
        }
    }

    html.global-nav-active {
        overflow: hidden;

        .global-nav {
        }

        .nav-copyright-area {
            position: fixed;
            bottom: 0;
            z-index: 1000;
            width: 100%;
            background-color: #ffffff;

            border-top: 1px solid rgba(34,34,34,0.9);

            .copyright {
                display: block;
                padding: 18px;
                font-size: 14px;
                line-height: 1;
                text-align: center;
            }
        }
    }
}
@media only screen and (min-width: 0px) and (max-width: 660px) {
    header {
        height: 60px;

        .logo-nav {

            .logo {
                top: 15px;
                left: 15px;
                width: 166px;
            }
        }

        .global-nav-btn {
            top: 6px;
            right: 6px;
        }
    }
}
@media only screen and (min-width: 770px) {
    header {
        transition: all 0.5s ease-out 0.0s;
        width: 100%;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 40px;
        height: 105px;
        background: rgba(0, 0, 0, 0.0);

        &.inactive {
            transition: all 0.3s ease-out 0.0s;
            height: 70px;
            background: rgba(0, 0, 0, 0.3);
        }

        .logo-nav {

            .logo {
                display: inline-block;

                a {
                    margin-left: auto;
                    margin-right: auto;
                    width: 216px;
                    height: auto;

                    img {
                        width: 216px;
                        display: block;
                    }
                }
            }
        }
        .global-nav-btn {
            display: none;
        }
        .global-nav {
            margin-left: auto;

            .nav {
                display: block;

                .logo-sp {
                    display: none;
                }

                ul {
                    margin: 0;
                    padding-left: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    & > li {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        line-height: 1;
                        text-align: right;

                        a {
                            line-height: 1;
                            opacity: 1.0;
                            color: $color-white;
                            display: flex;
                            align-items: center;
                            height: $header-height-pc;
                            box-sizing: border-box;
                            position: relative;
                        }
                        a:hover {
                            opacity: 0.5;
                        }
                    }
                    & > li:not(:last-child) {
                        margin-right: 40px;
                    }

                    & > li.current {
                        a {
                            &::before {
                                content: "";
                                width: 100%;
                                height: 2px;
                                background: $color-white;
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: -15px;
                            }
                        }
                    }

                    & > li.contact-btn {

                        a {
                            padding: 15px 35px;
                            height: auto;

                            &:hover {
                                opacity: 1;
                            }

                            .icon {
                                width: 16px;
                                height: auto;
                                margin: 5px 12px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    header {
        .global-nav {

            .nav {

                ul {

                    & > li:not(:last-child) {
                        margin-right: 50px;
                    }
                }
            }
        }
    }
}












/* アイコン */
.global-nav-btn {
    padding: 0;
    width: 32px;
    height: 32px;
    border: none;
    display: block;
    position: relative;

    span {
        margin: auto;
        width: 30px;
        height: 2px;
        border-radius: 1px;
        background: $color-white;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all 0.15s ease-in-out 0s;
    }
    span:nth-child(1) {
        transform: translate(0px, -10px);
    }
    span:nth-child(4) {
        transform: translate(0px, 10px);
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {
        display: none;
    }
}
html.global-nav-active {
    .global-nav-btn {
        span:nth-child(1) {
            transform: translate(0px, -42px);
            opacity: 0;
        }
        span:nth-child(4) {
            transform: translate(0px, 42px);
            opacity: 0;
        }
        span:nth-child(2) {
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}
