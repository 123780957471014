// 背景色
body {
    background: #fff;
}
.bg-white {
    background-color: #fff;
}
.bg-gray {
    background-color: #f4f4f4;
}
.bg-black {
    background-color: $color-bg-black;
    color: #fff;

    .module-group > a,
    .border-group > a,
    .card-group > a {
        color: #fff;
    }
    a.module,
    a.border,
    a.card {
        color: #fff;
    }

    .border-group > *,
    .border {
        border-color: #fff;
    }
}
