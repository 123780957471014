
// ==================================================
// 個人情報保護方針
// ==================================================



.is-privacy {

  .privacy-content {
    padding-top: 20px;
    @media only screen and (min-width: 660px) {
      padding-top: 25px;
      padding-right: 5px;
      padding-left: 5px;
    }


    .text-lead {
      font-size: 16px;
      padding-bottom: 30px;
      line-height: 2;
      @media only screen and (min-width: 660px) {
        font-size: 20px;
        padding-bottom: 36px;
      }
    }

    .privacy-group {
      padding-bottom: 10px;
      @media only screen and (min-width: 660px) {
        padding-bottom: 50px;
      }
    }
  }

}
