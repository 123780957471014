html {
    .fc {
        .fc-view-container {
            .fc-view {
                .fc-widget-header {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;

                    th.fc-day-header {
                        background: none;
                        border-color: transparent;
                        font-weight: bold;
                    }
                    th.fc-sat {
                        color: #3c83af;
                    }
                    th.fc-sun {
                        color: #a23737;
                    }
                }
            }
        }

        .fc-day-grid-event {
            margin: 0;
        }

        .fc-day-top {
            text-align: center;

            .fc-day-number {
                float: none;
            }
        }
        .fc-day-top.fc-past {
            opacity: 0.25;
        }

        /* 文字 */
        .fc-content-skeleton {
            padding: 0;

            table,
            thead,
            tbody,
            tfoot,
            tr,
            th,
            td {
                border: none;
            }

            .fc-event-container {
                a.fc-event,
                a.fc-event-dot {
                    background-color: transparent;
                    border: none;
                }
                a.fc-event {
                    text-align: center;
                    font-size: 16px;
                }


                /* 青 */
                a.fc-event.possible,
                a.fc-event.possible:hover {
                    color: #3c83af;
                }

                /* 赤 */
                a.fc-event.few,
                a.fc-event.few:hover {
                    color: #a23737;
                }

                a.fc-event.few {
                    .fc-title::before {
                        content: "残";
                        margin-right: 0.5em;
                        color: $color-text;
                        font-size: 0.8em;
                        display: inline-block;
                    }
                }

                a.fc-event.past {
                    color: transparent !important;
                }

                /* グレー */
                a.fc-event.impossible,
                a.fc-event.impossible:hover,
                a.fc-event.closed,
                a.fc-event.closed:hover {
                    color: #999;
                }
            }
        }

        .fc-bgevent-skeleton {
            td.fc-bgevent {
                background: none;
            }
        }

        /* 背景色 */
        .fc-bg {
            /* 青 */
            td.bg-possible {
                background: #e7f1f7;
            }
            /*td.bg-possible:hover,*/
            td.bg-possible.active {
                background: #cde5f3;
            }

            /* 赤 */
            td.bg-few {
                background: #f8e6e6;
            }
            /*td.bg-impossible:hover,
            td.bg-few:hover,*/
            td.bg-impossible.active,
            td.bg-few.active {
                background: #fdd2d2;
            }

            /* 黄 */
            td.fc-today {
                background: #fffacc;
            }
            /*td.fc-today:hover,*/
            td.fc-today.active {
                background: #fff8b9;
            }

            /* グレー */
            td.fc-past,
            td.bg-impossible,
            td.bg-closed {
                background: #f2f2f2 !important;
            }

            td.fc-disabled-day {
                background: #ddd;
                opacity: 1.0;
            }
        }

        .fc-toolbar.fc-header-toolbar {
            .fc-left,
            .fc-right,
            .fc-center {
                margin-bottom: 1em;
                display: block;

                &,
                & > * {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    float: none;
                }
            }
            .fc-center {
                & > *:not(:last-child) {
                    margin-right: 1em;
                }
            }

            @media only screen and (min-width: 480px) {
                display: flex;
                align-items: center;

                .fc-left,
                .fc-right,
                .fc-center {
                    margin-bottom: 0;

                    &,
                    & > * {
                        margin: 0;
                        text-align: initial;
                    }
                }

                .fc-left {
                    margin-right: auto;
                }
            }
            @media only screen and (min-width: 660px) {

            }
            @media only screen and (min-width: 770px) {

            }
            @media only screen and (min-width: 1000px) {

            }
        }

        .fc-toolbar h2 {
            font-size: 1.5rem;
        }

        .fc-state-default,
        .fc-state-default.fc-corner-left,
        .fc-state-default.fc-corner-right {
            outline: none;

            border: none;
            font-size: 0.85em;
            text-align: center;
            line-height: 1;
            box-shadow: 0 0 0 2px $color-btn inset;
            display: inline-block;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            /*  */
            padding: 0.75em 1.5em;
            border-radius: calc(1em + 1em + 1em);

            /*  */
            background-color: $color-btn;
            color: #fff;

            &:hover {
                background-color: transparent;
                color: $color-btn;
            }

            /* 不要記述削除 */
            float: none;
            height: auto;
            text-shadow: none;
            background-image: none;

            transition: opacity 0.15s ease-in-out 0s,
                        background 0.15s ease-in-out 0s,
                        background-color 0.15s ease-in-out 0s,
                        border-radius 0.15s ease-in-out 0s,
                        border-color 0.15s ease-in-out 0s,
                        color 0.15s ease-in-out 0s,
                        box-shadow 0.15s ease-in-out 0s,
                        text-shadow 0.15s ease-in-out 0s,
                        outline 0.15s ease-in-out 0s;
        }

        .fc-state-disabled,
        .fc-state-disabled:hover,
        .fc-state-disabled.fc-state-hover,
        .fc-state-disabled.fc-state-hover:hover {
            background-color: $color-base;
            color: #fff;
        }
        .fc-state-hover {
            background-position: initial;
        }

        .fc-scroller {
            overflow-x: initial !important;
            overflow-y: initial !important;
            height: initial !important;
        }

        &.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            float: none;
        }
        &.fc .fc-row .fc-content-skeleton table,
        &.fc .fc-row .fc-content-skeleton td,
        &.fc .fc-row .fc-helper-skeleton td {
            text-align: center;
            vertical-align: middle;
        }
    }
}
