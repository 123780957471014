/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.blog-list {

    display: flex;
    flex-wrap: wrap;

    .card {
        padding: 20px;
        background: $color-white;
        color: $color-text;
        width: 100%;
        margin: 40px 0 0 0;
        box-sizing: border-box;

        & > * {
            display: block;
        }
        & > *:not(:last-child) {
            margin-bottom: 15px;
        }

        .thumbnail:first-child {
            margin-top: -20px;
            margin-left: -20px;
            margin-right: -20px;
        }
        .thumbnail:last-child {
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: -20px;
        }

        .content {
            position: relative;

            &::after {
                content: '詳しく→';
                display: block;
                font-size: 14px;
                color: $color-bg-blue;
                margin-top: 10px;
                text-align: right;
            }

            > * {
                display: block;
            }
        }

        .category {
            position: absolute;
            top: -33px;
            background-color: $color-bg-blue;
            color: $color-white;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            padding: 10px 10px;
            border-radius: 2px;
        }

        .data {
            text-align: right;
            font-size: 14px;
            color: #C6C6C6;
            font-family: $font-roboto;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            line-height: $line-height-title;
            margin-top: 10px;
        }

        .text {
            font-size: 14px;
            line-height: $line-height-text;
            margin-top: 10px;
        }
    }

    @media only screen and (min-width: 660px) {

        margin: 0 0 0 -40px;

        .card {
            width: calc((100% - 80px) / 2);
            margin: 40px 0 0 40px;

            .category {
                top: -39px;
                font-size: 15px;
                padding: 15px 10px;
            }

            .title {
                font-size: 20px;
            }

            .text {
                font-size: 16px;
            }
        }
    }

    @media only screen and (min-width: 770px) {

        .card {
            width: calc((100% - 120px) / 3);
        }
    }

    @media only screen and (min-width: 1200px) {
        margin: 0 0 0 -60px;

        .card {
            width: calc((100% - 180px) / 3);
            margin: 60px 0 0 60px;
        }
    }

    .is-inactive {
      transform: translate(0,10%);
      transition : all 1500ms;
      display: none;
      opacity: 0;
      
      &.is-active {
        opacity: 1;
        transform: translate(0,0);
        .thumbnail img {
          opacity: 1;
        }
      }
    }


}





/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    width: 100%;
    display: none;
    align-items: center;
    box-sizing: border-box;

    ol {
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 0;
            display: block;
            white-space: nowrap;
        }
        li + li::before {
            content: ">";
            padding: 0 0.5em;
            display: inline-block;
        }
    }

    @media only screen and (min-width: 480px) {
        display: flex;
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
    @media only screen and (min-width: 1300px) {

    }
}



/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    // border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .progress-bar {
        background: $color-base;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/
.step-group {
    display: flex;
    // justify-content: space-between;

    position: relative;

    .step {
        flex: 1 1 0%;
        text-align: center;
        position: relative;
        cursor: default;

        .badge {
            margin: 0 auto 1em auto;
            padding: 0;
            width: 40px;
            height: 40px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: rgba(0, 0, 0, 0.2);
            font-size: 1.0em;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            z-index: 1000;
        }
        .content {
            color: $color-text;
            font-size: 12px;
        }

        &.active {
            .badge {
                background-color: $color-base;
                box-shadow: 0 0 0 1px $color-base inset;
                color: #fff;
            }
            .content {
                color: $color-base;
            }
        }
        &:hover {
            opacity: 1.0;
        }
    }

    .step:not(:first-child) {
        &::before,
        &::after {
            content: "";
            height: 2px;
            display: block;
            position: absolute;
            top: calc(40px / 2 - 2px / 2);
            left: -50%;
            z-index: 0;
        }

        &::before {
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
        }
        &::after {
            width: 0%;
            background: $color-base;
            transition: width 0.2s ease-in-out 0.0s;
        }
    }
    .step.active:not(:first-child) {
        /*
        &::before {
            background: $color-base;
        }
        */
        &::after {
            width: 100%;
        }
    }
}

/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;

    section& {
        display: block;
    }
}

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: $color-base;
    color: #fff;

    table {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            border-color: #fff;
        }
    }
}
.box.box-white {
    background-color: #fff;
    color: $color-base;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;

    }
    .box-md {
        // padding: 60px;
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        // padding: 100px;
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

/*
.box-title {
    height: auto;
}
*/

.box-text {
    min-height: 200px;
    height: auto;

    .title {
        margin-bottom: 15px;
        line-height: 1;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {
        max-width: 400px;
    }
    @media only screen and (min-width: 770px) {
        .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
    @media only screen and (min-width: 1200px) {

    }
    @media only screen and (min-width: 1400px) {

    }
    @media only screen and (min-width: 1600px) {

    }
}


.box {
    .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }
}


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px $color-base inset;
    box-sizing: border-box;

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        width: 160px;
        height: 160px;
    }
    @media only screen and (min-width: 1000px) {

    }

    /* aタグの装飾をキャンセル */
    html body & {
        border: none !important;
    }

    /* ボタンのサイズ */
    &.circle-lg {

    }
    &.circle-sm {
        width: 70px;
        height: 70px;
    }

    &,
    &.circle-fill {
        background-color: $color-btn;
        color: #fff;

        a&:hover {
            background-color: transparent;
            color: $color-btn;
        }
    }
    &.circle-outline {
        background-color: transparent;
        color: $color-btn;

        a&:hover {
            background-color: $color-btn;
            color: #fff;
        }
    }
    &.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        a&:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;
        }
    }

    & > span {
        display: block;
        text-align: center;
        line-height: 1.2;

        span {
            display: inline-block;
        }

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            line-height: 1.5;
        }
        @media only screen and (min-width: 1000px) {

        }
    }
}
