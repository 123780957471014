/* タグ、バッジ、ラベル(Semantic UI) */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* https://semantic-ui.com/elements/label.html */
/*----------------------------------------------------------------------------*/

.badge-group {
    margin: -0.25em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
        margin: 0.25em;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}

.badge {
    // padding: 0.5em 1.0em;
    // background: #aaa;
    // color: #fff;
    border: none;
    font-size: 12px;
    text-align: center;
    line-height: 1;
    box-shadow: 0 0 0 1px $color-badge inset;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    /* ボタンのサイズ */
    &.badge-lg {
        font-size: 1rem;
    }
    &.badge-sm {
        font-size: 10px;
    }

    /* ボタンの形 */
    &,
    &.badge-rect {
        padding: 0.5em 1.0em;
        border-radius: 4px;
    }
    &.badge-pill {
        padding: 0.5em 1.0em;
        border-radius: calc(1em + 1em + 1em);
    }
    &.badge-circle {
        padding: 0;
        width: 2em;
        height: 2em;
        border-radius: 2em;
    }

    /* ボタンの枠線、背景色 */
    &,
    &.badge-fill {
        background-color: $color-badge;
        color: #fff;

    }
    &.badge-outline {
        background-color: transparent;
        color: $color-badge;
    }
}

.badge-danger {
    box-shadow: 0 0 0 1px $color-danger inset;

    &,
    &.badge-fill {
        background-color: $color-danger;
        color: #fff;

    }
    &.badge-outline {
        background-color: transparent;
        color: $color-danger;
    }
}
.badge-warning {
    box-shadow: 0 0 0 1px $color-warning inset;

    &,
    &.badge-fill {
        background-color: $color-warning;
        color: #fff;

    }
    &.badge-outline {
        background-color: transparent;
        color: $color-warning;
    }
}
.badge-success {
    box-shadow: 0 0 0 1px $color-success inset;

    &,
    &.badge-fill {
        background-color: $color-success;
        color: #fff;

    }
    &.badge-outline {
        background-color: transparent;
        color: $color-success;
    }
}
