// ==================================================
// ニュース・コラム
// ==================================================

.is-news {
  background-color: $color-bg;

  .l-category-select {
    padding-bottom: 0;
  }
  .list-category {
    .list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      border-color: #E5E5E5;
      border-style: solid;
      border-top-width: 1px;
      border-left-width: 1px;
      box-sizing: border-box;
      li {
        list-style: none;
        width: 50%;
        border-color: #E5E5E5;
        border-bottom-width: 1px;
        border-right-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        @media only screen and (min-width: 660px) {
          width: 33.33%;
        }
      }
    }

    .link {
      display: block;
      background-color: #fff;
      color: $color-bg-blue;
      text-decoration: none;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 5px;
      text-align: center;
      transition: 0.5s;
      @media only screen and (min-width: 660px) {
        font-size: 18px;
        height: 70px;
        line-height: 70px;
      }
      &.current {
        background-color: $color-bg-blue;
        color: #fff;
      }
      &:hover {
        @media only screen and (min-width: 1200px) {
          background-color: $color-bg-blue;
          color: #fff;
        }
      }
    }

  }

  .section-large {
    padding-top: 0;
  }

  .wrap-btn {
    text-align: center;
    padding: 60px 0 170px;
    width: 100%;
  }






}
