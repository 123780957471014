.mainvisual {

    width: 100vw;
    height: 100vh;
    position: relative;

    .text {
        z-index: 10000;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%); /* Safari用 */
        transform: translate(-50%, -50%);
        color: $color-white;
        font-size: 28px;
        font-weight: bold;
        font-family: $font-mincho;
        width: 100%;
        padding: 0 30px 0 30px;
        line-height: 1.33333;
        box-sizing: border-box;
        opacity: 0;
        transition : all 2000ms;
        &.active {
          opacity: 1;
        }


        br {
            display: none;
        }

        dd {
            font-size: 16px;
            font-family: $font-gothic;
            margin-top: 20px;
            /* display: none; */
        }
    }



    @media only screen and (min-width: 660px) {
        .text {
            font-size: 40px;
            padding: 0 0 0 60px;

            br {
                display: inline;
            }

            dd {
                font-size: 20px;
            }
        }
    }

    @media only screen and (min-width: 770px) {
        .text {
            font-size: 56px;

            dd {
                font-size: 28px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .text {
            font-size: 60px;
            padding: 0 0 0 130px;

            dd {
                font-size: 30px;
            }
        }
    }

    @media only screen and (min-width: 1400px) {
    }
}



.mainvisual-sub-page {
  background-position: center top;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 50vh;
  display: table;
  @media only screen and (min-width: 660px) {
    height: 540px;
  }
  .container {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    text-align: center;
  }
  .subtitle {
    font-size: 20px;
    font-weight: bold;
    display: block;
    font-family: $font-roboto;
    @media only screen and (min-width: 660px) {
      font-size: 30px;
    }
  }

  .title {
    font-size: 34px;
    padding-top: 10px;
    font-weight: bold;
    @media only screen and (min-width: 660px) {
      font-size: 46px;
      padding-top: 30px;
    }
  }
}
