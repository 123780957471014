.table-01 {
  dl {
  width: 100%;
  display: table;
  @media only screen and (min-width: 660px) {
  }
  &:first-child {
    dt {
      border-top: 1px solid #1E50A2;
    }
    dd {
      border-top: 1px solid #e5e5e5;
    }
  }
  dt {
    border-bottom: 1px solid #1E50A2;
    display: table-cell;
    vertical-align: top;
    line-height: 1.75;
    padding: 15px 0 10px;
    width: 32.2%;
    @media only screen and (min-width: 660px) {
      padding: 25px 0 15px;
    }
  }
  dd {
    border-bottom: 1px solid #e5e5e5;
    display: table-cell;
    vertical-align: top;
    line-height: 1.75;
    padding: 15px 10px;
    @media only screen and (min-width: 660px) {
      padding: 25px 20px 25px;
    }
    a {
      color: $color-text;
      text-decoration: none;
      &:hover {
        @media only screen and (min-width: 770px) {
          text-decoration: underline;
        }
      }
    }
  }
  }
}
