.home-contents-link {

    a {
        display: block;
        padding: 40px 20px 40px 20px;
        height: 340px;
        color: $color-white;
        font-weight: bold;
        overflow:hidden;
        position: relative;
        box-sizing: border-box;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center bottom;
          transition: all .5s ease-in-out 0s;
        }
        @media only screen and (min-width: 770px) {
          &:hover {
            &:after {
              transform: scale(1.06);
            }
          }
        }




        &.service {
          &:after {
            background-image: url(../img/home/bg-01.jpg);
          }
        }

        &.philosophy {
          &:after {
            background-image: url(../img/home/bg-02.jpg);
          }
        }

        div {
            font-size: 16px;
            line-height: 1.9;
            margin-top: 15px;

            br {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 660px) {
        display: flex;

        a {
            padding: 60px 40px 60px 40px;
            width: 50%;
            height: 390px;
        }
    }

    @media only screen and (min-width: 770px) {
        a {
            height: 490px;

            div {
                font-size: 18px;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        a {
            padding: 60px 60px 60px 90px;
            height: 590px;

            div {
                font-size: 20px;
            }
        }
    }

    @media only screen and (min-width: 1400px) {
        a {
            padding: 60px 0 60px 90px;

            div {

                br {
                    display: inline;
                }
            }
        }
    }







    .read-more {
      border: 2px solid #fff;
      margin-top: 15px;
      display: inline-block;
      padding: 10px 20px;
      font-size: 14px;
      position: relative;
      @media only screen and (min-width: 660px) {
        margin-top: 30px;
        font-size: 16px;
      }
    }



}



.home-news-area {

    .news-list {
      margin-top: 50px;
      @media only screen and (min-width: 660px) {
        margin-top: 40px;
      }

      a {
        color: $color-text;
        @media only screen and (min-width: 770px) {
          &:hover {
            .title {
              span {
                text-decoration: underline;
              }
            }
          }
        }
      }

    }

    @media only screen and (min-width: 660px) {

        .container-md {
            display: flex;
        }

        .heading {
            width: 200px;
        }

        .news-list {
            width: calc(100% - 200px);
            margin-top: 0;
        }
    }

    @media only screen and (min-width: 770px) {
        .heading {
            width: 280px;
        }

        .news-list {
            width: calc(100% - 280px);
        }
    }
}



.home-bg-area {
    width: 100%;
    height: 190px;
    position: relative;
    overflow: hidden;
    @media only screen and (min-width: 660px) {
      height: 250px;
    }


    div {
        width: calc(100% + 20px);
        height: 270px;
        position: absolute;
        background-image: url(../img/home/bg-03.jpg);
        background-size: cover;
        background-position: center top;
        top: -10px;
        left: -10px;
    }

    @media only screen and (min-width: 660px) {
        height: 350px;

        div {
            height: 370px;
        }
    }
    @media only screen and (min-width: 1200px) {
        height: 500px;

        div {
            height: 520px;
        }
    }
}



.home-blog-area {
    background-color: $color-bg;
}
