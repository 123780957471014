.title-01 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    @media only screen and (min-width: 660px) {

    }

    span {
        font-family: $font-roboto;
        font-size: 14px;
        display: block;
        margin-bottom: 15px;
        color: $color-bg-blue;
    }

    &::after {
        content: '';
        display: block;
        width: 26px;
        height: 4px;
        background: linear-gradient(90deg,#1E50A2 0%,#1E50A2 70%,#AD4820 70%,#AD4820 100%);
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    &.pattern-01 {

        span {
            color: $color-white;
        }

        &::after {
            display: none;
        }
    }

    @media only screen and (min-width: 660px) {
        font-size: 32px;
        text-align: left;

        span {
            font-size: 16px;
        }

        &::after {
            margin-left: inherit;
            margin-right: inherit;
        }
    }

    @media only screen and (min-width: 770px) {
        font-size: 34px;

        span {
            font-size: 18px;
        }
    }

    @media only screen and (min-width: 1200px) {
        font-size: 36px;

        span {
            font-size: 20px;
        }
    }

    @media only screen and (min-width: 1400px) {
    }

    br {
      @media only screen and (min-width: 660px) {
        display: none;
      }
    }
}



.title-text-type-medium {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 5px;
  line-height: 1.25;
  @media only screen and (min-width: 660px) {
    font-size: 28px;
  }

}
