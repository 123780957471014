

.wrap-content {
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px;
	box-sizing: border-box;
  @media only screen and (min-width: 1200) {
    padding: 0;
  }
}
