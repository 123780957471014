html {
    font-size: 14px;
    font-family: $font-gothic;
    color: $color-text;
    letter-spacing: 0.05em;

    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
        font-size: 18px;
    }
    @media only screen and (min-width: 1200px) {
    }
}

p {
    /* margin-top: 1.0em;
    margin-bottom: 1.0em;
    line-height: $line-height-text; */
}

.text {
    /* line-height: $line-height-text; */
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
}

[target="_blank"] {
    /* display: inline-flex;
    align-items: center;

    &::after {
        margin-left: 0.25em;
        @mixin icon;
        @mixin icon-outlink;
    } */
}

/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }

// 文字サイズ
.text-lg { font-size: 1.2em; }
.text-sm { font-size: 0.8em; }

// 注釈
.text-note {
    font-size: 0.8em;
    color: #aaa;
    display: block;
}

// バリデーション
.invalid-feedback {
    font-size: 0.8em;
    color: $color-danger;
    display: block;
}
.valid-feedback {
    font-size: 0.8em;
    color: $color-success;
    display: block;
}

.text-base {
    color: $color-base;
}
strong {
    font-weight: bold;
}



.text-standard {
  font-size: 14px;
  padding-bottom: 15px;
  line-height: 2;
  @media only screen and (min-width: 660px) {
    font-size: 18px
  }
}
