/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

@define-mixin icon {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    transform: scale(1.1);
}

.icon::before {
	@mixin icon;
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}


@define-mixin icon-arrow-simple { content: "\EA01"; }
.icon-arrow-simple::before { content: "\EA01"; }

@define-mixin icon-arrow { content: "\EA02"; }
.icon-arrow::before { content: "\EA02"; }

@define-mixin icon-checkbox-checked-fill { content: "\EA03"; }
.icon-checkbox-checked-fill::before { content: "\EA03"; }

@define-mixin icon-checkbox-checked { content: "\EA04"; }
.icon-checkbox-checked::before { content: "\EA04"; }

@define-mixin icon-checkbox-round-checked-02-fill { content: "\EA05"; }
.icon-checkbox-round-checked-02-fill::before { content: "\EA05"; }

@define-mixin icon-checkbox-round-checked-02 { content: "\EA06"; }
.icon-checkbox-round-checked-02::before { content: "\EA06"; }

@define-mixin icon-checkbox-round-checked-fill { content: "\EA07"; }
.icon-checkbox-round-checked-fill::before { content: "\EA07"; }

@define-mixin icon-checkbox-round-checked { content: "\EA08"; }
.icon-checkbox-round-checked::before { content: "\EA08"; }

@define-mixin icon-checkbox-round { content: "\EA09"; }
.icon-checkbox-round::before { content: "\EA09"; }

@define-mixin icon-checkbox { content: "\EA0A"; }
.icon-checkbox::before { content: "\EA0A"; }

@define-mixin icon-close { content: "\EA0B"; }
.icon-close::before { content: "\EA0B"; }

@define-mixin icon-dropbox { content: "\EA0C"; }
.icon-dropbox::before { content: "\EA0C"; }

@define-mixin icon-email { content: "\EA0D"; }
.icon-email::before { content: "\EA0D"; }

@define-mixin icon-email2 { content: "\EA0E"; }
.icon-email2::before { content: "\EA0E"; }

@define-mixin icon-evernote { content: "\EA0F"; }
.icon-evernote::before { content: "\EA0F"; }

@define-mixin icon-facebook-simple { content: "\EA10"; }
.icon-facebook-simple::before { content: "\EA10"; }

@define-mixin icon-facebook { content: "\EA11"; }
.icon-facebook::before { content: "\EA11"; }

@define-mixin icon-feedly { content: "\EA12"; }
.icon-feedly::before { content: "\EA12"; }

@define-mixin icon-google-plus { content: "\EA13"; }
.icon-google-plus::before { content: "\EA13"; }

@define-mixin icon-hatena { content: "\EA14"; }
.icon-hatena::before { content: "\EA14"; }

@define-mixin icon-instagram { content: "\EA15"; }
.icon-instagram::before { content: "\EA15"; }

@define-mixin icon-line { content: "\EA16"; }
.icon-line::before { content: "\EA16"; }

@define-mixin icon-linkedin { content: "\EA17"; }
.icon-linkedin::before { content: "\EA17"; }

@define-mixin icon-outlink-02 { content: "\EA18"; }
.icon-outlink-02::before { content: "\EA18"; }

@define-mixin icon-outlink { content: "\EA19"; }
.icon-outlink::before { content: "\EA19"; }

@define-mixin icon-pinterest { content: "\EA1A"; }
.icon-pinterest::before { content: "\EA1A"; }

@define-mixin icon-pocket { content: "\EA1B"; }
.icon-pocket::before { content: "\EA1B"; }

@define-mixin icon-radio-checked-fill { content: "\EA1C"; }
.icon-radio-checked-fill::before { content: "\EA1C"; }

@define-mixin icon-radio-checked { content: "\EA1D"; }
.icon-radio-checked::before { content: "\EA1D"; }

@define-mixin icon-radio { content: "\EA1E"; }
.icon-radio::before { content: "\EA1E"; }

@define-mixin icon-search { content: "\EA1F"; }
.icon-search::before { content: "\EA1F"; }

@define-mixin icon-tel { content: "\EA20"; }
.icon-tel::before { content: "\EA20"; }

@define-mixin icon-time { content: "\EA21"; }
.icon-time::before { content: "\EA21"; }

@define-mixin icon-twitter { content: "\EA22"; }
.icon-twitter::before { content: "\EA22"; }

@define-mixin icon-youtube { content: "\EA23"; }
.icon-youtube::before { content: "\EA23"; }
