// ==================================================
// ニュース・コラム　詳細
// ==================================================

.is-news.detail {
  background-color: $color-bg;
  padding-bottom: 150px;
  *{
    box-sizing: border-box;
  }



  .l-entry-content {
    background-color: #fff;
    width: 100%;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px;
    border-radius: 2px;
    @media only screen and (min-width: 660px) {
      padding: 60px 50px 80px;
    }
  }

  .l-upper-part {
    @media only screen and (min-width: 660px) {
      display: flex;
      justify-content:space-between;
      align-items:center;
    }


    .cate {
      padding-bottom: 5px;
      display: inline-block;
      @media only screen and (min-width: 660px) {
        padding-bottom: 0;
      }
      span {
        background-color: $color-bg-blue;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        display: inline-block;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 5px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
        @media only screen and (min-width: 660px) {
          min-width: 100px;
          font-size: 14px;
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
        }
      }
    }

    .data {
      color: #C6C6C6;
      font-size: 12px;
      font-family: $font-roboto;
      @media only screen and (min-width: 660px) {
        font-size: 16px;
      }
    }
  }

  .title-entry {
    font-size: 20px;
    line-height: 1.5;
    padding-top: 15px;
    font-weight: bold;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 20px;
    @media only screen and (min-width: 660px) {
      font-size: 30px;
      padding-bottom: 50px;
    }
  }

  .m-thumbnail {
    text-align: center;
    margin-top: 30px;
    width: 100%;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 660px) {
      margin-top: 45px;
    }
    img {
      display: block;
      margin: auto;
    }
  }

  .content {
    font-size: 16px;
    margin-top: 60px;
    line-height: 1.8;
    @media only screen and (min-width: 660px) {
      font-size: 18px;
      margin-top: 60px;
    }
    p {
      padding: 0.75em 0;
      font-size: 16px;
      line-height: 1.8;
      @media only screen and (min-width: 660px) {
        font-size: 18px;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $color-bg-blue;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      padding-top: 1.0em;
      padding-bottom: 0.25em;
      &:first-of-type {
          padding-top: 0;
      }
      @media only screen and (min-width: 660px) {
        font-size: 24px;
        padding-top: 1.5em;
        padding-bottom: 0.5em;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: $color-bg-blue;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      padding-bottom: 0.25em;
      @media only screen and (min-width: 660px) {
        font-size: 20px;
        padding-bottom: 0.5em;
      }
    }
    b,strong {
      font-weight: bold;
    }
    a {
      color: $color-base;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      padding-top: 1em;
      padding-bottom: 1em;
    }
    ul, ol {
        margin-left: 1em;

        li {
            font-weight: bold;

            * {
                font-weight: normal;
                margin-top: 0;
            }
        }
    }
    .aligncenter {
        text-align: center;
    }
    .alignright {
        text-align: right;
    }
    .alignleft {
        text-align: left;
    }
  }


  .postnavi {
    border-color: #E5E5E5;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    @media only screen and (min-width: 660px) {
      margin-top: 80px;
      display: flex;
      justify-content:center;
      align-items:center;

    }
    .navi {
      display: block;
      padding: 10px 25px;
      border-color: #E5E5E5;
      border-style: solid;
      @media only screen and (min-width: 660px) {
        width:50%;
        border-left-width: 1px;
        padding: 25px;
      }
      &:first-child {
        border-left: none;
      }


      &.backnavi {
        a {
          &:before {
            left: -10px;
            transform:rotate(-230deg);
            @media only screen and (min-width: 660px) {
              left: -20px;
            }
          }
        }
      }


      &.nextnavi {
        text-align: right;
        a {
          &:before {
            right: -10px;
            transform:rotate(-45deg);
            @media only screen and (min-width: 660px) {
              right: -20px;
            }

          }
        }
      }

    }

    a {
      color: $color-base;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      line-height: 1.25;
      position: relative;
      transition: 0.2s;
      @media only screen and (min-width: 660px) {
        line-height: 1.8;
        font-size: 16px;
      }
      &:before {
        content: '';
        display: block;
        border-right:2px solid $color-base;
        border-bottom:2px solid $color-base;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: 0.2s;
        @media only screen and (min-width: 660px) {
          width: 12px;
          height: 12px;
        }
      }
    }



  }


}
