ul,
ol {
    /* margin-top: 1.0em;
    margin-bottom: 1.0em;
    padding-left: 1.5em;
    line-height: $line-height-text;
    list-style-position: outside; */

    ul,
    ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

    .title + & {
        margin-top: 0;
    }

    @media only screen and (min-width: 480px) {
        // margin-top: 1.5em;
        // margin-bottom: 1.5em;
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        // margin-top: 2em;
        // margin-bottom: 2em;
    }
    @media only screen and (min-width: 1000px) {

    }
}
li {
    /* margin-top: 0.5em;
    margin-bottom: 0.5em; */

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    li {
        /* margin-top: 0.5em;
        margin-bottom: 0.5em; */

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    & > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
}
ul {
    & > li {
        list-style-type: disc;
    }
    ul {
        & > li {
            list-style-type: circle;
        }
    }
}
ol {
    & > li {
        list-style-type: decimal;
    }
    ol {
        padding-left: 0;

        & > li {
            // list-style-type: decimal;
            list-style-type: none;
            counter-increment: cnt;
            display: flex;

            &::before {
                display: marker;
                content: "(" counter(cnt) ") ";
                margin-right: 0.5em;
            }
        }
    }
}

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;

    & > li {
        list-style-type: none;
    }
    & > li::before {
        display: marker;
        content: "※";
        margin-left: -1.5em;
        margin-right: 0.5em;
        width: 1em;
    }
}


/* リストが1つだけの場合は「1.」などを表示しない */
/*----------------------------------------------------------------------------*/
/*
ul.no-style,
ol.no-style {
    padding-left: 0;
    list-style-position: inside;

    & > li {
        list-style-type: none;
    }
}

dl {
    margin-top: 2em;
    margin-bottom: 2em;
    line-height: $line-height-text;

    dt {
        margin-top: 1em;
        margin-bottom: 0.25em;
        font-weight: bold;
    }
    *:first-child {
        margin-top: 0;
    }
}
*/


.news-list {

    margin: 0;
    padding: 0;
    line-height: 1.3;

    li {
        list-style: none;
        padding: 15px 0;
    }

    li:not(:last-child) {
        border-bottom: 1px solid #E2E2E2;
    }

    li:first-child {
        padding-top: 0;
    }

    .info {

        .date {
            font-size: 15px;
            font-family: $font-roboto;
        }

        .category {
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
            border: 1px solid #333333;
            width: 120px;
            text-align: center;
            padding: 5px 0;
        }
    }

    .title {
        margin-top: 10px;

        a {
            color: $color-text;
        }
    }

    @media only screen and (min-width: 660px) {
        padding-left: 20px;
        border-left: 1px solid #E2E2E2;
    }
}
