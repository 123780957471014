/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    background-repeat: no-repeat;
    border-radius: 0;
}
/*
body {
    color: #333333;
    letter-spacing: 0.075em;
}
*/
main {
    display: block;
}
/*
input,
textarea,
select:not([size]),
option {
    padding: 0.75em 1.0em;
}
td,
th {
    padding: 0.25em 1.0em;
}
ul,
ol {
    padding-left: 1.5em;
    list-style-position: outside;
}
ul li { list-style-type: disc; }
ol li { list-style-type: decimal }
input,
textarea,
select,
td,
th {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}
select,
option,
textarea,
input,
button {
    font-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0;
}
select,
option,
input,
button {
    line-height: 1;
}
*/
select,
option,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="date"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
/*
a {
    text-decoration: none;
}
td,
optgroup,
address {
    font-weight: normal;
}
a,
select,
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    cursor: pointer;
}
textarea {
    vertical-align: top;
    overflow: auto;
    resize: vertical;
}
*/
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input[type="search"] {
    box-sizing: content-box;
}
input[type="search"]:focus {
    outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

/* 色 */
/*----------------------------------------------------------------------------*/
/* 共通の色 */

/* 文字の色 */

/* 背景の色 */

/* 線の色 */

/* アラート色 */

/* フォーム */

/* モジュールの色 */


/* px数 (変数化しすぎると逆に大変なので極力増やさない) */
/*----------------------------------------------------------------------------*/
/* ヘッダーの高さ */

/* 影 */

/* 行間 */
/* $line-height-text: 1.75; */
/* $line-height-title: 1.5; */

/* フォント */

/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon::before {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}
.icon-arrow-simple::before { content: "\EA01"; }
.icon-arrow::before { content: "\EA02"; }
.icon-checkbox-checked-fill::before { content: "\EA03"; }
.icon-checkbox-checked::before { content: "\EA04"; }
.icon-checkbox-round-checked-02-fill::before { content: "\EA05"; }
.icon-checkbox-round-checked-02::before { content: "\EA06"; }
.icon-checkbox-round-checked-fill::before { content: "\EA07"; }
.icon-checkbox-round-checked::before { content: "\EA08"; }
.icon-checkbox-round::before { content: "\EA09"; }
.icon-checkbox::before { content: "\EA0A"; }
.icon-close::before { content: "\EA0B"; }
.icon-dropbox::before { content: "\EA0C"; }
.icon-email::before { content: "\EA0D"; }
.icon-email2::before { content: "\EA0E"; }
.icon-evernote::before { content: "\EA0F"; }
.icon-facebook-simple::before { content: "\EA10"; }
.icon-facebook::before { content: "\EA11"; }
.icon-feedly::before { content: "\EA12"; }
.icon-google-plus::before { content: "\EA13"; }
.icon-hatena::before { content: "\EA14"; }
.icon-instagram::before { content: "\EA15"; }
.icon-line::before { content: "\EA16"; }
.icon-linkedin::before { content: "\EA17"; }
.icon-outlink-02::before { content: "\EA18"; }
.icon-outlink::before { content: "\EA19"; }
.icon-pinterest::before { content: "\EA1A"; }
.icon-pocket::before { content: "\EA1B"; }
.icon-radio-checked-fill::before { content: "\EA1C"; }
.icon-radio-checked::before { content: "\EA1D"; }
.icon-radio::before { content: "\EA1E"; }
.icon-search::before { content: "\EA1F"; }
.icon-tel::before { content: "\EA20"; }
.icon-time::before { content: "\EA21"; }
.icon-twitter::before { content: "\EA22"; }
.icon-youtube::before { content: "\EA23"; }

@charset "UTF-8";

/* ページャー */
/*---------------------------------------------------*/
/*
.pager li {
    padding: 0 0.5em;
}
.pager a,
.pager a:hover,
.pager span {
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #111111;
}
.pager .current {
    background: #dddddd;
}
.pager a:hover {
    background: #111111;
    color: #ffffff;
}
*/



/* toggle / tab / popup */
/*---------------------------------------------------*/
/*
.toggle-button,
.parent-tab-button a,
.popup-button a {
    // padding: 15px 20px;
    // border: 1px solid #333333;
    font-weight: normal;
}
.toggle-button,
.popup-button a {
    background-color: transparent;
    color: #333333;
}
.toggle-button.active,
.toggle-button:hover,
.parent-tab-button li a,
.popup-button a:hover {
    // background-color: #333333;
    // color: #ffffff;
    color: #333333;
}
.parent-tab-button li a:hover {
    opacity: 0.7;
}
.toggle-button.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.toggle-content,
.parent-tab-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.parent-tab-button li a {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.parent-tab-button li:first-child a {
    border-top-left-radius: 3px;
    border-left-width: 1px;
}
.parent-tab-button li:last-child a {
    border-top-right-radius: 3px;
    border-right-width: 1px;
}
.parent-tab-content {
    border: 1px solid #333333;
    border-top: none;
}
.parent-tab-button li.current a {
    color: #333333;
    background-color: transparent;
}
.parent-popup-content .container {
    // width: 770px;
}
.popup-content > .panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}
.popup-content {
    opacity: 0.0;
}
.popup-content.active {
    opacity: 1.0;
}
.popup-content {
    transition: opacity 0.25s ease-in-out 0.4s;
}
.popup-button-close {
    width: 30px;
    height: 30px;
    background: url(../img/icon-close-big.png) no-repeat center center;
    background-size: 30px 30px;
    top: 10px;
    right: 10px;
}
.popup-button-close:hover {
    opacity: 0.35;
}
*/




/* thumbnailボタン */
/*---------------------------------------------------*/
a .thumbnail img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
a:hover .thumbnail img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
/*
@media only screen and (min-width: 480px) {
    // input[type="submit"],
    // input[type="button"],
    // input[type="reset"],
    // button,
    // .button a {
    //     width: auto
    // }
}

@media only screen and (min-width: 770px) {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="number"],
    input[type="file"],
    input[type="search"],
    select,
    body select[size="1"],
    select[multiple],
    option,
    textarea {
        padding: 20px;
        // border: 2px solid #eeeeee;
    }

    select[size],
    select[multiple],
    select:not([size]) {
        padding: 20px;
    }

    // input[type="button"],
    // input[type="reset"],
    // input[type="submit"],
    // button,
    // .button > a,
    // .button > span:not(.icon),
    // a.button {
    //     padding: 0.5em 1em;
    //     font-size: 16px;
    // }
}
*/


/* pagerボタン */
/*---------------------------------------------------*/
.pager ul,
.pager ol,
ul.pager ,
ol.pager {
    margin: 0 auto;
    padding: 0;
    display: table;
    border-collapse: separate;
    border-spacing: 1px 0;
}
.pager li,
.pager li {
    display: table-cell;
}
.pager li a,
.pager li span {
/*
    display: table-cell;
    text-align: center;
    vertical-align: middle;
*/
}
.pager li a,
.pager li span {
    min-width: 2em;
    min-height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pager .first a,
.pager .prev a,
.pager .next a,
.pager .last a {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
}

/* WordPressの管理者用ヘッダー */
body.logged-in #wpadminbar {
        position: fixed;
        top: 0;
    }
body.logged-in header {
        top: 46px;
    }
body.logged-in header .global-nav nav {
            top: calc(46px + 0);
        }
body.logged-in .alert-group {
        top: calc(46px + 0 + 5px);
    }

@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 783px) {
        body.logged-in header {
            top: 32px;
        }

            body.logged-in header .global-nav nav {
                top: calc(32px + 0);
            }
        body.logged-in .alert-group {
            top: calc(32px + 0 + 5px);
        }

}
@media only screen and (min-width: 1000px) {
    /* body.customize-support header, */
        body.logged-in header .global-nav nav {
            top: auto;
        }
        body.logged-in .alert-group {
            top: calc(32px + 0 + 5px);
        }
}

/* WordPress管理画面対応 */
/*
html.global-nav-active {
    body.customize-support .toggle-content {
        max-height: calc(100vh - 46px - 70px);

        @media only screen and (min-width: 783px) {
            max-height: calc(100vh - 32px - 70px);
        }
    }
}
*/

/* WordPress */
.mw_wp_form form .error {
        padding: 5px 10px;
        background-color: #B70000;
        color: #ffffff;
    }

/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  touch-action: pan-y;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.bxslider {
  margin: 0;
  padding: 0;
}
ul.bxslider {
  list-style: none;
}
.bx-viewport {
  /* fix other elements on the page moving (in Chrome) */
  -webkit-transform: translateZ(0);
}
/** THEME
===================================*/
.bx-wrapper {
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff;
}
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url('images/bx_loader.gif') center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  border-radius: 5px;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000;
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}
/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url('images/controls.png') no-repeat 0 -32px;
}
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}
.bx-wrapper .bx-next {
  right: 10px;
  background: url('images/controls.png') no-repeat -43px -32px;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}
/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/
.bx-wrapper .bx-loading {
    background: url(../img/common/loading.png) center center no-repeat;
}
.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
    background: none;
    display: block;
    position: relative;
}
.bx-wrapper .bx-prev::before,
.bx-wrapper .bx-next::before {
    content: "\e200";
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 14px;
    font-size: 1rem;
    font-family: 'icon','icon-sns' !important;
    color: #fff;
    text-indent: 0;
    background: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.bx-wrapper .bx-prev::before {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.bx-wrapper .bx-next::before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
/*
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
*/

/* .bxsliderを読み込むまでは表示しない */
/*----------------------------------------------------------------------------------*/
.bxslider {
    list-style: none;
    display: block;
}
.bxslider > li {
        list-style: none;
        display: block;
    }
.bx-wrapper .bxslider {
    display: block;
}

/* 余白や装飾の初期化 */
/*----------------------------------------------------------------------------------*/
.bxslider li, .bx-wrapper .bx-viewport li {
        margin: 0;
    }
.bx-wrapper {
    /* initialはIEでは使用不可 */
    /* border: none; */
    /* box-shadow: none; */
    /* background: none; */
}
.bxslider *:not(.thumbnail) > img {
    min-width: 100%;
    min-height: 100%;
}

/* PCで画面を触ると都合が悪いので、マスクを表示 */
/*----------------------------------------------------------------------------------*/

/* マスク */
.bx-wrapper::before {
    z-index: 2000;
}
.bx-viewport {
    z-index: 1000;
}
.bx-controls {
    z-index: 8000;
    position: relative;
}

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {
    /* マスク */
    .bx-wrapper::before {
        content: "";
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
@media only screen and (min-width: 1000px) {

}
@media only screen and (min-width: 1300px) {

}

.xdsoft_datetimepicker {
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #fff;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 8px;
	padding-left: 0;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	box-sizing: border-box;
	display: none;
}
.xdsoft_datetimepicker.xdsoft_rtl {
	padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 75px;
	height: 210px;
	background: transparent;
	border: none;
}

/*For IE8 or lower*/
.xdsoft_datetimepicker button {
	border: none !important;
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_noselect::selection { background: transparent }
.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_datetimepicker.xdsoft_inline {
	display: inline-block;
	position: static;
	box-shadow: none;
}

.xdsoft_datetimepicker * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
	display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	width: 224px;
	float: left;
	margin-left: 8px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
	width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
	width: 58px;
	float: left;
	text-align: center;
	margin-left: 8px;
	margin-top: 0;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active+.xdsoft_timepicker {
	margin-top: 8px;
	margin-bottom: 3px
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	position: relative;
	text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
	opacity: 0.5;
	background-position: -92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
	float: left;
	background-position: -20px 0;
}
.xdsoft_datetimepicker .xdsoft_today_button {
	float: left;
	background-position: -70px 0;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
	float: right;
	background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_prev ,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
	float: none;
	background-position: -40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left: 14px;
	margin-top: 7px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
	float: none;
	margin-left: 0;
	margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
	background-position: -40px 0;
	margin-bottom: 7px;
	margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
	height: 151px;
	overflow: hidden;
	border-bottom: 1px solid #ddd;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #f5f5f5;
	border-top: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: center;
	border-collapse: collapse;
	cursor: pointer;
	border-bottom-width: 0;
	height: 25px;
	line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div > div:first-child {
	border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover,
.xdsoft_datetimepicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_prev:hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
	display: inline;
	position: relative;
	z-index: 9999;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff;
	float: left;
	width: 182px;
	text-align: center;
	cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover>span {
	text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
	opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
	border: 1px solid #ccc;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 101;
	display: none;
	background: #fff;
	max-height: 160px;
	overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect{ right: -7px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect{ right: 2px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #fff;
	background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
	padding: 2px 10px 2px 5px;
	text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
	width: 100px;
	text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	clear: both;
}

.xdsoft_datetimepicker .xdsoft_year{
	width: 48px;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border-collapse: collapse;
	width: 100%;

}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
	padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td,.xdsoft_datetimepicker .xdsoft_calendar th {
	width: 14.2857142%;
	background: #f5f5f5;
	border: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding: 0;
	border-collapse: collapse;
	cursor: pointer;
	height: 25px;
}
.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td,.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
	width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	color: #33aaff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker .xdsoft_time_box >div >div.xdsoft_disabled {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #fff !important;
	background: #ff8000 !important;
	box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current.xdsoft_disabled:hover {
	background: #33aaff !important;
	box-shadow: #178fe5 0 1px 3px 0 inset !important;
	color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_disabled:hover {
	color: inherit	!important;
	background: inherit !important;
	box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
	color: #ccc !important;
	font-size: 10px;
	clear: both;
	float: none;
	margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a { color: #eee !important }
.xdsoft_datetimepicker .xdsoft_copyright a:hover { color: #aaa !important }

.xdsoft_time_box {
	position: relative;
	border: 1px solid #ccc;
}
.xdsoft_scrollbar >.xdsoft_scroller {
	background: #ccc !important;
	height: 20px;
	border-radius: 3px;
}
.xdsoft_scrollbar {
	position: absolute;
	width: 7px;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
	left: 0;
	right: auto;
}
.xdsoft_scroller_box {
	position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
	box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	background: #000;
	border-bottom: 1px solid #444;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
	border-bottom: 1px solid #222;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #0a0a0a;
	border-top: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
	background-color: #000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
	border: 1px solid #333;
	background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #000;
	background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_next,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0a0a0a;
	border: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
	color: #cc5500;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color:#000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color:#000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #000 !important;
	background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright { color: #333 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a { color: #111 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover { color: #555 !important }

.xdsoft_dark .xdsoft_time_box {
	border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar >.xdsoft_scroller {
	background: #333 !important;
}
.xdsoft_datetimepicker .xdsoft_save_selected {
    display: block;
    border: 1px solid #dddddd !important;
    margin-top: 5px;
    width: 100%;
    color: #454551;
    font-size: 13px;
}
.xdsoft_datetimepicker .blue-gradient-button {
	font-family: "museo-sans", "Book Antiqua", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #82878c;
	height: 28px;
	position: relative;
	padding: 4px 17px 4px 33px;
	border: 1px solid #d7d8da;
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: linear-gradient(to bottom, #fff 0%, #f4f8fa 73%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f4f8fa',GradientType=0 );
/* IE6-9 */
}
.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #454551;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #f4f8fa 0%, #FFF 73%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f8fa', endColorstr='#FFF',GradientType=0 );
  /* IE6-9 */
}

/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/



/*
body .xdsoft_datetimepicker .xdsoft_next {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(90deg);
    }
}

body .xdsoft_datetimepicker .xdsoft_prev {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(-90deg);
    }
}
*/
body .xdsoft_datetimepicker .xdsoft_today_button {
    display: none;
}

.datetimepicker-date {
    padding-right: 32px;
    background-image: url(../img/common/icon-calendar-margin.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 32px 16px;
    cursor: pointer;
}
body .xdsoft_calendar table {
            display: table;
        }
body .xdsoft_calendar table thead {
                display: table-header-group;
            }
body .xdsoft_calendar table tbody {
                display: table-row-group;
            }
body .xdsoft_calendar table tfoot {
                display: table-footer-group;
            }
body .xdsoft_calendar table tr {
                display: table-row;
            }
body .xdsoft_calendar table th,
            body .xdsoft_calendar table td {
                display: table-cell;
            }
/*
    .xdsoft_datetimepicker,
    .xdsoft_noselect {
        display: block !important;
    }
    */
body .xdsoft_datetimepicker {
        padding: 10px;
    }
body .xdsoft_datetimepicker .xdsoft_datepicker {
    	width: 260px;
    	float: none;
    	/*\/ margin-left: 8px; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
    	float: none;
    	/* margin-right: 8px; */
    	/* margin-left: 0; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
    	width: 256px;
    }
body .xdsoft_datetimepicker {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
body .xdsoft_datetimepicker .xdsoft_year {
        width: 60px;
    }
/* マス */
body .xdsoft_datetimepicker .xdsoft_calendar table,
    body .xdsoft_datetimepicker .xdsoft_calendar thead,
    body .xdsoft_datetimepicker .xdsoft_calendar tbody,
    body .xdsoft_datetimepicker .xdsoft_calendar tfoot {
        border-left: none;
        border-right: none;
    }
/*
    .xdsoft_datetimepicker .xdsoft_label i,
    .xdsoft_datetimepicker .xdsoft_next,
    .xdsoft_datetimepicker .xdsoft_prev,
    .xdsoft_datetimepicker .xdsoft_today_button {
        background: none;

        &::before {
            content: url(../img/common/icon-arrow-simple.svg);
            display: block;
        }
    }
    */
body .xdsoft_datetimepicker .xdsoft_calendar td,
    body .xdsoft_datetimepicker .xdsoft_calendar th {
        color: #333333;
        border: 1px solid #000000;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: none;
    }
/* 見出し */
body .xdsoft_datetimepicker .xdsoft_calendar th {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        background: none;
        height: auto;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td {
        height: 38px;
        text-align: center;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td > div {
        padding: 0;
    }
/* 今日 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
        background-color: #333333 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 0.25;
    }
/* 選択中 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
        background-color: #28f !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* ホバー */
body .xdsoft_datetimepicker .xdsoft_calendar td:hover,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
        background-color: #049 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* 前月、翌月 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
    body .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
        opacity: 0.25;
    }
/* select */
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
        background-color: #28f !important;
        color: #fff !important;
        box-shadow: none !important;
    }
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
        background-color: #049 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/*
select,
body select[size="1"] {

    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1;

    position: relative;
}
*/

/* 便利なCSSを記述 */
/*----------------------------------------------------------------------------*/
:first-child {
    margin-top: 0;
}
:last-child {
    margin-bottom: 0;
}

/* clearfix、回り込み */
/*---------------------------------------------------*/
/*
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float-left { float: left; }
.float-right { float: right; }
.clear { clear: both; }
*/

/* ブロック */
/*---------------------------------------------------*/
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-table { display: table; }
.d-table-cell { display: table-cell; }
.d-flex { display: flex; }

/* 余白 */
/*---------------------------------------------------*/
.mt-auto { margin-top: auto !important; }
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-200 { margin-top: 200px !important; }
.mt-250 { margin-top: 250px !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.mb-250 { margin-bottom: 250px !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-30 { margin-left: 30px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-50 { margin-left: 50px !important; }
.ml-60 { margin-left: 60px !important; }
.ml-70 { margin-left: 70px !important; }
.ml-80 { margin-left: 80px !important; }
.ml-90 { margin-left: 90px !important; }
.ml-100 { margin-left: 100px !important; }
.ml-110 { margin-left: 110px !important; }
.ml-120 { margin-left: 120px !important; }
.ml-130 { margin-left: 130px !important; }
.ml-140 { margin-left: 140px !important; }
.ml-150 { margin-left: 150px !important; }
.ml-200 { margin-left: 200px !important; }
.ml-250 { margin-left: 250px !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-50 { margin-right: 50px !important; }
.mr-60 { margin-right: 60px !important; }
.mr-70 { margin-right: 70px !important; }
.mr-80 { margin-right: 80px !important; }
.mr-90 { margin-right: 90px !important; }
.mr-100 { margin-right: 100px !important; }
.mr-110 { margin-right: 110px !important; }
.mr-120 { margin-right: 120px !important; }
.mr-130 { margin-right: 130px !important; }
.mr-140 { margin-right: 140px !important; }
.mr-150 { margin-right: 150px !important; }
.mr-200 { margin-right: 200px !important; }
.mr-250 { margin-right: 250px !important; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-110 { padding-top: 110px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-130 { padding-top: 130px !important; }
.pt-140 { padding-top: 140px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pt-250 { padding-top: 250px !important; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-110 { padding-bottom: 110px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-130 { padding-bottom: 130px !important; }
.pb-140 { padding-bottom: 140px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.pb-250 { padding-bottom: 250px !important; }

.pl-0 { padding-left: 0px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-40 { padding-left: 40px !important; }
.pl-50 { padding-left: 50px !important; }
.pl-60 { padding-left: 60px !important; }
.pl-70 { padding-left: 70px !important; }
.pl-80 { padding-left: 80px !important; }
.pl-90 { padding-left: 90px !important; }
.pl-100 { padding-left: 100px !important; }
.pl-110 { padding-left: 110px !important; }
.pl-120 { padding-left: 120px !important; }
.pl-130 { padding-left: 130px !important; }
.pl-140 { padding-left: 140px !important; }
.pl-150 { padding-left: 150px !important; }
.pl-200 { padding-left: 200px !important; }
.pl-250 { padding-left: 250px !important; }

.pr-0 { padding-right: 0px !important; }
.pr-5 { padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-20 { padding-right: 20px !important; }
.pr-30 { padding-right: 30px !important; }
.pr-40 { padding-right: 40px !important; }
.pr-50 { padding-right: 50px !important; }
.pr-60 { padding-right: 60px !important; }
.pr-70 { padding-right: 70px !important; }
.pr-80 { padding-right: 80px !important; }
.pr-90 { padding-right: 90px !important; }
.pr-100 { padding-right: 100px !important; }
.pr-110 { padding-right: 110px !important; }
.pr-120 { padding-right: 120px !important; }
.pr-130 { padding-right: 130px !important; }
.pr-140 { padding-right: 140px !important; }
.pr-150 { padding-right: 150px !important; }
.pr-200 { padding-right: 200px !important; }
.pr-250 { padding-right: 250px !important; }

/* 幅 */
/*---------------------------------------------------*/
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }


/* HTMLで改行した場合に生じる左右の余白を消す */
/*---------------------------------------------------*/
.parent-inline-block {
    letter-spacing: -1em;
    word-spacing: -1em;
}
:root .parent-inline-block {
    font-size: 0 !important;
    letter-spacing: -1px;
}
.parent-inline-block > * {
    font-size: 14px;
    font-size: 1rem;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}


/* 動画 */
/*---------------------------------------------------*/
.parent-youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.parent-youtube iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/* その他 */
/*---------------------------------------------------*/
/* いいねボタンの横幅が途切れてしまう現象への対策 */
.fb-like iframe {
    max-width: none !important;
}


/* 画面幅によって非表示 */
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
    .only-pc { display: none !important; }
    .only-tab { display: none !important; }
    .only-sp {  }

    .over-pc { display: none !important; }
    .over-tab { display: none !important; }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp {  }
}
@media only screen and (min-width: 480px) and (max-width: 769px) {
    .only-pc { display: none !important; }
    .only-tab {  }
    .only-sp { display: none !important; }

    .over-pc { display: none !important; }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp { display: none !important; }
}
@media only screen and (min-width: 770px) {
    .only-pc {  }
    .only-tab { display: none !important; }
    .only-sp { display: none !important; }

    .over-pc {  }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab { display: none !important; }
    .under-sp { display: none !important; }
}

html {
    font-size: 14px;
    font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', '游ゴシック', YuGothic, 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
    color: #333333;
    letter-spacing: 0.05em;
}

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {

html {
        font-size: 18px
}
    }

@media only screen and (min-width: 1200px) {
    }

p {
    /* margin-top: 1.0em;
    margin-bottom: 1.0em;
    line-height: $line-height-text; */
}

.text {
    /* line-height: $line-height-text; */
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
}

[target="_blank"] {
    /* display: inline-flex;
    align-items: center;

    &::after {
        margin-left: 0.25em;
        @mixin icon;
        @mixin icon-outlink;
    } */
}

/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }

/* 文字サイズ */
.text-lg { font-size: 1.2em; }
.text-sm { font-size: 0.8em; }

/* 注釈 */
.text-note {
    font-size: 0.8em;
    color: #aaa;
    display: block;
}

/* バリデーション */
.invalid-feedback {
    font-size: 0.8em;
    color: #ec5858;
    display: block;
}
.valid-feedback {
    font-size: 0.8em;
    color: #6bc55d;
    display: block;
}

.text-base {
    color: #333333;
}
strong {
    font-weight: bold;
}



.text-standard {
  font-size: 14px;
  padding-bottom: 15px;
  line-height: 2;
}



@media only screen and (min-width: 660px) {



.text-standard {
    font-size: 18px
}
  }

.title-01 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
}

@media only screen and (min-width: 660px) {

    }

.title-01 span {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        display: block;
        margin-bottom: 15px;
        color: #082F6F;
    }

.title-01::after {
        content: '';
        display: block;
        width: 26px;
        height: 4px;
        background: linear-gradient(90deg,#1E50A2 0%,#1E50A2 70%,#AD4820 70%,#AD4820 100%);
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

.title-01.pattern-01 span {
            color: #ffffff;
        }

.title-01.pattern-01::after {
            display: none;
        }

@media only screen and (min-width: 660px) {

.title-01 {
        font-size: 32px;
        text-align: left
}

        .title-01 span {
            font-size: 16px;
        }

        .title-01::after {
            margin-left: inherit;
            margin-right: inherit;
        }
    }

@media only screen and (min-width: 770px) {

.title-01 {
        font-size: 34px
}

        .title-01 span {
            font-size: 18px;
        }
    }

@media only screen and (min-width: 1200px) {

.title-01 {
        font-size: 36px
}

        .title-01 span {
            font-size: 20px;
        }
    }

@media only screen and (min-width: 1400px) {
    }

@media only screen and (min-width: 660px) {

.title-01 br {
        display: none
    }
      }



.title-text-type-medium {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 5px;
  line-height: 1.25;

}



@media only screen and (min-width: 660px) {



.title-text-type-medium {
    font-size: 28px

}
  }

/* margin-top: 1.0em;
    margin-bottom: 1.0em;
    padding-left: 1.5em;
    line-height: $line-height-text;
    list-style-position: outside; */

ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

.title + ul, .title + ol {
        margin-top: 0;
    }

@media only screen and (min-width: 480px) {

ul,
ol {
        /* margin-top: 1.5em; */
        /* margin-bottom: 1.5em; */
}
    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

ul,
ol {
        /* margin-top: 2em; */
        /* margin-bottom: 2em; */
}
    }

@media only screen and (min-width: 1000px) {

    }
/* margin-top: 0.5em;
    margin-bottom: 0.5em; */
li:first-child {
        margin-top: 0;
    }
li:last-child {
        margin-bottom: 0;
    }
/* margin-top: 0.5em;
        margin-bottom: 0.5em; */
li li:first-child {
            margin-top: 0;
        }
li li:last-child {
            margin-bottom: 0;
        }
li > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
ul > li {
        list-style-type: disc;
    }
ul ul > li {
            list-style-type: circle;
        }
ol > li {
        list-style-type: decimal;
    }
ol ol {
        padding-left: 0;
    }
ol ol > li {
            /* list-style-type: decimal; */
            list-style-type: none;
            counter-increment: cnt;
            display: flex;
        }
ol ol > li::before {
                display: marker;
                content: "(" counter(cnt) ") ";
                margin-right: 0.5em;
            }

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;
}
.list-note > li {
        list-style-type: none;
    }
.list-note > li::before {
        display: marker;
        content: "※";
        margin-left: -1.5em;
        margin-right: 0.5em;
        width: 1em;
    }


/* リストが1つだけの場合は「1.」などを表示しない */
/*----------------------------------------------------------------------------*/
/*
ul.no-style,
ol.no-style {
    padding-left: 0;
    list-style-position: inside;

    & > li {
        list-style-type: none;
    }
}

dl {
    margin-top: 2em;
    margin-bottom: 2em;
    line-height: $line-height-text;

    dt {
        margin-top: 1em;
        margin-bottom: 0.25em;
        font-weight: bold;
    }
    *:first-child {
        margin-top: 0;
    }
}
*/


.news-list {

    margin: 0;
    padding: 0;
    line-height: 1.3;
}


.news-list li {
        list-style: none;
        padding: 15px 0;
    }


.news-list li:not(:last-child) {
        border-bottom: 1px solid #E2E2E2;
    }


.news-list li:first-child {
        padding-top: 0;
    }


.news-list .info .date {
            font-size: 15px;
            font-family: 'Roboto', sans-serif;
        }


.news-list .info .category {
            display: inline-block;
            margin-left: 10px;
            font-size: 12px;
            border: 1px solid #333333;
            width: 120px;
            text-align: center;
            padding: 5px 0;
        }


.news-list .title {
        margin-top: 10px;
    }


.news-list .title a {
            color: #333333;
        }


@media only screen and (min-width: 660px) {


.news-list {
        padding-left: 20px;
        border-left: 1px solid #E2E2E2
}
    }

.table-01 dl {
  width: 100%;
  display: table;
  }

@media only screen and (min-width: 660px) {
  }

.table-01 dl:first-child dt {
      border-top: 1px solid #1E50A2;
    }

.table-01 dl:first-child dd {
      border-top: 1px solid #e5e5e5;
    }

.table-01 dl dt {
    border-bottom: 1px solid #1E50A2;
    display: table-cell;
    vertical-align: top;
    line-height: 1.75;
    padding: 15px 0 10px;
    width: 32.2%;
  }

@media only screen and (min-width: 660px) {

.table-01 dl dt {
      padding: 25px 0 15px
  }
    }

.table-01 dl dd {
    border-bottom: 1px solid #e5e5e5;
    display: table-cell;
    vertical-align: top;
    line-height: 1.75;
    padding: 15px 10px;
  }

@media only screen and (min-width: 660px) {

.table-01 dl dd {
      padding: 25px 20px 25px
  }
    }

.table-01 dl dd a {
      color: #333333;
      text-decoration: none;
    }

@media only screen and (min-width: 770px) {

.table-01 dl dd a:hover {
          text-decoration: underline
      }
        }

img {
    max-width: 100%;
}

figure figcaption {
        margin-top: 1em;
        text-align: center;
        line-height: calc(1em + 10px);
    }

figure img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

/* 将来的にCSS「object-fit」で実装できるようになる */
/* https://developer.mozilla.org/ja/docs/Web/CSS/object-fit */

/* 新thumbnail */
/*----------------------------------------------------------------------------*/
/*
.thumbnail {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.thumbnail > img {
    max-width: initial;
    max-height: initial;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity 0.2s ease 0.0s;
    display: block;
    position: absolute;
    margin: auto;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1;
}

.thumbnail:not(.contain).landscape > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
.thumbnail:not(.contain).portrait > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.landscape > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.portrait > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
*/


/* 旧thumbnail */
/*----------------------------------------------------------------------------*/
.thumbnail {
    display: block;
    position: relative;
    /* min-height: 180px; */
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 76.276%;
    box-sizing: border-box;
}
@media only screen and (min-width: 660px) {
.thumbnail {
        height: 260px;
        padding-top: 0
}
    }
.thumbnail > * {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.thumbnail > img {
    max-width: none;
    max-width: initial;
    max-height: none;
    max-height: initial;
    opacity: 0.0;
    transition: opacity 0.2s ease 0.2s;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1.0;
}
.thumbnail.landscape > img {
    max-width: none;
    max-width: initial;
    max-height: 100%;

    height: 100%;
    left: 50%;
    right: auto;
}
.thumbnail.portrait > img {
    max-width: 100%;
    max-height: auto;
    max-height: initial;

    width: 100%;
    top: 50%;
    bottom: auto;
}
.thumbnail.contain > img,
.thumbnail.contain.landscape > img,
.thumbnail.contain.portrait > img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* ↓.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */
.thumbnail.contain.landscape > img {
    width: 100%;
    height: auto;
}
.thumbnail.contain.portrait > img {
    width: auto;
    height: 100%;
}
/* ↑.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */

iframe {
    max-width: 100%;
    display: block;
}

/*
アイコンのsvgファイルを以下のディレクトリに保存するとアイコンフォントとして使用可能。
1. /src/font/svg/ に.svgファイルを保存
2. gulpを実行
3. 保存したファイル名が「icon-arrow.svg」の場合、
　 「<i class="icon icon-arrow"></i>」でアイコンが表示される

上記、処理が「03-mixin-pre-consolidate.css」内に記述。
*/

/*
.icon-arrow::before {
    @mixin icon;
}
*/


/* 以下、フォント以外のアイコン */
/*----------------------------------------------------------------------------*/
.icon-arrow-animation {
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    display: block;
    position: relative;
}
.icon-arrow-animation span {
	    margin: auto;
	    width: 24px;
	    height: 2px;
	    background: #000000;
	    display: block;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    transition: all 0.15s ease-in-out 0s;
	}
.icon-arrow-animation span:nth-child(1),
	.icon-arrow-animation span:nth-child(2) {
	    margin-right: 3px;
	    width: 12px;
	    -webkit-transform-origin: 100% 50%;
	            transform-origin: 100% 50%;
	}
.icon-arrow-animation span:nth-child(1) {
	    -webkit-transform: rotate(45deg);
	            transform: rotate(45deg);
	}
.icon-arrow-animation span:nth-child(2) {
	    -webkit-transform: rotate(-45deg);
	            transform: rotate(-45deg);
	}
.icon-arrow-animation:hover span:nth-child(1),
	.icon-arrow-animation:hover span:nth-child(2) {
	    margin-right: -7px;
	    width: 12px;
	    -webkit-transform-origin: 100% 50%;
	            transform-origin: 100% 50%;
	}
.icon-arrow-animation:hover span:nth-child(3) {
	    box-shadow: 10px 0 0 0 #111111;
	}

html .loading {
	position: relative;
	color: transparent !important;
    pointer-events: none;
}

html .loading *,
    html .loading *::before,
    html .loading *::after {
        color: transparent !important;
        pointer-events: none;
    }

html .loading > * {
        opacity: 0.5 !important;
    }

html .loading.field input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
        html .loading.field textarea,
        html .loading.field select {
            background: #ebebeb !important;
            border: none !important;
            box-shadow: none !important;
        }

html .loading.field-radio label .icon, html .loading.field-checkbox label .icon {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-radio label .icon::before,
                html .loading.field-radio label .icon::after,
                html .loading.field-checkbox label .icon::before,
                html .loading.field-checkbox label .icon::after {
                    display: none;
                }

html .loading.field-range input[type="range"] {
            background: #ebebeb !important;
            border: none !important;
            box-shadow: none !important;
        }

/*
            &::-webkit-slider-runnable-track {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            */

html .loading.field-range input[type="range"]::-moz-range-track {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

/*横線 */

html .loading.field-range input[type="range"]::-ms-track {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

/* つまみ */

html .loading.field-range input[type="range"]::-webkit-slider-thumb {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-range input[type="range"]::-moz-range-thumb {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-range input[type="range"]::-ms-thumb {
                background: #ebebeb !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading::before,
	html .loading::after {
		content: "";
		margin: auto;
		width: 14px;
		width: 1rem;
		height: 14px;
		height: 1rem;
		border-radius: 0.5rem;
		display: block;
		/* background: #f00; */

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		box-sizing: border-box;
	}

html .loading::before {
		border: 2px solid #000000;
		border: 2px solid rgba(0, 0, 0, 0.5);
		z-index: 900;
	}

html .loading::after {
		border: 2px solid #ffffff;
		border: 2px solid rgba(255, 255, 255, 1.0);
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
		z-index: 1000;

		-webkit-animation: icon-load-animation 1.0s linear 0s infinite;

		        animation: icon-load-animation 1.0s linear 0s infinite;
	}

html .loading ::-webkit-input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

html .loading ::-moz-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
        opacity: 1;
    }

html .loading :-ms-input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

html .loading :input-placeholder {
        color: #000000;
        color: rgba(0, 0, 0, 0);
    }

@-webkit-keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}

@keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}

/* フォームUIを囲う要素 */
/*----------------------------------------------------------------------------*/
/* タイトルとフォームUIを囲う要素 */
fieldset,
.fieldset {
    margin: 2em 0;
}
fieldset:first-child, .fieldset:first-child {
        margin-top: 0;
    }
fieldset:last-child, .fieldset:last-child {
        margin-bottom: 0;
    }
fieldset .title, .fieldset .title {
        margin-bottom: 0.5em;
        /* font-weight: bold; */
        font-size: 0.8em;
    }
fieldset > .field, .fieldset > .field {
        margin-top: 0;
        margin-bottom: 0;
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {
fieldset,
.fieldset {
        margin: 3em 0
}

        fieldset:not(.fieldset-block), .fieldset:not(.fieldset-block) {
            display: flex;
            align-items: baseline;
        }

            fieldset:not(.fieldset-block) > .title, .fieldset:not(.fieldset-block) > .title {
                margin-right: 1em;
                margin-bottom: 0;
                width: 200px;
            }
            fieldset:not(.fieldset-block) > .field,
            fieldset:not(.fieldset-block) > .field-group,
            .fieldset:not(.fieldset-block) > .field,
            .fieldset:not(.fieldset-block) > .field-group {
                flex: 1 1 0%;
            }
    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }

/* フォームUIを囲う要素の親要素 */
.field-group {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.field-group .field {
        /* display: block; */
        flex-wrap: wrap;
        /* & > * { */
    }
.field-group .field label.title {
            margin-right: 0;
            margin-bottom: 0.25em;
            width: 100%;
            font-size: 0.85em;

            display: block;
        }
/* } */
.field-group .field > *:not(:first-child) {
            margin-left: 0;
        }
/*     margin-right: 0; */
.field-group .field:first-child {
            margin-top: 0;
        }
/*     margin-left: 0; */
.field-group .field:last-child {
            margin-bottom: 0;
        }
.field-group:first-child {
        margin-top: 0;
    }
.field-group:last-child {
        margin-bottom: 0;
    }

/* フォームUIを囲う要素 */
.field {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;

    /*
    select,
    textarea {
        margin: 0;
    }
    */
}
.field > * {
        flex: 1 1 0%;
    }
.field > label,
    .field > .unit,
    .field > .btn  {
        /* IE11でバグ。flex: 1 1 0%;のような挙動 */
        /* flex: initial; */
        flex: 0 0 auto;
    }
.field > * {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
.field > *:first-child {
            margin-left: 0;
        }
.field > *:last-child {
            margin-right: 0;
        }
.field > .unit {
        margin: 0;
    }
.field > .unit:first-child {
            margin-left: 0;
        }
.field > .unit:last-child {
            margin-right: 0;
        }
/* .field内で[type="hidden"]の次の要素は余白をキャンセル */
.field > [type="hidden"]:first-child + *,
    .field > [type="hidden"] + * {
        margin-left: 0;
    }
/* .field内の先頭に[type="hidden"]の次の要素は余白をキャンセル */
.field .field {
        margin-top: 0;
        margin-bottom: 0;

        /* 病歴モーダル内のradio、checkboxで問題が出るためコメントアウト */
        /*
        & > *:not(:last-child) {
            margin-right: 0.25em;
        }
        */
    }


/* 共通 */
/*----------------------------------------------------------------------------*/
input,
select,
option,
textarea {
    font-size: 14px;
    font-size: 1rem;

    /* firefox対策、.field内のUIの最小値を修正 */
    min-width: 1em;
}
label {
    white-space: nowrap;

    /*
    &,
    & > * {
        vertical-align: middle;
        line-height: 1;
    }
    */
}


/* テキスト、セレクト */
/*----------------------------------------------------------------------------*/
/* 自動入力時の見た目をリセット */
:-webkit-autofill {
    /*
    box-shadow: 0 0 0 1000px #eee inset !important;
    // background-color: transparent !important;
    // background-color: rgba(255, 255, 0, 1.0) !important;
    */
    box-shadow: inherit !important;
    background-color: inherit !important;
}
:-webkit-autofill:active,
    :-webkit-autofill:focus {
        /*
        box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
        */
        box-shadow: inherit !important;
        background-color: inherit !important;
    }

/* placeholder */
::-webkit-input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.2);
}
::-moz-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.2);
    opacity: 1;
}
:-ms-input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.2);
}
:input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.2);
}
:focus::-webkit-input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.5);
}
:focus::-moz-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.5);
    opacity: 1;
}
:focus:-ms-input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.5);
}
:focus:input-placeholder {
    color: #333333;
    color: rgba(51,51,51, 0.5);
}


/* テキスト系のinput、textarea、select 共通 */
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
    /* サイズ */
	padding: 0.75em 0.5em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
    font-size: 16px;

    /* 線と背景 */
    border-radius: 0;
    /* border: 1px solid $color-border; */
    background-color: #fafafa;
    border: none;
    border: 1px solid #fff;
}
@media only screen and (min-width: 660px) {
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
      font-size: 18px
}
    }
input[type="text"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="url"]:hover, input[type="email"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="week"]:hover, input[type="datetime-local"]:hover, input[type="number"]:hover, input[type="file"]:hover, textarea:hover, select:hover, option:hover {

    }
input[type="text"]:active,
    input[type="text"]:focus,
    input[type="search"]:active,
    input[type="search"]:focus,
    input[type="tel"]:active,
    input[type="tel"]:focus,
    input[type="url"]:active,
    input[type="url"]:focus,
    input[type="email"]:active,
    input[type="email"]:focus,
    input[type="password"]:active,
    input[type="password"]:focus,
    input[type="datetime"]:active,
    input[type="datetime"]:focus,
    input[type="date"]:active,
    input[type="date"]:focus,
    input[type="month"]:active,
    input[type="month"]:focus,
    input[type="week"]:active,
    input[type="week"]:focus,
    input[type="datetime-local"]:active,
    input[type="datetime-local"]:focus,
    input[type="number"]:active,
    input[type="number"]:focus,
    input[type="file"]:active,
    input[type="file"]:focus,
    textarea:active,
    textarea:focus,
    select:active,
    select:focus,
    option:active,
    option:focus {
        outline: none;
        /* -webkit- */
        /* box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05) inset; */
        border-color: #28f;
        box-shadow: 0 0 0 1px inset #28f;
        background-color: #fff;
        border: 1px solid #082F6F;
    }
input[type="text"]:disabled,
    input[type="text"][readonly],
    input[type="search"]:disabled,
    input[type="search"][readonly],
    input[type="tel"]:disabled,
    input[type="tel"][readonly],
    input[type="url"]:disabled,
    input[type="url"][readonly],
    input[type="email"]:disabled,
    input[type="email"][readonly],
    input[type="password"]:disabled,
    input[type="password"][readonly],
    input[type="datetime"]:disabled,
    input[type="datetime"][readonly],
    input[type="date"]:disabled,
    input[type="date"][readonly],
    input[type="month"]:disabled,
    input[type="month"][readonly],
    input[type="week"]:disabled,
    input[type="week"][readonly],
    input[type="datetime-local"]:disabled,
    input[type="datetime-local"][readonly],
    input[type="number"]:disabled,
    input[type="number"][readonly],
    input[type="file"]:disabled,
    input[type="file"][readonly],
    textarea:disabled,
    textarea[readonly],
    select:disabled,
    select[readonly],
    option:disabled,
    option[readonly] {
        color: #000000 !important;
        color: rgba(0, 0, 0, 0.5) !important;
        background-color: #ddd !important;
        border-color: #000000 !important;
        border-color: rgba(0, 0, 0, 0.2) !important;
        box-shadow: none !important;
        cursor: default !important;
    }

/* テキスト系のinput */

input[type="number"] {
    width: 6em;
    min-width: 6em; /* IE対策 */
    flex: 0 0 6em;
}
input[type="file"] {
    padding: calc(0.75em - 1.5px) 0.5em;
    cursor: pointer;
}

/* textarea */
textarea {
    width: 100%;
    height: 8em;
    min-height: 3em;
    resize: vertical;
    display: block;
}
@media only screen and (min-width: 480px) {
textarea {
        height: 12em
}
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }

/* select (1行) */
select {
    /* padding-right: 32px; */
    padding-right: 24px;

    background-repeat: no-repeat;
    background-image: url(../img/common/icon-select-margin.svg);
    background-position: right center;
    background-size: 32px 16px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    line-height: 1;

    position: relative;
}
/* IEで矢印を非表示 */
select::-ms-expand {
        display: none;
    }
select option {

    }
select[value=""] {
    color: #f00;
}

/* select (複数行) */
select[size]:not([size="1"]),
select[multiple] {
    padding: 0;
    background-image: none;
    overflow: auto;
}
select[size]:not([size="1"]) option, select[multiple] option {
        /* padding: 0.75em 1em; */
        border: none;
    }
select[size]:not([size="1"]) optgroup, select[multiple] optgroup {
        padding: 0.5em 0;
        color: #ccc;
        font-size: 0.85em;
        font-weight: normal;
        text-indent: 5px;
    }
select[size]:not([size="1"]) optgroup > option, select[multiple] optgroup > option {
            padding-left: 0;
            padding-right: 0;
            color: #333333;
            font-size: 14px;
            font-size: 1rem;
            text-indent: 0;
        }
select[size]:not([size="1"]) optgroup > option::before, select[multiple] optgroup > option::before {
            content: "";
            width: 1em;
            display: inline-block;
        }
select[size]:not([size="1"]) optgroup > option:first-child, select[multiple] optgroup > option:first-child {
            margin-top: 5px;
            border-top: 1px dotted #ccc;
        }
select[size]:not([size="1"]) optgroup:not(:first-child), select[multiple] optgroup:not(:first-child) {
        border-top: 1px dotted #ccc;
    }
select[size]:not([size="1"]) optgroup:last-child, select[multiple] optgroup:last-child {
        padding-bottom: 0;
    }


/* バリデーション */
/* 失敗、エラー */
input[type="text"].is-invalid, input[type="search"].is-invalid, input[type="tel"].is-invalid, input[type="url"].is-invalid, input[type="email"].is-invalid, input[type="password"].is-invalid, input[type="datetime"].is-invalid, input[type="date"].is-invalid, input[type="month"].is-invalid, input[type="week"].is-invalid, input[type="datetime-local"].is-invalid, input[type="number"].is-invalid, input[type="file"].is-invalid, textarea.is-invalid, select.is-invalid {
        color: #ec5858;
        border-color: #ec5858;
        background-color: #fffafa;
    }
input[type="text"].is-invalid:hover, input[type="search"].is-invalid:hover, input[type="tel"].is-invalid:hover, input[type="url"].is-invalid:hover, input[type="email"].is-invalid:hover, input[type="password"].is-invalid:hover, input[type="datetime"].is-invalid:hover, input[type="date"].is-invalid:hover, input[type="month"].is-invalid:hover, input[type="week"].is-invalid:hover, input[type="datetime-local"].is-invalid:hover, input[type="number"].is-invalid:hover, input[type="file"].is-invalid:hover, textarea.is-invalid:hover, select.is-invalid:hover {

        }
input[type="text"].is-invalid:active,
        input[type="text"].is-invalid:focus,
        input[type="search"].is-invalid:active,
        input[type="search"].is-invalid:focus,
        input[type="tel"].is-invalid:active,
        input[type="tel"].is-invalid:focus,
        input[type="url"].is-invalid:active,
        input[type="url"].is-invalid:focus,
        input[type="email"].is-invalid:active,
        input[type="email"].is-invalid:focus,
        input[type="password"].is-invalid:active,
        input[type="password"].is-invalid:focus,
        input[type="datetime"].is-invalid:active,
        input[type="datetime"].is-invalid:focus,
        input[type="date"].is-invalid:active,
        input[type="date"].is-invalid:focus,
        input[type="month"].is-invalid:active,
        input[type="month"].is-invalid:focus,
        input[type="week"].is-invalid:active,
        input[type="week"].is-invalid:focus,
        input[type="datetime-local"].is-invalid:active,
        input[type="datetime-local"].is-invalid:focus,
        input[type="number"].is-invalid:active,
        input[type="number"].is-invalid:focus,
        input[type="file"].is-invalid:active,
        input[type="file"].is-invalid:focus,
        textarea.is-invalid:active,
        textarea.is-invalid:focus,
        select.is-invalid:active,
        select.is-invalid:focus {
            background-color: #fff;
            box-shadow: 0 0 0 1px inset #ec5858;
        }
input[type="text"].is-invalid:disabled,
        input[type="text"].is-invalid[readonly],
        input[type="search"].is-invalid:disabled,
        input[type="search"].is-invalid[readonly],
        input[type="tel"].is-invalid:disabled,
        input[type="tel"].is-invalid[readonly],
        input[type="url"].is-invalid:disabled,
        input[type="url"].is-invalid[readonly],
        input[type="email"].is-invalid:disabled,
        input[type="email"].is-invalid[readonly],
        input[type="password"].is-invalid:disabled,
        input[type="password"].is-invalid[readonly],
        input[type="datetime"].is-invalid:disabled,
        input[type="datetime"].is-invalid[readonly],
        input[type="date"].is-invalid:disabled,
        input[type="date"].is-invalid[readonly],
        input[type="month"].is-invalid:disabled,
        input[type="month"].is-invalid[readonly],
        input[type="week"].is-invalid:disabled,
        input[type="week"].is-invalid[readonly],
        input[type="datetime-local"].is-invalid:disabled,
        input[type="datetime-local"].is-invalid[readonly],
        input[type="number"].is-invalid:disabled,
        input[type="number"].is-invalid[readonly],
        input[type="file"].is-invalid:disabled,
        input[type="file"].is-invalid[readonly],
        textarea.is-invalid:disabled,
        textarea.is-invalid[readonly],
        select.is-invalid:disabled,
        select.is-invalid[readonly] {

        }
/* placeholder */
input[type="text"].is-invalid::-webkit-input-placeholder, input[type="search"].is-invalid::-webkit-input-placeholder, input[type="tel"].is-invalid::-webkit-input-placeholder, input[type="url"].is-invalid::-webkit-input-placeholder, input[type="email"].is-invalid::-webkit-input-placeholder, input[type="password"].is-invalid::-webkit-input-placeholder, input[type="datetime"].is-invalid::-webkit-input-placeholder, input[type="date"].is-invalid::-webkit-input-placeholder, input[type="month"].is-invalid::-webkit-input-placeholder, input[type="week"].is-invalid::-webkit-input-placeholder, input[type="datetime-local"].is-invalid::-webkit-input-placeholder, input[type="number"].is-invalid::-webkit-input-placeholder, input[type="file"].is-invalid::-webkit-input-placeholder, textarea.is-invalid::-webkit-input-placeholder, select.is-invalid::-webkit-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid::-moz-placeholder, input[type="search"].is-invalid::-moz-placeholder, input[type="tel"].is-invalid::-moz-placeholder, input[type="url"].is-invalid::-moz-placeholder, input[type="email"].is-invalid::-moz-placeholder, input[type="password"].is-invalid::-moz-placeholder, input[type="datetime"].is-invalid::-moz-placeholder, input[type="date"].is-invalid::-moz-placeholder, input[type="month"].is-invalid::-moz-placeholder, input[type="week"].is-invalid::-moz-placeholder, input[type="datetime-local"].is-invalid::-moz-placeholder, input[type="number"].is-invalid::-moz-placeholder, input[type="file"].is-invalid::-moz-placeholder, textarea.is-invalid::-moz-placeholder, select.is-invalid::-moz-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
            opacity: 1;
        }
input[type="text"].is-invalid:-ms-input-placeholder, input[type="search"].is-invalid:-ms-input-placeholder, input[type="tel"].is-invalid:-ms-input-placeholder, input[type="url"].is-invalid:-ms-input-placeholder, input[type="email"].is-invalid:-ms-input-placeholder, input[type="password"].is-invalid:-ms-input-placeholder, input[type="datetime"].is-invalid:-ms-input-placeholder, input[type="date"].is-invalid:-ms-input-placeholder, input[type="month"].is-invalid:-ms-input-placeholder, input[type="week"].is-invalid:-ms-input-placeholder, input[type="datetime-local"].is-invalid:-ms-input-placeholder, input[type="number"].is-invalid:-ms-input-placeholder, input[type="file"].is-invalid:-ms-input-placeholder, textarea.is-invalid:-ms-input-placeholder, select.is-invalid:-ms-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid:input-placeholder, input[type="search"].is-invalid:input-placeholder, input[type="tel"].is-invalid:input-placeholder, input[type="url"].is-invalid:input-placeholder, input[type="email"].is-invalid:input-placeholder, input[type="password"].is-invalid:input-placeholder, input[type="datetime"].is-invalid:input-placeholder, input[type="date"].is-invalid:input-placeholder, input[type="month"].is-invalid:input-placeholder, input[type="week"].is-invalid:input-placeholder, input[type="datetime-local"].is-invalid:input-placeholder, input[type="number"].is-invalid:input-placeholder, input[type="file"].is-invalid:input-placeholder, textarea.is-invalid:input-placeholder, select.is-invalid:input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid:focus::-webkit-input-placeholder, input[type="search"].is-invalid:focus::-webkit-input-placeholder, input[type="tel"].is-invalid:focus::-webkit-input-placeholder, input[type="url"].is-invalid:focus::-webkit-input-placeholder, input[type="email"].is-invalid:focus::-webkit-input-placeholder, input[type="password"].is-invalid:focus::-webkit-input-placeholder, input[type="datetime"].is-invalid:focus::-webkit-input-placeholder, input[type="date"].is-invalid:focus::-webkit-input-placeholder, input[type="month"].is-invalid:focus::-webkit-input-placeholder, input[type="week"].is-invalid:focus::-webkit-input-placeholder, input[type="datetime-local"].is-invalid:focus::-webkit-input-placeholder, input[type="number"].is-invalid:focus::-webkit-input-placeholder, input[type="file"].is-invalid:focus::-webkit-input-placeholder, textarea.is-invalid:focus::-webkit-input-placeholder, select.is-invalid:focus::-webkit-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
input[type="text"].is-invalid:focus::-moz-placeholder, input[type="search"].is-invalid:focus::-moz-placeholder, input[type="tel"].is-invalid:focus::-moz-placeholder, input[type="url"].is-invalid:focus::-moz-placeholder, input[type="email"].is-invalid:focus::-moz-placeholder, input[type="password"].is-invalid:focus::-moz-placeholder, input[type="datetime"].is-invalid:focus::-moz-placeholder, input[type="date"].is-invalid:focus::-moz-placeholder, input[type="month"].is-invalid:focus::-moz-placeholder, input[type="week"].is-invalid:focus::-moz-placeholder, input[type="datetime-local"].is-invalid:focus::-moz-placeholder, input[type="number"].is-invalid:focus::-moz-placeholder, input[type="file"].is-invalid:focus::-moz-placeholder, textarea.is-invalid:focus::-moz-placeholder, select.is-invalid:focus::-moz-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
            opacity: 1;
        }
input[type="text"].is-invalid:focus:-ms-input-placeholder, input[type="search"].is-invalid:focus:-ms-input-placeholder, input[type="tel"].is-invalid:focus:-ms-input-placeholder, input[type="url"].is-invalid:focus:-ms-input-placeholder, input[type="email"].is-invalid:focus:-ms-input-placeholder, input[type="password"].is-invalid:focus:-ms-input-placeholder, input[type="datetime"].is-invalid:focus:-ms-input-placeholder, input[type="date"].is-invalid:focus:-ms-input-placeholder, input[type="month"].is-invalid:focus:-ms-input-placeholder, input[type="week"].is-invalid:focus:-ms-input-placeholder, input[type="datetime-local"].is-invalid:focus:-ms-input-placeholder, input[type="number"].is-invalid:focus:-ms-input-placeholder, input[type="file"].is-invalid:focus:-ms-input-placeholder, textarea.is-invalid:focus:-ms-input-placeholder, select.is-invalid:focus:-ms-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
input[type="text"].is-invalid:focus:input-placeholder, input[type="search"].is-invalid:focus:input-placeholder, input[type="tel"].is-invalid:focus:input-placeholder, input[type="url"].is-invalid:focus:input-placeholder, input[type="email"].is-invalid:focus:input-placeholder, input[type="password"].is-invalid:focus:input-placeholder, input[type="datetime"].is-invalid:focus:input-placeholder, input[type="date"].is-invalid:focus:input-placeholder, input[type="month"].is-invalid:focus:input-placeholder, input[type="week"].is-invalid:focus:input-placeholder, input[type="datetime-local"].is-invalid:focus:input-placeholder, input[type="number"].is-invalid:focus:input-placeholder, input[type="file"].is-invalid:focus:input-placeholder, textarea.is-invalid:focus:input-placeholder, select.is-invalid:focus:input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
/* 成功 */
input[type="text"].is-valid, input[type="search"].is-valid, input[type="tel"].is-valid, input[type="url"].is-valid, input[type="email"].is-valid, input[type="password"].is-valid, input[type="datetime"].is-valid, input[type="date"].is-valid, input[type="month"].is-valid, input[type="week"].is-valid, input[type="datetime-local"].is-valid, input[type="number"].is-valid, input[type="file"].is-valid, textarea.is-valid, select.is-valid {
        color: #6bc55d;
        border-color: #6bc55d;
        background-color: #fafff9;
    }
input[type="text"].is-valid:hover, input[type="search"].is-valid:hover, input[type="tel"].is-valid:hover, input[type="url"].is-valid:hover, input[type="email"].is-valid:hover, input[type="password"].is-valid:hover, input[type="datetime"].is-valid:hover, input[type="date"].is-valid:hover, input[type="month"].is-valid:hover, input[type="week"].is-valid:hover, input[type="datetime-local"].is-valid:hover, input[type="number"].is-valid:hover, input[type="file"].is-valid:hover, textarea.is-valid:hover, select.is-valid:hover {

        }
input[type="text"].is-valid:active,
        input[type="text"].is-valid:focus,
        input[type="search"].is-valid:active,
        input[type="search"].is-valid:focus,
        input[type="tel"].is-valid:active,
        input[type="tel"].is-valid:focus,
        input[type="url"].is-valid:active,
        input[type="url"].is-valid:focus,
        input[type="email"].is-valid:active,
        input[type="email"].is-valid:focus,
        input[type="password"].is-valid:active,
        input[type="password"].is-valid:focus,
        input[type="datetime"].is-valid:active,
        input[type="datetime"].is-valid:focus,
        input[type="date"].is-valid:active,
        input[type="date"].is-valid:focus,
        input[type="month"].is-valid:active,
        input[type="month"].is-valid:focus,
        input[type="week"].is-valid:active,
        input[type="week"].is-valid:focus,
        input[type="datetime-local"].is-valid:active,
        input[type="datetime-local"].is-valid:focus,
        input[type="number"].is-valid:active,
        input[type="number"].is-valid:focus,
        input[type="file"].is-valid:active,
        input[type="file"].is-valid:focus,
        textarea.is-valid:active,
        textarea.is-valid:focus,
        select.is-valid:active,
        select.is-valid:focus {
            background-color: #fff;
            box-shadow: 0 0 0 1px inset #6bc55d;
        }
input[type="text"].is-valid:disabled,
        input[type="text"].is-valid[readonly],
        input[type="search"].is-valid:disabled,
        input[type="search"].is-valid[readonly],
        input[type="tel"].is-valid:disabled,
        input[type="tel"].is-valid[readonly],
        input[type="url"].is-valid:disabled,
        input[type="url"].is-valid[readonly],
        input[type="email"].is-valid:disabled,
        input[type="email"].is-valid[readonly],
        input[type="password"].is-valid:disabled,
        input[type="password"].is-valid[readonly],
        input[type="datetime"].is-valid:disabled,
        input[type="datetime"].is-valid[readonly],
        input[type="date"].is-valid:disabled,
        input[type="date"].is-valid[readonly],
        input[type="month"].is-valid:disabled,
        input[type="month"].is-valid[readonly],
        input[type="week"].is-valid:disabled,
        input[type="week"].is-valid[readonly],
        input[type="datetime-local"].is-valid:disabled,
        input[type="datetime-local"].is-valid[readonly],
        input[type="number"].is-valid:disabled,
        input[type="number"].is-valid[readonly],
        input[type="file"].is-valid:disabled,
        input[type="file"].is-valid[readonly],
        textarea.is-valid:disabled,
        textarea.is-valid[readonly],
        select.is-valid:disabled,
        select.is-valid[readonly] {

        }
/* placeholder */
input[type="text"].is-valid::-webkit-input-placeholder, input[type="search"].is-valid::-webkit-input-placeholder, input[type="tel"].is-valid::-webkit-input-placeholder, input[type="url"].is-valid::-webkit-input-placeholder, input[type="email"].is-valid::-webkit-input-placeholder, input[type="password"].is-valid::-webkit-input-placeholder, input[type="datetime"].is-valid::-webkit-input-placeholder, input[type="date"].is-valid::-webkit-input-placeholder, input[type="month"].is-valid::-webkit-input-placeholder, input[type="week"].is-valid::-webkit-input-placeholder, input[type="datetime-local"].is-valid::-webkit-input-placeholder, input[type="number"].is-valid::-webkit-input-placeholder, input[type="file"].is-valid::-webkit-input-placeholder, textarea.is-valid::-webkit-input-placeholder, select.is-valid::-webkit-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid::-moz-placeholder, input[type="search"].is-valid::-moz-placeholder, input[type="tel"].is-valid::-moz-placeholder, input[type="url"].is-valid::-moz-placeholder, input[type="email"].is-valid::-moz-placeholder, input[type="password"].is-valid::-moz-placeholder, input[type="datetime"].is-valid::-moz-placeholder, input[type="date"].is-valid::-moz-placeholder, input[type="month"].is-valid::-moz-placeholder, input[type="week"].is-valid::-moz-placeholder, input[type="datetime-local"].is-valid::-moz-placeholder, input[type="number"].is-valid::-moz-placeholder, input[type="file"].is-valid::-moz-placeholder, textarea.is-valid::-moz-placeholder, select.is-valid::-moz-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
            opacity: 1;
        }
input[type="text"].is-valid:-ms-input-placeholder, input[type="search"].is-valid:-ms-input-placeholder, input[type="tel"].is-valid:-ms-input-placeholder, input[type="url"].is-valid:-ms-input-placeholder, input[type="email"].is-valid:-ms-input-placeholder, input[type="password"].is-valid:-ms-input-placeholder, input[type="datetime"].is-valid:-ms-input-placeholder, input[type="date"].is-valid:-ms-input-placeholder, input[type="month"].is-valid:-ms-input-placeholder, input[type="week"].is-valid:-ms-input-placeholder, input[type="datetime-local"].is-valid:-ms-input-placeholder, input[type="number"].is-valid:-ms-input-placeholder, input[type="file"].is-valid:-ms-input-placeholder, textarea.is-valid:-ms-input-placeholder, select.is-valid:-ms-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid:input-placeholder, input[type="search"].is-valid:input-placeholder, input[type="tel"].is-valid:input-placeholder, input[type="url"].is-valid:input-placeholder, input[type="email"].is-valid:input-placeholder, input[type="password"].is-valid:input-placeholder, input[type="datetime"].is-valid:input-placeholder, input[type="date"].is-valid:input-placeholder, input[type="month"].is-valid:input-placeholder, input[type="week"].is-valid:input-placeholder, input[type="datetime-local"].is-valid:input-placeholder, input[type="number"].is-valid:input-placeholder, input[type="file"].is-valid:input-placeholder, textarea.is-valid:input-placeholder, select.is-valid:input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid:focus::-webkit-input-placeholder, input[type="search"].is-valid:focus::-webkit-input-placeholder, input[type="tel"].is-valid:focus::-webkit-input-placeholder, input[type="url"].is-valid:focus::-webkit-input-placeholder, input[type="email"].is-valid:focus::-webkit-input-placeholder, input[type="password"].is-valid:focus::-webkit-input-placeholder, input[type="datetime"].is-valid:focus::-webkit-input-placeholder, input[type="date"].is-valid:focus::-webkit-input-placeholder, input[type="month"].is-valid:focus::-webkit-input-placeholder, input[type="week"].is-valid:focus::-webkit-input-placeholder, input[type="datetime-local"].is-valid:focus::-webkit-input-placeholder, input[type="number"].is-valid:focus::-webkit-input-placeholder, input[type="file"].is-valid:focus::-webkit-input-placeholder, textarea.is-valid:focus::-webkit-input-placeholder, select.is-valid:focus::-webkit-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }
input[type="text"].is-valid:focus::-moz-placeholder, input[type="search"].is-valid:focus::-moz-placeholder, input[type="tel"].is-valid:focus::-moz-placeholder, input[type="url"].is-valid:focus::-moz-placeholder, input[type="email"].is-valid:focus::-moz-placeholder, input[type="password"].is-valid:focus::-moz-placeholder, input[type="datetime"].is-valid:focus::-moz-placeholder, input[type="date"].is-valid:focus::-moz-placeholder, input[type="month"].is-valid:focus::-moz-placeholder, input[type="week"].is-valid:focus::-moz-placeholder, input[type="datetime-local"].is-valid:focus::-moz-placeholder, input[type="number"].is-valid:focus::-moz-placeholder, input[type="file"].is-valid:focus::-moz-placeholder, textarea.is-valid:focus::-moz-placeholder, select.is-valid:focus::-moz-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
            opacity: 1;
        }
input[type="text"].is-valid:focus:-ms-input-placeholder, input[type="search"].is-valid:focus:-ms-input-placeholder, input[type="tel"].is-valid:focus:-ms-input-placeholder, input[type="url"].is-valid:focus:-ms-input-placeholder, input[type="email"].is-valid:focus:-ms-input-placeholder, input[type="password"].is-valid:focus:-ms-input-placeholder, input[type="datetime"].is-valid:focus:-ms-input-placeholder, input[type="date"].is-valid:focus:-ms-input-placeholder, input[type="month"].is-valid:focus:-ms-input-placeholder, input[type="week"].is-valid:focus:-ms-input-placeholder, input[type="datetime-local"].is-valid:focus:-ms-input-placeholder, input[type="number"].is-valid:focus:-ms-input-placeholder, input[type="file"].is-valid:focus:-ms-input-placeholder, textarea.is-valid:focus:-ms-input-placeholder, select.is-valid:focus:-ms-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }
input[type="text"].is-valid:focus:input-placeholder, input[type="search"].is-valid:focus:input-placeholder, input[type="tel"].is-valid:focus:input-placeholder, input[type="url"].is-valid:focus:input-placeholder, input[type="email"].is-valid:focus:input-placeholder, input[type="password"].is-valid:focus:input-placeholder, input[type="datetime"].is-valid:focus:input-placeholder, input[type="date"].is-valid:focus:input-placeholder, input[type="month"].is-valid:focus:input-placeholder, input[type="week"].is-valid:focus:input-placeholder, input[type="datetime-local"].is-valid:focus:input-placeholder, input[type="number"].is-valid:focus:input-placeholder, input[type="file"].is-valid:focus:input-placeholder, textarea.is-valid:focus:input-placeholder, select.is-valid:focus:input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }



/* ラジオボタン、チェックボックス */
/*----------------------------------------------------------------------------*/

/* ラジオボタン、チェックボックスを囲んでいるlabel */
.field-radio,
.field-checkbox {
    flex-wrap: wrap;
    margin: 0.5em -0.5em;

    /* 「.field > *」のmargin、paddingをキャンセル */
    /* 将来的にはjQueryを使用せず */
    /* :focus-withinで対応可能。 */
    /* & > label:active, */
    /* & > label:focus-within, */
}
/*
    & > label,
    & > label:not(:first-child),
    & > label:not(:last-child) {
        margin: 0;
        padding: 0.5em;
    }
    */
.field-radio > *,
    .field-radio > *:not(:first-child),
    .field-radio > *:not(:last-child),
    .field-checkbox > *,
    .field-checkbox > *:not(:first-child),
    .field-checkbox > *:not(:last-child) {
        margin: 0;
        padding: 0.5em;
        flex: 0 0 auto;
    }
/* WordPress MW WP Form */
.field-radio > .mwform-radio-field,
    .field-radio > .mwform-checkbox-field,
    .field-checkbox > .mwform-radio-field,
    .field-checkbox > .mwform-checkbox-field {
        margin: 0 !important;
    }
.field-radio > .mwform-radio-field.vertical-item, .field-radio > .mwform-checkbox-field.vertical-item, .field-checkbox > .mwform-radio-field.vertical-item, .field-checkbox > .mwform-checkbox-field.vertical-item {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 0.5em - 0.5em);
        }
/* & > label { */
.field-radio label, .field-checkbox label {
        /* MW WP Formではみ出るのでコメントアウト */
        /* max-width: calc(100% - 0.5em - 0.5em); */
        color: #333333;
        display: flex;
        align-items: center;
        /* IEでラジオボタンが横方向に潰れる */
        /* flex: 0 1 7em; */
        /* flex: 0 0 auto; */
        cursor: pointer;
        white-space: nowrap;
    }
.field-radio label .icon, .field-checkbox label .icon {
            /* flex: 0 0 1.5rem; */
        }
.field-radio label span, .field-checkbox label span {
            flex: 1 1 calc(100% - 1.75rem);
        }
/* & > label:hover { */
.field-radio label:hover, .field-checkbox label:hover {

    }
/* & > label.focus { */
.field-radio label:active,
    .field-radio label:focus-within,
    .field-radio label.focus,
    .field-checkbox label:active,
    .field-checkbox label:focus-within,
    .field-checkbox label.focus {

    }
/* & > label.disabled { */
.field-radio label.disabled,
        .field-radio label.disabled *,
        .field-radio label.disabled input[type="radio"],
        .field-radio label.disabled input[type="checkbox"],
        .field-checkbox label.disabled,
        .field-checkbox label.disabled *,
        .field-checkbox label.disabled input[type="radio"],
        .field-checkbox label.disabled input[type="checkbox"] {
            color: #ddd;
            cursor: default;
        }

/*
// フォントで実装
// radio、checkbox共通
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;

        & + .icon {
            margin: 0 0.25rem 0 -1rem;
            padding: 0;
            font-size: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & + .icon::before {
            font-size: 1.25rem;
            margin: 0;
            display: block;

        }

        & ~ * {
            color: $color-gray;
        }
        &:checked {
            & + .icon::before,
            & ~ * {
                color: $color-btn;
            }
        }

        // Firefoxでは「:read-only」はdisabled以外のときも適用されてしまう
        &:disabled,
        &[readonly] {
            &:checked {
                & + .icon::before,
                & ~ * {
                    color: #ddd;
                    cursor: default;
                }
            }
        }
    }
}

// radio
input[type="radio"].before-icon {
    & + .icon::before {
        @mixin icon-radio;
    }
    &:checked + .icon::before {
        @mixin icon-radio-checked-fill;
    }
}

// checkbox
input[type="checkbox"].before-icon {
    & + .icon::before {
        @mixin icon-checkbox-round;
    }
    &:checked + .icon::before {
        @mixin icon-checkbox-round-checked-fill;
    }
}
*/


/* CSSで実装 */
/* radio、checkbox共通 */
input[type="radio"],
input[type="checkbox"] {
    margin-right: 14px;
    margin-right: 1rem;
    cursor: pointer;
}
input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        margin: 0;
        opacity: 0;
        width: 14px;
        width: 1rem;
        height: 14px;
        height: 1rem;
        font-size: 14px;
        font-size: 1rem;
    }
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
            margin-right: 5px;
            padding: 0;
            flex: 0 0 21px;
            flex: 0 0 1.5rem;
            font-size: 21px;
            font-size: 1.5rem;
            width: 21px;
            width: 1.5rem;
            height: 21px;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;

        }
@media only screen and (min-width: 660px) {
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
              margin-right: 10px

        }
            }
input[type="radio"].before-icon + .icon::before, input[type="checkbox"].before-icon + .icon::before {
            -webkit-transform: none;
                    transform: none;
            display: block;
        }

/* radio */
input[type="radio"].before-icon + .icon {
        border-radius: 50%;

        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
        background-color: #fafafa;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
input[type="radio"].before-icon + .icon::before {
            content: "";
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
        }
input[type="radio"].before-icon:checked + .icon {
        background-color: #fff;
    }
/* box-shadow: 0 0 0 2px $color-text inset; */
input[type="radio"].before-icon:checked + .icon::before {
            background-color: #333333;
            background-color: rgba(51,51,51, 0.75);
        }
/* focus */
input[type="radio"].before-icon:focus {
        outline: none;
    }
/* box-shadow: 0 0 0 2px $color-link inset; */
input[type="radio"].before-icon:active + .icon::before, input[type="radio"].before-icon:focus + .icon::before {

            }
input[type="radio"].before-icon:active:checked + .icon::before, input[type="radio"].before-icon:focus:checked + .icon::before {
                background-color: #2288ff;
                background-color: rgba(34,136,255, 0.75);
            }
/* disabled */
input[type="radio"].before-icon:disabled + .icon, input[type="radio"].before-icon[readonly] + .icon {
            background-color: #ddd;
            /* box-shadow: 0 0 0 2px $color-border inset; */
            color: #ddd;
            cursor: default;
        }
input[type="radio"].before-icon:disabled + .icon::before, input[type="radio"].before-icon[readonly] + .icon::before {

            }
input[type="radio"].before-icon:disabled:checked + .icon::before, input[type="radio"].before-icon[readonly]:checked + .icon::before {
                background-color: #000000;
                background-color: rgba(0, 0, 0, 0.2);
            }

/* checkbox */
input[type="checkbox"].before-icon + .icon {
        border-radius: 0;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
        background-color: #fafafa;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        display: inline-block;
        vertical-align: text-bottom;
        width: 16px;
        height: 16px;
    }
@media only screen and (min-width: 660px) {
input[type="checkbox"].before-icon + .icon {
          width: 22px;
          height: 22px
    }
        }
/* display: flex;
        align-items: center;
        justify-content: center; */
input[type="checkbox"].before-icon + .icon::before {
            content: "";
            width: 90%;
            height: 40%;
            /* background: #f00; */
            border-left: 4px solid transparent;
            border-bottom: 4px solid transparent;
            display: block;
            box-sizing: border-box;
            -webkit-transform: translate(10%, -40%) rotate(-45deg);
                    transform: translate(10%, -40%) rotate(-45deg);
            position: relative;
            top: 7px;
        }
input[type="checkbox"].before-icon:checked + .icon {
        background-color: #fff;
    }
/* box-shadow: 0 0 0 2px $color-text inset; */
input[type="checkbox"].before-icon:checked + .icon::before {
            border-color: #333333;
            border-color: rgba(51,51,51, 0.75);
        }
/* focus */
input[type="checkbox"].before-icon:focus {
        outline: none;
    }
/* box-shadow: 0 0 0 2px $color-link inset; */
input[type="checkbox"].before-icon:active + .icon::before, input[type="checkbox"].before-icon:focus + .icon::before {

            }
input[type="checkbox"].before-icon:active:checked + .icon::before, input[type="checkbox"].before-icon:focus:checked + .icon::before {
                border-color: #2288ff;
                border-color: rgba(34,136,255, 0.75);
            }
/* disabled */
input[type="checkbox"].before-icon:disabled + .icon, input[type="checkbox"].before-icon[readonly] + .icon {
            background-color: #ddd;
            /* box-shadow: 0 0 0 2px $color-border inset; */
            color: #ddd;
            cursor: default;
        }
input[type="checkbox"].before-icon:disabled + .icon::before, input[type="checkbox"].before-icon[readonly] + .icon::before {

            }
input[type="checkbox"].before-icon:disabled:checked + .icon::before, input[type="checkbox"].before-icon[readonly]:checked + .icon::before {
                border-color: #000000;
                border-color: rgba(0, 0, 0, 0.2);
            }


/* バリデーション */
/* 失敗、エラー */
input[type="radio"].before-icon.is-invalid + .icon {
            box-shadow: 0 0 0 2px rgba(236,88,88, 0.25) inset;
            background-color: #fffafa;
        }
input[type="radio"].before-icon.is-invalid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #ec5858 inset;
        }
input[type="radio"].before-icon.is-invalid:checked + .icon::before {
                background-color: #ec5858;
            }
/* focus */
input[type="radio"].before-icon.is-invalid:active + .icon, input[type="radio"].before-icon.is-invalid:focus + .icon {
                background: #ec5858;
                background: rgba(236,88,88, 0.2);
            }
input[type="radio"].before-icon.is-invalid:active + .icon::before, input[type="radio"].before-icon.is-invalid:focus + .icon::before {

                }
input[type="radio"].before-icon.is-invalid:active:checked + .icon::before, input[type="radio"].before-icon.is-invalid:focus:checked + .icon::before {

                }
/* 成功 */
input[type="radio"].before-icon.is-valid + .icon {
            box-shadow: 0 0 0 2px rgba(107,197,93, 0.25) inset;
        }
input[type="radio"].before-icon.is-valid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #6bc55d inset;
        }
input[type="radio"].before-icon.is-valid:checked + .icon::before {
                background-color: #6bc55d;
            }
/* focus */
input[type="radio"].before-icon.is-valid:active + .icon, input[type="radio"].before-icon.is-valid:focus + .icon {
                background: #6bc55d;
                background: rgba(107,197,93, 0.2);
            }
input[type="radio"].before-icon.is-valid:active + .icon::before, input[type="radio"].before-icon.is-valid:focus + .icon::before {

                }
input[type="radio"].before-icon.is-valid:active:checked + .icon::before, input[type="radio"].before-icon.is-valid:focus:checked + .icon::before {

                }
/* 失敗、エラー */
input[type="checkbox"].before-icon.is-invalid + .icon {
            box-shadow: 0 0 0 2px rgba(236,88,88, 0.25) inset;
            background-color: #fffafa;
        }
input[type="checkbox"].before-icon.is-invalid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #ec5858 inset;
        }
input[type="checkbox"].before-icon.is-invalid:checked + .icon::before {
                border-color: #ec5858;
            }
/* focus */
input[type="checkbox"].before-icon.is-invalid:active + .icon, input[type="checkbox"].before-icon.is-invalid:focus + .icon {
                background: #ec5858;
                background: rgba(236,88,88, 0.2);
            }
input[type="checkbox"].before-icon.is-invalid:active + .icon::before, input[type="checkbox"].before-icon.is-invalid:focus + .icon::before {

                }
input[type="checkbox"].before-icon.is-invalid:active:checked + .icon::before, input[type="checkbox"].before-icon.is-invalid:focus:checked + .icon::before {

                }
/* 成功 */
input[type="checkbox"].before-icon.is-valid + .icon {
            box-shadow: 0 0 0 2px rgba(107,197,93, 0.25) inset;
            background-color: #fafff9;
        }
input[type="checkbox"].before-icon.is-valid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #6bc55d inset;
        }
input[type="checkbox"].before-icon.is-valid:checked + .icon::before {
                border-color: #6bc55d;
            }
/* focus */
input[type="checkbox"].before-icon.is-valid:active + .icon, input[type="checkbox"].before-icon.is-valid:focus + .icon {
                background: #6bc55d;
                background: rgba(107,197,93, 0.2);
            }
input[type="checkbox"].before-icon.is-valid:active + .icon::before, input[type="checkbox"].before-icon.is-valid:focus + .icon::before {

                }
input[type="checkbox"].before-icon.is-valid:active:checked + .icon::before, input[type="checkbox"].before-icon.is-valid:focus:checked + .icon::before {

                }


/* range */
/*----------------------------------------------------------------------------*/
.field-range {
    padding: 0.75em 0;
}

/* 横線 */

/* つまみ */

input[type="range"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.2);
    /* border-radius: 6px; */
    cursor: pointer;
    position: relative;
}

/*
    &::-webkit-slider-runnable-track {
        @mixin range-track;
    }
    */

input[type="range"]::-moz-range-track {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        margin: auto 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;

    }

/* 横線 */

input[type="range"]::-ms-track {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        margin: auto 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
    }

/* つまみ */

input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-webkit-slider-thumb:hover,
        input[type="range"]::-webkit-slider-thumb:active {
            -webkit-transform: scale(1.25);
                    transform: scale(1.25);
        }

input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-moz-range-thumb:hover,
        input[type="range"]::-moz-range-thumb:active {
            transform: scale(1.25);
        }

input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-ms-thumb:hover,
        input[type="range"]::-ms-thumb:active {
            transform: scale(1.25);
        }

input[type="range"]:focus {
        outline: none;
        background-color: #2288ff;
        background-color: rgba(34,136,255, 0.75);
    }

/*
        &::-webkit-slider-runnable-track {
            outline: none;
            background-color: rgba($color-link, 0.75);
        }
        */

input[type="range"]:focus::-moz-range-track {
            outline: none;
            background-color: #2288ff;
            background-color: rgba(34,136,255, 0.75);
        }

input[type="range"]:focus::-ms-track {
            outline: none;
            background-color: #2288ff;
            background-color: rgba(34,136,255, 0.75);
        }

input[type="range"]:focus::-webkit-slider-thumb {
            outline: none;
            border-color: #28f;
            -webkit-transform: scale(1.25);
                    transform: scale(1.25);
        }

input[type="range"]:focus::-moz-range-thumb {
            outline: none;
            border-color: #28f;
            transform: scale(1.25);
        }

input[type="range"]:focus::-ms-thumb {
            outline: none;
            border-color: #28f;
            transform: scale(1.25);
        }

/* disabled */

input[type="range"]:disabled,
    input[type="range"][readonly] {
        cursor: default !important;
    }

/*
        &::-webkit-slider-runnable-track {

        }
        */

input[type="range"]:disabled::-moz-range-track, input[type="range"][readonly]::-moz-range-track {

        }

/* 横線 */

input[type="range"]:disabled::-ms-track, input[type="range"][readonly]::-ms-track {

        }

/* つまみ */

input[type="range"]:disabled::-webkit-slider-thumb, input[type="range"][readonly]::-webkit-slider-thumb {
            -webkit-transform: none !important;
                    transform: none !important;
            cursor: default !important;
        }

input[type="range"]:disabled::-moz-range-thumb, input[type="range"][readonly]::-moz-range-thumb {
            transform: none !important;
            cursor: default !important;
        }

input[type="range"]:disabled::-ms-thumb, input[type="range"][readonly]::-ms-thumb {
            transform: none !important;
            cursor: default !important;
        }

/* 失敗、エラー */

input[type="range"].is-invalid {
        background-color: #ec5858;
    }

/*
        &::-webkit-slider-runnable-track {
            background-color: $color-danger;
        }
        */

input[type="range"].is-invalid::-moz-range-track {
            background-color: #ec5858;

        }

/* 横線 */

input[type="range"].is-invalid::-ms-track {
            background-color: #ec5858;
        }

/* つまみ */

input[type="range"].is-invalid::-webkit-slider-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

input[type="range"].is-invalid::-moz-range-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

input[type="range"].is-invalid::-ms-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

/* 成功 */

input[type="range"].is-valid {
        background-color: #6bc55d;
    }

/*
        &::-webkit-slider-runnable-track {
            background-color: $color-success;
        }
        */

input[type="range"].is-valid::-moz-range-track {
            background-color: #6bc55d;
        }

/* 横線 */

input[type="range"].is-valid::-ms-track {
            background-color: #6bc55d;
        }

/* つまみ */

input[type="range"].is-valid::-webkit-slider-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }

input[type="range"].is-valid::-moz-range-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }

input[type="range"].is-valid::-ms-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }


/* ファイル */
/*----------------------------------------------------------------------------*/
/* MW WP Formだと右側に余白ができる */
.field.field-file input[type="file"] {
        margin-right: 0;
        display: block;
    }
/* MW WP Formだと「×」ボタンが表示されるので消す */
.field.field-file .mwform-file-delete {
        margin-left: -0.5em;
        flex: 0 0 0%;
        display: none;
    }

/* 検索 */
/*----------------------------------------------------------------------------*/
.field.field-search input {
        padding-left: 32px;

        /* padding-right: 32px; */
        background-repeat: no-repeat;
        background-image: url(../img/common/icon-search-margin.svg);
        background-position: left center;
        background-size: 32px 16px;
    }


/* 年月日 */
/*----------------------------------------------------------------------------*/
.field-date .field-year {
        flex: 3 1 0%;
    }
.field-date .field-month,
    .field-date .field-day {
        flex: 2 1 0%;
    }


/* 同意チェックボックス */
/*----------------------------------------------------------------------------*/
.field.field-agree {
    display: flex;
    align-items: center;
    justify-content: center;
}
.field.field-agree > * {
        margin: 0;
        flex: 0 0 auto;
    }
.field.field-agree > label {
        margin-right: 0.5em;
        display: flex;
        align-items: center;
    }
.field.field-agree > label input[type="checkbox"].before-icon {
            position: absolute;
        }
.field.field-agree > label input[type="checkbox"].before-icon + .icon {
            margin: 0;
        }
.field.field-agree > span {
        margin-left: 0;
    }


/* 画像radio */
/*----------------------------------------------------------------------------*/
.field-img-radio {
    position: relative;
}
.field-img-radio > label {
        flex: 1 1 auto;
    }
.field-img-radio > label img {
            width: 100%;
        }
.field-img-radio input,
    .field-img-radio .icon {
        opacity: 0;
        position: absolute;
    }
.field-img-radio input ~ img {
        opacity: 0.5;
    }
.field-img-radio input:checked ~ img {
        opacity: 1.0;
        box-shadow: 0 0 0 4px #333333 inset;
    }


/* 日付 カスタマイズ */
/*----------------------------------------------------------------------------*/
body .ui-corner-all,
    body .ui-corner-bottom,
    body .ui-corner-right,
    body .ui-corner-br {
        border-radius: 0;
    }
body .ui-datepicker {
        margin: -1em 0 0 1em;
        padding: 0;
        width: 283px;
    }
body .ui-datepicker .ui-widget-header {
            border: none;
            background: #f4f4f4;
        }
body .ui-datepicker select.ui-datepicker-month,
        body .ui-datepicker select.ui-datepicker-year {
            padding: 10px 15px;
            background-image: none;
        }
body .ui-datepicker thead th {
            border-bottom: 1px solid #ddd;
        }
body .ui-datepicker .ui-datepicker-calendar {
            margin: 0;

        }
body .ui-datepicker .ui-state-default,
        body .ui-datepicker .ui-widget-content .ui-state-default,
        body .ui-datepicker .ui-widget-header .ui-state-default {
            border-color: #ddd;
            background: #f5f5f5;
        }
body .ui-datepicker .ui-state-highlight,
        body .ui-datepicker .ui-widget-content .ui-state-highlight,
        body .ui-datepicker .ui-widget-header .ui-state-highlight {
            border-color: #28f;
            color: #28f;
            background: #def5ff;
        }

.datetimepicker-date {
    padding-right: 24px;
    background-image: url(../img/common/icon-calendar-margin.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 32px 16px;
    cursor: pointer;
}
.xdsoft_datetimepicker table {
        display: table;
    }
.xdsoft_datetimepicker table thead {
            display: table-header-group;
        }
.xdsoft_datetimepicker table tbody {
            display: table-header-group;
        }
.xdsoft_datetimepicker table tfoot {
            display: table-footer-group;
        }
.xdsoft_datetimepicker table tr {
            display: table-row;
        }
.xdsoft_datetimepicker table td,
        .xdsoft_datetimepicker table th {
            display: table-cell;
        }
@media only screen and (min-width: 0px) and (max-width: 479px) {
            .xdsoft_datetimepicker table,
            .xdsoft_datetimepicker table thead,
            .xdsoft_datetimepicker table tbody,
            .xdsoft_datetimepicker table tfoot,
            .xdsoft_datetimepicker table tr,
            .xdsoft_datetimepicker table td,
            .xdsoft_datetimepicker table th,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal),
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) thead,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tbody,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tfoot,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tr,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) td,
            .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) th {
                border-right-style: none;
                border-left-style: none;
            }
    }


/* バリデーション */
/*----------------------------------------------------------------------------*/
.field + .invalid-feedback,
.field + .valid-feedback {
    margin-top: 0;
    margin-bottom: 20px;
}
.field + .invalid-feedback:last-child, .field + .valid-feedback:last-child {
        margin-bottom: 0;
    }

/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    padding: .75em 1em;
    line-height: 1.5;
    border-radius: 4px;
    background-color: #aaa;
    color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
}
.alert .btn {
        margin-left: auto;
        padding: 0;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        flex: 0 0 2em;
        font-size: .5em;
        line-height: 1;
        border: none;
        border-radius: 50%;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.alert .btn:hover {
        color: #fff;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.5);
    }
.alert.alert-danger {
        background-color: #ec5858;
    }
.alert.alert-warning {
        background-color: #e6bd41;
    }
.alert.alert-success {
        background-color: #6bc55d;
    }

.alert-group {
    position: fixed;
    top: calc(0 + 5px);
    left: 5px;
    right: 5px;
    z-index: 10000;
}

.alert-group .alert:not(:last-child) {
        margin-bottom: 5px;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

.alert-group {
        top: calc(0 + 5px)
}
    }

.alert-group.alert-group-static {
        position: static;
    }

.alert-group.alert-group-static .alert {
            position: static;
        }

/* タグ、バッジ、ラベル(Semantic UI) */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* https://semantic-ui.com/elements/label.html */
/*----------------------------------------------------------------------------*/

.badge-group {
    margin: -0.25em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.badge-group > * {
        margin: 0.25em;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

.badge {
    /* padding: 0.5em 1.0em; */
    /* background: #aaa; */
    /* color: #fff; */
    border: none;
    font-size: 12px;
    text-align: center;
    line-height: 1;
    box-shadow: 0 0 0 1px #333333 inset;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

/* ボタンのサイズ */

.badge.badge-lg {
        font-size: 14px;
        font-size: 1rem;
    }

.badge.badge-sm {
        font-size: 10px;
    }

/* ボタンの形 */

.badge,
    .badge.badge-rect {
        padding: 0.5em 1.0em;
        border-radius: 4px;
    }

.badge.badge-pill {
        padding: 0.5em 1.0em;
        border-radius: calc(1em + 1em + 1em);
    }

.badge.badge-circle {
        padding: 0;
        width: 2em;
        height: 2em;
        border-radius: 2em;
    }

/* ボタンの枠線、背景色 */

.badge,
    .badge.badge-fill {
        background-color: #333333;
        color: #fff;

    }

.badge.badge-outline {
        background-color: transparent;
        color: #333333;
    }

.badge-danger {
    box-shadow: 0 0 0 1px #ec5858 inset;
}

.badge-danger,
    .badge-danger.badge-fill {
        background-color: #ec5858;
        color: #fff;

    }

.badge-danger.badge-outline {
        background-color: transparent;
        color: #ec5858;
    }
.badge-warning {
    box-shadow: 0 0 0 1px #e6bd41 inset;
}
.badge-warning,
    .badge-warning.badge-fill {
        background-color: #e6bd41;
        color: #fff;

    }
.badge-warning.badge-outline {
        background-color: transparent;
        color: #e6bd41;
    }
.badge-success {
    box-shadow: 0 0 0 1px #6bc55d inset;
}
.badge-success,
    .badge-success.badge-fill {
        background-color: #6bc55d;
        color: #fff;

    }
.badge-success.badge-outline {
        background-color: transparent;
        color: #6bc55d;
    }

/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.btn-group > *:not(:last-child) {
    }

@media only screen and (min-width: 660px) {

.btn-group {
        margin-top: 80px
}
    }

input.btn,
button.btn {
    outline: none;
}

.btn {
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    background: #082F6F;
    color: #ffffff;
    border: none;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    border-radius: 2px;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  	text-transform: uppercase;
  	transition: all .3s;
  	overflow: hidden;
  	z-index: 1;
    background-color: #082F6F;
}

@media only screen and (min-width: 660px) {

.btn {
      width: auto;
      min-width: 282px;
      height: 60px;
      line-height: 60px;
      font-size: 18px
}
    }

/*
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $color-bg-blue-dark;
        border-radius: 2px;
        transition: all .3s;
        z-index: -1;
    } */

.btn:hover {

      /*
        &:before {
            width: 100%;
        }
         */
    }

@media only screen and (min-width: 770px) {

.btn:hover {
        opacity: 0.8
    }
      }

/* &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-bg-blue;
        border-radius: 2px;
        z-index: -2;
    }
 */

.btn.btn-gray {
      background-color: #C4C4C4;

    }

@media only screen and (min-width: 660px) {

.btn.btn-gray {
        width: 146px;
        min-width: auto

    }
      }

.btn.btn-small {
        min-width: 150px;
        height: 45px;
        line-height: 45px;
    }

@media only screen and (min-width: 660px) {

.btn.btn-small {
            margin-left: inherit
    }
        }

.btn.disabled,
        .btn.disabled:hover,
        .btn:disabled,
        .btn:disabled:hover,
        .btn[readonly],
        .btn[readonly]:hover {
            background-color: #777;
            box-shadow: 0 0 0 2px #777 inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;
        }

.btn.disabled::after, .btn.disabled:hover::after, .btn:disabled::after, .btn:disabled:hover::after, .btn[readonly]::after, .btn[readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }

.btn-red {
    text-transform: uppercase;
    transition: all .3s;
    overflow: hidden;
    z-index: 1;
    position: relative;
    color: #ffffff;
    background-color: #A32E1F;

    /*
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-red;
        border-radius: 2px;
        z-index: -2;
    }
     */
}

/*
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $color-red-dark;
        border-radius: 2px;
        transition: all .3s;
        z-index: -1;
    }
     */

.btn-red:hover {

        /* &:before {
            width: 100%;
        } */
    }

@media only screen and (min-width: 770px) {

.btn-red:hover {
        opacity: 0.8 !important
    }
      }

.btn-white {
    box-shadow: 0 0 0 2px #fff inset;
}

/* ボタンの文字色、背景色、枠線の色(box-shadow) */

.btn-white,
    .btn-white.btn-fill {
        background-color: #fff;
        color: #333333;
    }

.btn-white:hover, .btn-white.btn-fill:hover {
            background-color: transparent;
            color: #fff;
        }

.btn-white.btn-outline {
        background-color: transparent;
        color: #fff;
    }

.btn-white.btn-outline:hover {
            background-color: #fff;
            color: #333333;
        }

.btn-white.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #fff;
    }

.btn-white.btn-skeleton:hover {
            background-color: #ffffff;
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
        }

.pagination {
    display: flex;
    align-items: center;
}

/*
    box-shadow: 0 0 0 2px #777 inset;
    border-radius: 4px;
    */

.pagination > * {
        padding: 0.75em;
        min-width: calc(1em + 0.75em + 0.75em);
        min-height: calc(1em + 0.75em + 0.75em);

        /* .btn { } からコピペ */
        border: none;
        font-size: 1em;
        text-align: center;
        line-height: 1;
        box-shadow: 0 0 0 2px #777 inset;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        box-sizing: border-box;

        /*
        // &.btn-outline {  } からコピペ
        background-color: transparent;
        color: $color-btn;

        &:hover {
            background-color: $color-btn;
            color: #fff;

            box-shadow: 0 0 0 2px $color-btn inset;
            position: relative;
            z-index: 1000;
        }

        // &.btn-fill { } からコピペ
        background-color: $color-btn;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: $color-btn;

            box-shadow: 0 0 0 2px $color-btn inset;
            position: relative;
            z-index: 1000;
        }
        */

        /* &.btn-skeleton { } からコピペ */
        background-color: transparent;
        box-shadow: none;
        color: #333333;
    }

.pagination > *:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #333333;

            position: relative;
            z-index: 1000;
        }

.pagination > *:not(:first-child) {
        margin-left: -2px;
    }

.pagination > *.current,
    .pagination > *.current:hover {
        box-shadow: 0 0 0 2px #333333 inset;
        background-color: #333333;
        position: relative;
        z-index: 1000;
    }

.pagination > span,
        .pagination > span:hover,
        .pagination > *.disabled,
        .pagination > *.disabled:hover,
        .pagination > *:disabled,
        .pagination > *:disabled:hover,
        .pagination > *[readonly],
        .pagination > *[readonly]:hover {
            background-color: #eee;
            box-shadow: 0 0 0 2px #eee inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;
        }

.pagination > span::after, .pagination > span:hover::after, .pagination > *.disabled::after, .pagination > *.disabled:hover::after, .pagination > *:disabled::after, .pagination > *:disabled:hover::after, .pagination > *[readonly]::after, .pagination > *[readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }

.pagination > *:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

.pagination > *:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

html .fc .fc-view-container .fc-view .fc-widget-header {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-day-header {
                        background: none;
                        border-color: transparent;
                        font-weight: bold;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sat {
                        color: #3c83af;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sun {
                        color: #a23737;
                    }

html .fc .fc-day-grid-event {
            margin: 0;
        }

html .fc .fc-day-top {
            text-align: center;
        }

html .fc .fc-day-top .fc-day-number {
                float: none;
            }

html .fc .fc-day-top.fc-past {
            opacity: 0.25;
        }

/* 文字 */

html .fc .fc-content-skeleton {
            padding: 0;
        }

html .fc .fc-content-skeleton table,
            html .fc .fc-content-skeleton thead,
            html .fc .fc-content-skeleton tbody,
            html .fc .fc-content-skeleton tfoot,
            html .fc .fc-content-skeleton tr,
            html .fc .fc-content-skeleton th,
            html .fc .fc-content-skeleton td {
                border: none;
            }

html .fc .fc-content-skeleton .fc-event-container a.fc-event,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event-dot {
                    background-color: transparent;
                    border: none;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event {
                    text-align: center;
                    font-size: 16px;
                }

/* 青 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible:hover {
                    color: #3c83af;
                }

/* 赤 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.few:hover {
                    color: #a23737;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few .fc-title::before {
                        content: "残";
                        margin-right: 0.5em;
                        color: #333333;
                        font-size: 0.8em;
                        display: inline-block;
                    }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.past {
                    color: transparent !important;
                }

/* グレー */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible:hover,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed:hover {
                    color: #999;
                }

html .fc .fc-bgevent-skeleton td.fc-bgevent {
                background: none;
            }

/* 背景色 */

/* 青 */

html .fc .fc-bg td.bg-possible {
                background: #e7f1f7;
            }

/*td.bg-possible:hover,*/

html .fc .fc-bg td.bg-possible.active {
                background: #cde5f3;
            }

/* 赤 */

html .fc .fc-bg td.bg-few {
                background: #f8e6e6;
            }

/*td.bg-impossible:hover,
            td.bg-few:hover,*/

html .fc .fc-bg td.bg-impossible.active,
            html .fc .fc-bg td.bg-few.active {
                background: #fdd2d2;
            }

/* 黄 */

html .fc .fc-bg td.fc-today {
                background: #fffacc;
            }

/*td.fc-today:hover,*/

html .fc .fc-bg td.fc-today.active {
                background: #fff8b9;
            }

/* グレー */

html .fc .fc-bg td.fc-past,
            html .fc .fc-bg td.bg-impossible,
            html .fc .fc-bg td.bg-closed {
                background: #f2f2f2 !important;
            }

html .fc .fc-bg td.fc-disabled-day {
                background: #ddd;
                opacity: 1.0;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left,
            html .fc .fc-toolbar.fc-header-toolbar .fc-right,
            html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                margin-bottom: 1em;
                display: block;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    float: none;
                }

html .fc .fc-toolbar.fc-header-toolbar .fc-center > *:not(:last-child) {
                    margin-right: 1em;
                }

@media only screen and (min-width: 480px) {

html .fc .fc-toolbar.fc-header-toolbar {
                display: flex;
                align-items: center
        }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                    margin-bottom: 0;
                }

                    html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                        margin: 0;
                        text-align: initial;
                    }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left {
                    margin-right: auto;
                }
            }

@media only screen and (min-width: 660px) {

            }

@media only screen and (min-width: 770px) {

            }

@media only screen and (min-width: 1000px) {

            }

html .fc .fc-toolbar h2 {
            font-size: 21px;
            font-size: 1.5rem;
        }

html .fc .fc-state-default,
        html .fc .fc-state-default.fc-corner-left,
        html .fc .fc-state-default.fc-corner-right {
            outline: none;

            border: none;
            font-size: 0.85em;
            text-align: center;
            line-height: 1;
            box-shadow: 0 0 0 2px #333333 inset;
            display: inline-block;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            /*  */
            padding: 0.75em 1.5em;
            border-radius: calc(1em + 1em + 1em);

            /*  */
            background-color: #333333;
            color: #fff;

            /* 不要記述削除 */
            float: none;
            height: auto;
            text-shadow: none;
            background-image: none;

            transition: opacity 0.15s ease-in-out 0s,
                        background 0.15s ease-in-out 0s,
                        background-color 0.15s ease-in-out 0s,
                        border-radius 0.15s ease-in-out 0s,
                        border-color 0.15s ease-in-out 0s,
                        color 0.15s ease-in-out 0s,
                        box-shadow 0.15s ease-in-out 0s,
                        text-shadow 0.15s ease-in-out 0s,
                        outline 0.15s ease-in-out 0s;
        }

html .fc .fc-state-default:hover, html .fc .fc-state-default.fc-corner-left:hover, html .fc .fc-state-default.fc-corner-right:hover {
                background-color: transparent;
                color: #333333;
            }

html .fc .fc-state-disabled,
        html .fc .fc-state-disabled:hover,
        html .fc .fc-state-disabled.fc-state-hover,
        html .fc .fc-state-disabled.fc-state-hover:hover {
            background-color: #333333;
            color: #fff;
        }

html .fc .fc-state-hover {
            background-position: initial;
        }

html .fc .fc-scroller {
            overflow-x: initial !important;
            overflow-y: initial !important;
            height: initial !important;
        }

html .fc.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            float: none;
        }

html .fc.fc .fc-row .fc-content-skeleton table,
        html .fc.fc .fc-row .fc-content-skeleton td,
        html .fc.fc .fc-row .fc-helper-skeleton td {
            text-align: center;
            vertical-align: middle;
        }



.wrap-content {
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px;
	box-sizing: border-box;
}



@media only screen and (min-width: 1200) {



.wrap-content {
    padding: 0
}
  }

/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.blog-list {

    display: flex;
    flex-wrap: wrap;


}
.blog-list .card {
        padding: 20px;
        background: #ffffff;
        color: #333333;
        width: 100%;
        margin: 40px 0 0 0;
        box-sizing: border-box;
    }
.blog-list .card > * {
            display: block;
        }
.blog-list .card > *:not(:last-child) {
            margin-bottom: 15px;
        }
.blog-list .card .thumbnail:first-child {
            margin-top: -20px;
            margin-left: -20px;
            margin-right: -20px;
        }
.blog-list .card .thumbnail:last-child {
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: -20px;
        }
.blog-list .card .content {
            position: relative;
        }
.blog-list .card .content::after {
                content: '詳しく→';
                display: block;
                font-size: 14px;
                color: #082F6F;
                margin-top: 10px;
                text-align: right;
            }
.blog-list .card .content > * {
                display: block;
            }
.blog-list .card .category {
            position: absolute;
            top: -33px;
            background-color: #082F6F;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            padding: 10px 10px;
            border-radius: 2px;
        }
.blog-list .card .data {
            text-align: right;
            font-size: 14px;
            color: #C6C6C6;
            font-family: 'Roboto', sans-serif;
        }
.blog-list .card .title {
            font-size: 18px;
            font-weight: bold;
            line-height: calc(1em + 10px);
            margin-top: 10px;
        }
.blog-list .card .text {
            font-size: 14px;
            line-height: calc(1em + 10px);
            margin-top: 10px;
        }
@media only screen and (min-width: 660px) {
.blog-list {

        margin: 0 0 0 -40px


}

        .blog-list .card {
            width: calc((100% - 80px) / 2);
            margin: 40px 0 0 40px;
        }

            .blog-list .card .category {
                top: -39px;
                font-size: 15px;
                padding: 15px 10px;
            }

            .blog-list .card .title {
                font-size: 20px;
            }

            .blog-list .card .text {
                font-size: 16px;
            }
    }
@media only screen and (min-width: 770px) {

        .blog-list .card {
            width: calc((100% - 120px) / 3);
        }
    }
@media only screen and (min-width: 1200px) {
.blog-list {
        margin: 0 0 0 -60px


}

        .blog-list .card {
            width: calc((100% - 180px) / 3);
            margin: 60px 0 0 60px;
        }
    }
.blog-list .is-inactive {
      -webkit-transform: translate(0,10%);
              transform: translate(0,10%);
      transition : all 1500ms;
      display: none;
      opacity: 0;
    }
.blog-list .is-inactive.is-active {
        opacity: 1;
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
      }
.blog-list .is-inactive.is-active .thumbnail img {
          opacity: 1;
        }





/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    width: 100%;
    display: none;
    align-items: center;
    box-sizing: border-box;
}
.breadcrumb ol {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
.breadcrumb ol li {
            margin: 0;
            display: block;
            white-space: nowrap;
        }
.breadcrumb ol li + li::before {
            content: ">";
            padding: 0 0.5em;
            display: inline-block;
        }
@media only screen and (min-width: 480px) {
.breadcrumb {
        display: flex
}
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }
@media only screen and (min-width: 1300px) {

    }



/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: #dcdcdc;
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    /* border-radius: 4px; */
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.progress .progress-bar {
        background: #333333;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/
.step-group {
    display: flex;
    /* justify-content: space-between; */

    position: relative;
}
.step-group .step {
        flex: 1 1 0%;
        text-align: center;
        position: relative;
        cursor: default;
    }
.step-group .step .badge {
            margin: 0 auto 1em auto;
            padding: 0;
            width: 40px;
            height: 40px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: #000000;
            color: rgba(0, 0, 0, 0.2);
            font-size: 1.0em;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            z-index: 1000;
        }
.step-group .step .content {
            color: #333333;
            font-size: 12px;
        }
.step-group .step.active .badge {
                background-color: #333333;
                box-shadow: 0 0 0 1px #333333 inset;
                color: #fff;
            }
.step-group .step.active .content {
                color: #333333;
            }
.step-group .step:hover {
            opacity: 1.0;
        }
.step-group .step:not(:first-child)::before,
        .step-group .step:not(:first-child)::after {
            content: "";
            height: 2px;
            display: block;
            position: absolute;
            top: calc(40px / 2 - 2px / 2);
            left: -50%;
            z-index: 0;
        }
.step-group .step:not(:first-child)::before {
            width: 100%;
            background: #000000;
            background: rgba(0, 0, 0, 0.2);
        }
.step-group .step:not(:first-child)::after {
            width: 0%;
            background: #333333;
            transition: width 0.2s ease-in-out 0.0s;
        }
/*
        &::before {
            background: $color-base;
        }
        */
.step-group .step.active:not(:first-child)::after {
            width: 100%;
        }

/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;
}

section.box {
        display: block;
    }

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: #333333;
    color: #fff;
}

.box:not(.box-white) table,
        .box:not(.box-white) table thead,
        .box:not(.box-white) table tbody,
        .box:not(.box-white) table tfoot,
        .box:not(.box-white) table tr,
        .box:not(.box-white) table th,
        .box:not(.box-white) table td {
            border-color: #fff;
        }
.box.box-white {
    background-color: #fff;
    color: #333333;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;

    }
    .box-md {
        /* padding: 60px; */
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        /* padding: 100px; */
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

/*
.box-title {
    height: auto;
}
*/

.box-text {
    min-height: 200px;
    height: auto;
}

.box-text .title {
        margin-bottom: 15px;
        line-height: 1;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

.box-text {
        max-width: 400px
}
    }

@media only screen and (min-width: 770px) {
        .box-text .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 1200px) {

    }

@media only screen and (min-width: 1400px) {

    }

@media only screen and (min-width: 1600px) {

    }


.box .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px #333333 inset;
    box-sizing: border-box;
}
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.circle {
        width: 160px;
        height: 160px
}
    }
@media only screen and (min-width: 1000px) {

    }
/* aタグの装飾をキャンセル */
html body .circle {
        border: none !important;
    }
/* ボタンのサイズ */
.circle.circle-lg {

    }
.circle.circle-sm {
        width: 70px;
        height: 70px;
    }
.circle,
    .circle.circle-fill {
        background-color: #333333;
        color: #fff;
    }
a.circle:hover, a.circle.circle-fill:hover {
            background-color: transparent;
            color: #333333;
        }
.circle.circle-outline {
        background-color: transparent;
        color: #333333;
    }
a.circle.circle-outline:hover {
            background-color: #333333;
            color: #fff;
        }
.circle.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #333333;
    }
a.circle.circle-skeleton:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #333333;
        }
.circle > span {
        display: block;
        text-align: center;
        line-height: 1.2;
    }
.circle > span span {
            display: inline-block;
        }
@media only screen and (min-width: 480px) {

        }
@media only screen and (min-width: 660px) {

        }
@media only screen and (min-width: 770px) {
.circle > span {
            line-height: 1.5
    }
        }
@media only screen and (min-width: 1000px) {

        }

.l-content-banner .list {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }

@media only screen and (min-width: 660px) {

.l-content-banner .list {
      display: flex;
      flex-wrap:wrap;
      list-style: none
  }
    }

.l-content-banner .list.business .list-business {
        display: none;
      }

.l-content-banner .list.principle .list-principle {
        display: none;
      }

.l-content-banner .list.corporate-profile .list-corporate-profile {
        display: none;
      }

.l-content-banner .list.news .list-news {
        display: none;
      }

.l-content-banner .list.inquiry .list-inquiry {
        display: none;
      }

.l-content-banner .list li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

@media only screen and (min-width: 660px) {

.l-content-banner .list li {
        width: 50%;
        max-width: 840px
    }
      }

.l-content-banner .link {
    display: block;
    color: #fff;
    overflow: hidden;
    position: relative;
  }

@media only screen and (min-width: 1200px) {
        .l-content-banner .link:hover .banner {
          -webkit-transform: scale(1.06);
                  transform: scale(1.06);
          -webkit-backface-visibility:hidden;
          backface-visibility:hidden;
        }
      }

.l-content-banner .l-text {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
  }

@media only screen and (min-width: 770px) {

.l-content-banner .l-text {
      top: 45px;
      left: 40px
  }
    }

.l-content-banner .subtitle  {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: bold;
    display: block;

  }

@media only screen and (min-width: 770px) {

.l-content-banner .subtitle  {
      font-size: 18px

  }
    }

.l-content-banner .title {
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    display: block;
  }

@media only screen and (min-width: 770px) {

.l-content-banner .title {
      padding-top: 10px;
      font-size: 24px
  }
    }

.l-content-banner .banner {
    background-size: cover;
    color: #fff;
    width: 100%;
    max-width: 840px;
    transition: all 0.5s ease-in-out 0s;
    padding-top: 28.572%;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }

.l-content-banner .banner.principle {
      background-image: url(../img/common/footer-content-banner-philosophy.jpg);
    }

.l-content-banner .banner.corporate-profile {
      background-image: url(../img/common/footer-content-banner-overview.jpg);
    }

.l-content-banner .banner.news {
      background-image: url(../img/common/footer-content-banner-news.jpg);
    }

.l-content-banner .banner.inquiry {
      background-image: url(../img/common/footer-content-banner-contact.jpg);
    }

.l-content-banner .banner.business {
      background-image: url(../img/common/footer-content-banner-service.jpg);
    }

.is-news-banner .l-content-banner .list.news .list-news {
          display: none;
        }

/* 背景色 */
body {
    background: #fff;
}
.bg-white {
    background-color: #fff;
}
.bg-gray {
    background-color: #f4f4f4;
}
.bg-black {
    background-color: #222;
    color: #fff;
}
.bg-black .module-group > a,
    .bg-black .border-group > a,
    .bg-black .card-group > a {
        color: #fff;
    }
.bg-black a.module,
    .bg-black a.border,
    .bg-black a.card {
        color: #fff;
    }
.bg-black .border-group > *,
    .bg-black .border {
        border-color: #fff;
    }

/* アニメーション */
/*----------------------------------------------------------------------------*/

a,
input,
select,
textarea,
button,
.btn,
.btn * {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s;
}





.scroll-effect-01-a,
.scroll-effect-03,
.parent-scroll-effect.list > * { /* アニメーション */
    transition: opacity 0.75s cubic-bezier(.4,0,.4,1) 0.5s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.5s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.5s,
                opacity 0.75s cubic-bezier(.4,0,.4,1) 0.5s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.5s,
                opacity 0.75s cubic-bezier(.4,0,.4,1) 0.5s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.5s
}
.scroll-effect-01-b { /* アニメーション */
    transition: opacity 0.75s cubic-bezier(.4,0,.4,1) 1.0s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 1.0s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 1.0s,
                opacity 0.75s cubic-bezier(.4,0,.4,1) 1.0s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 1.0s,
                opacity 0.75s cubic-bezier(.4,0,.4,1) 1.0s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 1.0s
}
.scroll-effect-02 { /* アニメーション */
    transition: -webkit-transform 1.0s ease-out 0.7s,
                -webkit-filter 1.0s ease-out 0.7s;
    transition: transform 1.0s ease-out 0.7s,
                filter 1.0s ease-out 0.7s;
    transition: transform 1.0s ease-out 0.7s,
                filter 1.0s ease-out 0.7s,
                -webkit-transform 1.0s ease-out 0.7s,
                -webkit-filter 1.0s ease-out 0.7s
}


.scroll-effect-01-a,
.scroll-effect-01-b { /* アニメーション後 */
    -webkit-transform: none;
            transform: none;
    opacity: 1.0;
}
.scroll-effect-01-a.inactive,
.scroll-effect-01-b.inactive { /* アニメーション前 */
    transition: none;
    -webkit-transform: translate(-50px, 0);
            transform: translate(-50px, 0);
    opacity: 0.0;
}

.scroll-effect-02 { /* アニメーション後 */
    -webkit-transform: none;
            transform: none;
    -webkit-filter: blur(0);
            filter: blur(0); /*ぼかし度*/
    -webkit-transform: scale(1);
            transform: scale(1);
}
.scroll-effect-02.inactive { /* アニメーション前 */
    transition: none;
    -webkit-filter: blur(10px);
            filter: blur(10px); /*ぼかし度*/
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}

.scroll-effect-03,
.parent-scroll-effect.list > * { /* アニメーション後 */
    -webkit-transform: none;
            transform: none;
    opacity: 1.0;
}
.scroll-effect-03.inactive,
.parent-scroll-effect.list > *.inactive { /* アニメーション前 */
    transition: none;
    -webkit-transform: translate(0, 50px);
            transform: translate(0, 50px);
    opacity: 0.0;
}





.scroll-effect.customize-body {
    transition-delay: 0.5s;
}

a .thumbnail,
a.thumbnail,
.thumbnail a,

a .thumbnail img,
a.thumbnail img,
.thumbnail a img,

a .thumbnail .mask,
a.thumbnail .mask,
.thumbnail a .mask {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;;
}

a:hover .thumbnail img,
a.thumbnail:hover img,
.thumbnail a:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.5;
}

a:hover .thumbnail .mask,
a.thumbnail:hover .mask,
.thumbnail a:hover .mask {

}
/*
.bx-wrapper .bx-viewport li {
    transition: all 0.1s ease 0s;
}
.bx-wrapper .bx-viewport li.active {
    transition: all 0.25s ease 0s;
}
*/
.bx-wrapper .bx-pager.bx-default-pager a {
    /* transition: all 0.15s ease 0.75s; */
}

/* 高さを画面幅に */
.scroll-effect.contain {
    box-sizing: border-box;
    position: relative;
}
body > .scroll-effect.contain:not(.parent-popup-content) {
    transition: all 0.75s ease-in-out;
}

.scroll-effect.contain .scroll-effect {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parent-scroll-effect.parallax {
    position: relative;
}
.parent-scroll-effect.parallax > *:not(.not-move) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



/* カスタマイズ */
/*----------------------------------------------------------------------------*/
.mainvisual .slider li.active img {
    transition: -webkit-transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s, -webkit-transform 12.0s ease-in 0s;
}
.mainvisual .slider li img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.mainvisual .slider li.active img {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.slider li {
    transition: opacity 0.9s ease-in 0s;
}

.back-top {
    transition: margin 0.5s ease-in-out 0s,
                background 0.5s ease-in-out 0s,
                opacity 0.5s ease-in-out 0s,
                top 0.5s ease-in-out 0.25s,
                right 0.5s ease-in-out 0.25s,
                bottom 0.5s ease-in-out 0.25s,
                left 0.5s ease-in-out 0.25s;
}
.back-top.inactive {
    transition: margin 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out 0.25s,
                opacity 0.25s ease-in-out 0.25s,
                top 0.5s ease-in-out 1.0s,
                right 0.5s ease-in-out 1.0s,
                bottom 0.5s ease-in-out 1.0s,
                left 0.5s ease-in-out 1.0s;
}


.js-inview {
  opacity: 0;
  transition: all 0.4s ease-in 0.4s;
  -webkit-transform: translate(0,15%);
          transform: translate(0,15%);
}


.js-inview.active {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }

/*
*:first-child {
    margin-top: 0;
}
*:last-child {
    margin-bottom: 0;
}
*/
html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;
}

article,
.lead {
    padding: 80px 0;
}

main .main-header {
    }

main .main-footer {
    }

section {

    padding: 40px 0 40px;
}

section.section-large {
        padding: 40px 0;
    }

@media only screen and (min-width: 660px) {

section {
        padding: 70px 0 60px
}

        section.section-large {
            padding: 70px 0;
        }
    }

@media only screen and (min-width: 770px) {

section {
        padding: 90px 0 70px
}

        section.section-large {
            padding: 90px 0;
        }
    }

@media only screen and (min-width: 1200px) {

section {
        padding: 150px 0 100px
}

        section.section-large {
            padding: 150px 0;
        }
    }

/* section 2階層目 */
article section,
section section {
}

/* section 3階層目 */
article section section,
section section section {
}

/* .container */
/*----------------------------------------------------------------------------*/
.container,
.container-md {
    padding-left: 20px;
    padding-right: 20px;
}
@media only screen and (min-width: 660px) {
}
@media only screen and (min-width: 770px) {
}
@media only screen and (min-width: 1200px) {
    .container-md {
        margin: 0 auto;
        max-width: 1100px;
    }
}
@media only screen and (min-width: 1400px) {
    .container {
        margin: 0 auto;
        max-width: 1300px;
    }
}










.layout-group > *:not(:last-child) {
    margin-bottom: 20px;
}

.layout-group > *,
.layout {
}

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {
    }

@media only screen and (min-width: 0px) and (max-width: 769px) {
    /* スマホ */

    header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        background: #000000;
        background: rgba(0, 0, 0, 0.3);
    }

            header .logo-nav .logo {
                display: block;
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 50000;
                width: 216px;
            }

                header .logo-nav .logo a {
                    display: block;
                }

        header .global-nav-btn {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            bottom: 0;
            z-index: 1000;
            width: 50px;
            height: 50px;
        }

        header .global-nav {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: scroll;
            box-sizing: border-box;
            padding-top: 80px;
            padding-bottom: 80px;
            opacity: 1;
            text-align: center;
            background-color: #010a1b;
        }

            header .global-nav .nav {
              margin-top: 50px;
            }
              @media only screen and (min-width: 660px) {

            header .global-nav .nav {
                margin-top: 0
            }
              }

                header .global-nav .nav ul {
                    padding: 0;
                }

                    header .global-nav .nav ul li {
                        list-style-type: none;
                    }

                        header .global-nav .nav ul li a {
                            color: #ffffff;
                            display: block;
                            padding: 20px 0;
                        }

                    header .global-nav .nav ul > li.contact-btn {
                        background-color: #A32E1F;
                        border-radius: 2px;
                        display: inline-block;
                        margin-top: 20px;
                    }
                        @media only screen and (min-width: 660px) {

                    header .global-nav .nav ul > li.contact-btn {
                          margin-top: 10px
                    }
                        }

                        header .global-nav .nav ul > li.contact-btn a {
                            padding: 15px 35px;
                            height: auto;
                        }

                            header .global-nav .nav ul > li.contact-btn a .icon {
                                width: 16px;
                                height: auto;
                                margin: 5px 8px 0 0;
                            }
        html:not(.global-nav-active) .global-nav {
            opacity: 0;
            pointer-events: none;
        }

        html:not(.global-nav-active) .nav-copyright-area {
            display: none;
        }

    html.global-nav-active {
        overflow: hidden;
    }

        html.global-nav-active .global-nav {
        }

        html.global-nav-active .nav-copyright-area {
            position: fixed;
            bottom: 0;
            z-index: 1000;
            width: 100%;
            background-color: #ffffff;

            border-top: 1px solid rgba(34,34,34,0.9);
        }

            html.global-nav-active .nav-copyright-area .copyright {
                display: block;
                padding: 18px;
                font-size: 14px;
                line-height: 1;
                text-align: center;
            }
}
@media only screen and (min-width: 0px) and (max-width: 660px) {
    header {
        height: 60px;
    }

            header .logo-nav .logo {
                top: 15px;
                left: 15px;
                width: 166px;
            }

        header .global-nav-btn {
            top: 6px;
            right: 6px;
        }
}
@media only screen and (min-width: 770px) {
    header {
        transition: all 0.5s ease-out 0.0s;
        width: 100%;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 50000;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 40px;
        height: 105px;
        background: #000000;
        background: rgba(0, 0, 0, 0.0);
    }

        header.inactive {
            transition: all 0.3s ease-out 0.0s;
            height: 70px;
            background: #000000;
            background: rgba(0, 0, 0, 0.3);
        }

            header .logo-nav .logo {
                display: inline-block;
            }

                header .logo-nav .logo a {
                    margin-left: auto;
                    margin-right: auto;
                    width: 216px;
                    height: auto;
                }

                    header .logo-nav .logo a img {
                        width: 216px;
                        display: block;
                    }
        header .global-nav-btn {
            display: none;
        }
        header .global-nav {
            margin-left: auto;
        }

            header .global-nav .nav {
                display: block;
            }

                header .global-nav .nav .logo-sp {
                    display: none;
                }

                header .global-nav .nav ul {
                    margin: 0;
                    padding-left: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                    header .global-nav .nav ul > li {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        line-height: 1;
                        text-align: right;
                    }

                        header .global-nav .nav ul > li a {
                            line-height: 1;
                            opacity: 1.0;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            height: 0;
                            box-sizing: border-box;
                            position: relative;
                        }
                        header .global-nav .nav ul > li a:hover {
                            opacity: 0.5;
                        }
                    header .global-nav .nav ul > li:not(:last-child) {
                        margin-right: 40px;
                    }
                            header .global-nav .nav ul > li.current a::before {
                                content: "";
                                width: 100%;
                                height: 2px;
                                background: #ffffff;
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: -15px;
                            }

                        header .global-nav .nav ul > li.contact-btn a {
                            padding: 15px 35px;
                            height: auto;
                        }

                            header .global-nav .nav ul > li.contact-btn a:hover {
                                opacity: 1;
                            }

                            header .global-nav .nav ul > li.contact-btn a .icon {
                                width: 16px;
                                height: auto;
                                margin: 5px 12px 0 0;
                            }
}
@media only screen and (min-width: 1200px) {

                    header .global-nav .nav ul > li:not(:last-child) {
                        margin-right: 50px;
                    }
}












/* アイコン */
.global-nav-btn {
    padding: 0;
    width: 32px;
    height: 32px;
    border: none;
    display: block;
    position: relative;
}
.global-nav-btn span {
        margin: auto;
        width: 30px;
        height: 2px;
        border-radius: 1px;
        background: #ffffff;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all 0.15s ease-in-out 0s;
    }
.global-nav-btn span:nth-child(1) {
        -webkit-transform: translate(0px, -10px);
                transform: translate(0px, -10px);
    }
.global-nav-btn span:nth-child(4) {
        -webkit-transform: translate(0px, 10px);
                transform: translate(0px, 10px);
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {
.global-nav-btn {
        display: none
}
    }
html.global-nav-active .global-nav-btn span:nth-child(1) {
            -webkit-transform: translate(0px, -42px);
                    transform: translate(0px, -42px);
            opacity: 0;
        }
html.global-nav-active .global-nav-btn span:nth-child(4) {
            -webkit-transform: translate(0px, 42px);
                    transform: translate(0px, 42px);
            opacity: 0;
        }
html.global-nav-active .global-nav-btn span:nth-child(2) {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
        }
html.global-nav-active .global-nav-btn span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }

footer {
    background-color: #010a1b;
    color: #ffffff;
}

footer .footer-contents-area {
        padding: 50px 0;
    }

footer .footer-contents-area .information {
            text-align: center;
        }

footer .footer-contents-area .information dl {
                margin-top: 30px;
                text-align: left;
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
            }

footer .footer-contents-area .information dl dt {
                    font-weight: bold;
                }

footer .footer-contents-area .information dl dd {
                    margin-top: 15px;
                    line-height: 1.4;
                }

footer .footer-contents-area .information dl dd span {
                        display: block;
                        margin-left: 0;
                        margin-top: 10px;
                        font-size: 12px;
                        letter-spacing: -1px;
                    }

footer .footer-contents-area .information dl dd span a {
                            display: block;
                            padding: 10px 20px;
                            height: inherit;
                            line-height: 1;
                            min-width: 0;
                            font-size: 16px;
                            max-width: 200px;
                            margin-top: 20px;
                            margin-left: auto;
                            margin-right: auto;
                        }

@media only screen and (min-width: 660px) {

footer .footer-contents-area .information dl dd span a {
                              display: inline-block;
                              max-width: none;
                              padding: 10px 10px;
                              position: relative;
                              top: -10px
                        }
                            }

footer .footer-contents-area .contact {
            margin-top: 30px;
        }

footer .footer-contents-area .contact > div {
                border: 1px solid #ffffff;
                text-align: center;
                padding: 20px 0;
                width: 100%;
            }

footer .footer-contents-area .contact > div .title {
                    font-weight: bold;
                }

footer .footer-contents-area .contact > div .title::after {
                        content: '';
                        display: block;
                        width: 26px;
                        margin: 12px auto 23px;
                        border-bottom: 1px solid #ffffff;
                    }

footer .footer-contents-area .contact .tel dt {
                    font-weight: bold;
                    font-size: 22px;
                    font-family: 'Roboto', sans-serif;
                }

footer .footer-contents-area .contact .tel dt a {
                        color: #ffffff;
                    }

footer .footer-contents-area .contact .tel dd {
                    font-size: 14px;
                    margin-top: 15px;
                }

footer .footer-contents-area .contact .mail {
                margin-top: 20px;
            }

footer .footer-contents-area .contact .mail .link {
                    width: 200px;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 14px;
                    font-weight: bold;
                }

footer .footer-contents-area .contact .mail .link a {
                        display: block;
                        padding: 10px 0;
                        width: 100%;
                        height: auto;
                    }

footer .footer-contents-area .contact .mail .link a .icon {
                            width: 16px;
                            height: auto;
                            margin: 0 8px 0 0;
                            vertical-align: middle;
                        }

footer .footer-nav-copyright-area {
        border-top: 1px solid #4c5363;
        text-align: center;
        padding: 11px 0 14px;
    }

footer .footer-nav-copyright-area .copyright {
            opacity: 0.7;
            font-size: 13px;
            font-family: 'Roboto', sans-serif;
        }
@media only screen and (min-width: 660px) {

                        footer .footer-contents-area .information dl dd span {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 35px;
                            margin-top: 0;
                        }

            footer .footer-contents-area .contact {
                display: flex;
                justify-content: center;
            }

                footer .footer-contents-area .contact > div {
                    width: 250px;
                }

                footer .footer-contents-area .contact .mail {
                    margin-left: 25px;
                    margin-top: 0;
                }
}
@media only screen and (min-width: 770px) {

        footer .footer-contents-area {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            ]padding: 70px 0;
        }

            footer .footer-contents-area .information {
                text-align: left;
            }

                        footer .footer-contents-area .information dl dd span {
                            display: block;
                            margin-left: 0;
                            margin-top: 10px;
                        }

            footer .footer-contents-area .contact {
                justify-content: flex-start;
                margin-top: 0;
            }
}
@media only screen and (min-width: 1300px) {

                        footer .footer-contents-area .information dl dd span {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 35px;
                            margin-top: 0;
                        }

                footer .footer-contents-area .contact > div {
                    width: 300px;
                }

                footer .footer-contents-area .contact .mail {
                    margin-left: 40px;
                }
}















/* footer */
/*----------------------------------------------------------------------------*/

.back-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10000;
    transition: opacity 0.5s ease-in-out 0s,
                -webkit-transform 0.5s ease-in-out 0s;
    transition: opacity 0.5s ease-in-out 0s,
                transform 0.5s ease-in-out 0s;
    transition: opacity 0.5s ease-in-out 0s,
                transform 0.5s ease-in-out 0s,
                -webkit-transform 0.5s ease-in-out 0s;

    opacity: 1.0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.back-top {
        bottom: 40px;
        right: 40px
}
    }

@media only screen and (min-width: 1000px) {

    }

.back-top .btn {
        padding: 10px;
        /* border-radius: 5px; */
        /* background: $color-link; */
        /* color: rgba(30, 62, 72, 0.75); */
        color: #5a676b;
        font-size: 10px;
        line-height: 1;
        box-shadow: none;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;

        /* border: 1px solid rgba(30, 62, 72, 0.75); */
        border: 1px solid #5a676b;
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

.back-top .btn:not(.btn-sm)::before {
            display: none;
        }

.back-top .btn:not(.btn-sm)::after {
            /* margin: 0 auto 5px auto; */
            margin: auto;
            -webkit-transform: none;
                    transform: none;
            position: static;

            font-size: 24px;
        }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {
            .back-top .btn:not(.btn-sm):hover {
                /* background: $color-link-hover; */
                color: #3db5da;
                border-color: #3db5da;
            }

                .back-top .btn:not(.btn-sm):hover::after {
                    -webkit-transform: translate(0, -10px);
                            transform: translate(0, -10px);
                }
        }

@media only screen and (min-width: 1000px) {

        }

.back-top.inactive {
    opacity: 0;
    -webkit-transform: translate(0, 200px);
            transform: translate(0, 200px);
}
.back-top.mode-footer {
    position: absolute;
}
/* margin-bottom: 10px; */
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.back-top.mode-footer {
        /* margin-bottom: 40px; */
}
    }
@media only screen and (min-width: 1000px) {

    }


.mainvisual {

    width: 100vw;
    height: 100vh;
    position: relative;
}


.mainvisual .text {
        z-index: 10000;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%); /* Safari用 */
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 28px;
        font-weight: bold;
        font-family: 'Noto Serif JP', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', '游明朝', 'Yu Mincho', YuMincho, 'ＭＳ Ｐ明朝', 'MS PMincho', 'メイリオ', Meiryo, Verdana, Droid Sans, serif;
        width: 100%;
        padding: 0 30px 0 30px;
        line-height: 1.33333;
        box-sizing: border-box;
        opacity: 0;
        transition : all 2000ms;
    }


.mainvisual .text.active {
          opacity: 1;
        }


.mainvisual .text br {
            display: none;
        }


.mainvisual .text dd {
            font-size: 16px;
            font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', '游ゴシック', YuGothic, 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
            margin-top: 20px;
            /* display: none; */
        }


@media only screen and (min-width: 660px) {
        .mainvisual .text {
            font-size: 40px;
            padding: 0 0 0 60px;
        }

            .mainvisual .text br {
                display: inline;
            }

            .mainvisual .text dd {
                font-size: 20px;
            }
    }


@media only screen and (min-width: 770px) {
        .mainvisual .text {
            font-size: 56px;
        }

            .mainvisual .text dd {
                font-size: 28px;
            }
    }


@media only screen and (min-width: 1200px) {
        .mainvisual .text {
            font-size: 60px;
            padding: 0 0 0 130px;
        }

            .mainvisual .text dd {
                font-size: 30px;
            }
    }


@media only screen and (min-width: 1400px) {
    }



.mainvisual-sub-page {
  background-position: center top;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 50vh;
  display: table;
}



@media only screen and (min-width: 660px) {



.mainvisual-sub-page {
    height: 540px
}
  }



.mainvisual-sub-page .container {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    text-align: center;
  }



.mainvisual-sub-page .subtitle {
    font-size: 20px;
    font-weight: bold;
    display: block;
    font-family: 'Roboto', sans-serif;
  }



@media only screen and (min-width: 660px) {



.mainvisual-sub-page .subtitle {
      font-size: 30px
  }
    }



.mainvisual-sub-page .title {
    font-size: 34px;
    padding-top: 10px;
    font-weight: bold;
  }



@media only screen and (min-width: 660px) {



.mainvisual-sub-page .title {
      font-size: 46px;
      padding-top: 30px
  }
    }

/* ローディング */
.load-area {
    display: none;
    /* display: block; */
    /* background: #fff; */

    /* テスト用 */
    /* background: #fff !important; */
    /* opacity: 1 !important; */
    /* display: block !important; */
    /* テスト用 */

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50000;
}

html.load-active body {
        background: #fff;
    }

html.load-active .load-area {
        width: 100%;
        height: 100%;
        background: #fff;
        display: block;
        transition: all 0.35s ease 0.0s;
    }

html.load-active .load-area .svg-load {
            margin: auto;
            width: 150px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 50px;
            left: 0;
        }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {
            html.load-active .load-area .svg-load {
                width: 200px;
            }
        }

@media only screen and (min-width: 1000px) {

        }

/* opacityだけだと、アニメーションが正常に動作しない場合がある */
/* 一瞬、サイトの中身が表示されてしまうのを防ぐため */
html.load-active:not(.load-fadeout) body {
        background: #fff;
    }
html.load-active:not(.load-fadeout) body > *:not(.load-area) {
            visibility: hidden;
        }
html.load-fadeout .load-area {
        opacity: 0;
    }
html:not(.load-active) body {
        background: #fff;
    }
/*
html:not(.load-active):not(.load-fadeout) {
    .load-area {
        // display: none;
        display: none;
        background: transparent;
    }
}
*/

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

.post-group > a:not(:last-child) {
        margin-bottom: 2em;
    }

.post-group > a {
        color: #333333;
        display: block;
    }

.post-group > a > *:not(:last-child) {
            margin-bottom: 10px;
        }

.post-group > a .thumbnail {

        }

.post-group > a .content > * {
                display: block;
            }

.post-group > a .content > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

.post-group > a .content .date-area {
                display: flex;
                align-items: baseline;
            }

.post-group > a .content .date-area > *:not(:last-child) {
                    margin-right: 0.5em;
                }

.post-group > a .content .title {

            }

.post-group > a .content .text {

            }

@media only screen and (min-width: 480px) {

.post-group > a {
            display: flex;
            align-items: flex-start
    }

            .post-group > a > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 10px;
            }

            .post-group > a .thumbnail {
                width: 150px;
                min-height: 150px;
                flex: 0 0 150px;
            }
            .post-group > a .content {
                flex: 1 1 auto;
            }
        }

@media only screen and (min-width: 660px) {
            .post-group > a > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 15px;
            }

            .post-group > a .thumbnail {
                width: 200px;
                flex: 0 0 200px;
            }
            .post-group > a .content {

            }
        }

@media only screen and (min-width: 770px) {

        }

@media only screen and (min-width: 1000px) {

        }

.post-group > a:hover {
        background: #000000;
        background: rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.05);
    }

.post-area .post-group,
    .post-area .pagination {
        margin-bottom: 40px;
    }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {

.post-area .post-group,
    .post-area .pagination {
            margin-bottom: 60px
    }
        }

@media only screen and (min-width: 1000px) {

        }

.post-area .post-group:first-child, .post-area .pagination:first-child {
            margin-top: 0;
        }

.post-area .post-group:last-child, .post-area .pagination:last-child {
            margin-bottom: 0;
        }

.post-nav .title {
        margin-bottom: 10px;
        display: block;
    }

.post-nav .nav {
        /*
        & > * {
            background-color: transparent;
            color: $color-btn;
            opacity: 1;
        }
        & > *.active,
        & > *:hover {
            background-color: $color-btn;
            color: #fff;
        }
        */
    }
.post-nav-area {
    padding: 40px 0;
}
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.post-nav-area {
        padding: 60px 0
}
    }
@media only screen and (min-width: 1000px) {

    }
.post-nav-area .post-nav:not(.last-child) {
        margin-bottom: 10px;
    }


article .article-header {
        margin-bottom: 40px;
    }


article .article-header .title {
            /* margin-bottom: 40px; */
            font-size: 24px;
            /* text-align: center; */
        }


article .article-header .data-area:not(:last-child) {
            margin-bottom: 40px;
        }


article .article-header .date {
            margin-bottom: 1em;
            display: block;
        }


/* display: flex; */


article .article-header .excerpt-area > * {
                flex: 1 1 0%;
            }


article .article-header .excerpt-area .img img {
                    width: 100%;
                }


@media only screen and (min-width: 480px) {


article .article-header {
            margin-bottom: 40px
    }

            article .article-header .title {
                /* margin-bottom: 40px; */
            }

            article .article-header .data-area:not(:last-child) {
                margin-bottom: 60px;
            }
                article .article-header .excerpt-area .img {
                    margin: 0 auto;
                    max-width: 80%;
                }
        }


@media only screen and (min-width: 660px) {
            article .article-header .data-area {
                display: flex;
                align-items: center;
            }
                /* justify-content: center; */

                article .article-header .data-area .date {
                    margin-bottom: 0;
                }
                article .article-header .data-area .category-list {
                    margin-left: 20px;
                    padding-left: 20px;
                    border-left: 1px solid #333333;
                }
        }


@media only screen and (min-width: 770px) {


article .article-header {
            margin-bottom: 80px
    }

            article .article-header .title {
                /* margin-bottom: 80px; */
                font-size: 34px;
            }

            article .article-header .data-area:not(:last-child) {
                margin-bottom: 80px;
            }
                article .article-header .excerpt-area .img {
                    max-width: 60%;
                }
        }


@media only screen and (min-width: 1000px) {

        }


@media only screen and (min-width: 1300px) {

        }


article .article-content img {
            margin: 3em auto;
            display: block;
        }


@media only screen and (min-width: 480px) {

        }


@media only screen and (min-width: 660px) {

        }


@media only screen and (min-width: 770px) {
            article .article-content img {
                margin: 5em auto;
                display: block;
            }
        }


@media only screen and (min-width: 1000px) {

        }


@media only screen and (min-width: 1300px) {

        }

.article-nav-area {
    padding-bottom: 60px;
    background: #ffffff;
}

.article-nav-area .article-nav {
        display: flex;
        justify-content: space-around;
    }

.article-nav-area .article-nav .btn:not(:last-child) {
            margin-right: 20px;
        }

@media only screen and (min-width: 480px) {

.article-nav-area {
        padding-bottom: 80px
}
    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.article-nav-area {
        padding-bottom: 100px
}
    }

@media only screen and (min-width: 1000px) {

.article-nav-area {
        padding-bottom: 140px
}
    }

/* @media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

body.contact,
body.confirm,
body.complete {
    main {
        padding: 40px 0;

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {
            padding: 80px 0;
        }
        @media only screen and (min-width: 770px) {

        }
        @media only screen and (min-width: 1000px) {
            padding: 120px 0;
        }
    }

    .form-footer {
        margin-top: 40px;

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {

        }
        @media only screen and (min-width: 1000px) {
            margin-top: 80px;
        }

        .btn-group {
            justify-content: center;
        }
    }
} */

.home-contents-link a {
        display: block;
        padding: 40px 20px 40px 20px;
        height: 340px;
        color: #ffffff;
        font-weight: bold;
        overflow:hidden;
        position: relative;
        box-sizing: border-box;
    }

.home-contents-link a:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          background-size: cover;
          background-position: center bottom;
          transition: all .5s ease-in-out 0s;
        }

@media only screen and (min-width: 770px) {
            .home-contents-link a:hover:after {
              -webkit-transform: scale(1.06);
                      transform: scale(1.06);
            }
        }

.home-contents-link a.service:after {
            background-image: url(../img/home/bg-01.jpg);
          }

.home-contents-link a.philosophy:after {
            background-image: url(../img/home/bg-02.jpg);
          }

.home-contents-link a div {
            font-size: 16px;
            line-height: 1.9;
            margin-top: 15px;
        }

.home-contents-link a div br {
                display: none;
            }

@media only screen and (min-width: 660px) {

.home-contents-link {
        display: flex



}

        .home-contents-link a {
            padding: 60px 40px 60px 40px;
            width: 50%;
            height: 390px;
        }
    }

@media only screen and (min-width: 770px) {
        .home-contents-link a {
            height: 490px;
        }

            .home-contents-link a div {
                font-size: 18px;
            }
    }

@media only screen and (min-width: 1200px) {
        .home-contents-link a {
            padding: 60px 60px 60px 90px;
            height: 590px;
        }

            .home-contents-link a div {
                font-size: 20px;
            }
    }

@media only screen and (min-width: 1400px) {
        .home-contents-link a {
            padding: 60px 0 60px 90px;
        }

                .home-contents-link a div br {
                    display: inline;
                }
    }

.home-contents-link .read-more {
      border: 2px solid #fff;
      margin-top: 15px;
      display: inline-block;
      padding: 10px 20px;
      font-size: 14px;
      position: relative;
    }

@media only screen and (min-width: 660px) {

.home-contents-link .read-more {
        margin-top: 30px;
        font-size: 16px
    }
      }



.home-news-area .news-list {
      margin-top: 50px;

    }



@media only screen and (min-width: 660px) {



.home-news-area .news-list {
        margin-top: 40px

    }
      }



.home-news-area .news-list a {
        color: #333333;
      }



@media only screen and (min-width: 770px) {
              .home-news-area .news-list a:hover .title span {
                text-decoration: underline;
              }
        }



@media only screen and (min-width: 660px) {

        .home-news-area .container-md {
            display: flex;
        }

        .home-news-area .heading {
            width: 200px;
        }

        .home-news-area .news-list {
            width: calc(100% - 200px);
            margin-top: 0;
        }
    }



@media only screen and (min-width: 770px) {
        .home-news-area .heading {
            width: 280px;
        }

        .home-news-area .news-list {
            width: calc(100% - 280px);
        }
    }



.home-bg-area {
    width: 100%;
    height: 190px;
    position: relative;
    overflow: hidden;
}



@media only screen and (min-width: 660px) {



.home-bg-area {
      height: 250px
}
    }



.home-bg-area div {
        width: calc(100% + 20px);
        height: 270px;
        position: absolute;
        background-image: url(../img/home/bg-03.jpg);
        background-size: cover;
        background-position: center top;
        top: -10px;
        left: -10px;
    }



@media only screen and (min-width: 660px) {



.home-bg-area {
        height: 350px
}

        .home-bg-area div {
            height: 370px;
        }
    }



@media only screen and (min-width: 1200px) {



.home-bg-area {
        height: 500px
}

        .home-bg-area div {
            height: 520px;
        }
    }



.home-blog-area {
    background-color: #FAFAFA;
}


/* ================================================== */
/* 個人情報保護方針 */
/* ================================================== */



.is-privacy .privacy-content {
    padding-top: 20px;
  }



@media only screen and (min-width: 660px) {



.is-privacy .privacy-content {
      padding-top: 25px;
      padding-right: 5px;
      padding-left: 5px
  }
    }



.is-privacy .privacy-content .text-lead {
      font-size: 16px;
      padding-bottom: 30px;
      line-height: 2;
    }



@media only screen and (min-width: 660px) {



.is-privacy .privacy-content .text-lead {
        font-size: 20px;
        padding-bottom: 36px
    }
      }



.is-privacy .privacy-content .privacy-group {
      padding-bottom: 10px;
    }



@media only screen and (min-width: 660px) {



.is-privacy .privacy-content .privacy-group {
        padding-bottom: 50px
    }
      }

/* ================================================== */
/* 企業理念 */
/* ================================================== */
.is-philosophy * {
    box-sizing: border-box;
  }
.is-philosophy .title-philosophy {
    font-size: 22px;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;


  }
@media only screen and (min-width: 660px) {
.is-philosophy .title-philosophy {
      font-size: 30px


  }
    }
@media only screen and (min-width: 770px) {
.is-philosophy .title-philosophy {
      font-size: 36px


  }
    }
.is-philosophy .obj-01 {
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
@media only screen and (min-width: 660px) {
.is-philosophy .obj-01 {
      margin-top: 60px;
      width: 70%
  }
    }
@media only screen and (min-width: 770px) {
.is-philosophy .obj-01 {
      width: auto
  }
    }
.is-philosophy .list-philosophy {
    list-style: none;
    margin-top: 30px;
    padding-bottom: 20px;
  }
@media only screen and (min-width: 660px) {
.is-philosophy .list-philosophy {
      display: flex;
      justify-content:space-between;
      margin-top: 60px;
      padding-bottom: 50px
  }
    }
@media only screen and (min-width: 770px) {
.is-philosophy .list-philosophy {
      padding-bottom: 60px
  }
    }
.is-philosophy .list-philosophy li {
      width: 100%;
      list-style: none;
      margin-bottom: 20px;


    }
@media only screen and (min-width: 660px) {
.is-philosophy .list-philosophy li {
        margin-bottom: 0;
        width: 32%


    }
      }
@media only screen and (min-width: 1200px) {
.is-philosophy .list-philosophy li {
        width: 396px


    }
      }
.is-philosophy .list-philosophy li:last-child {
        margin-bottom: 0;
      }
.is-philosophy .list-philosophy .m-box {
      border: 1px solid #C4C4C4;
      padding: 25px 10px 20px;
    }
@media only screen and (min-width: 660px) {
.is-philosophy .list-philosophy .m-box {
        padding: 40px 10px 25px
    }
      }
.is-philosophy .list-philosophy .m-box .heading {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
      }
@media only screen and (min-width: 660px) {
.is-philosophy .list-philosophy .m-box .heading {
          font-size: 22px
      }
        }
.is-philosophy .list-philosophy .m-box .heading.color-1 {
          color: #082F6F;
        }
.is-philosophy .list-philosophy .m-box .heading.color-2 {
          color: #0051B1;
        }
.is-philosophy .list-philosophy .m-box .heading.color-3 {
          color: #007CAE;
        }
.is-philosophy .list-philosophy .m-box .text {
        font-size: 16px;
        padding-top: 10px;
        text-align: center;
        line-height: 1.5;


      }
@media only screen and (min-width: 660px) {
.is-philosophy .list-philosophy .m-box .text {
          font-size: 18px;
          padding-top: 25px


      }
        }
@media only screen and (min-width: 770px) {
.is-philosophy .list-philosophy .m-box .text {
          padding-top: 38px


      }
        }
.is-philosophy .text-comment {
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 1.8;
  }
@media only screen and (min-width: 660px) {
.is-philosophy .text-comment {
      font-size: 18px;
      padding-bottom: 20px
  }
    }
.is-philosophy .text-comment:last-child {
      padding-bottom: 0;
    }
.is-philosophy .l-inner {
  }
.is-philosophy .l-story {
    background-color: #F2F6FC;




  }
.is-philosophy .l-story .l-inner {
      padding-top: 0;
    }
@media only screen and (min-width: 660px) {
.is-philosophy .l-story .l-inner {
        display: flex;
        justify-content:space-between
    }
      }
.is-philosophy .l-story .l-inner .l-text {
        padding-bottom: 20px;
      }
@media only screen and (min-width: 660px) {
.is-philosophy .l-story .l-inner .l-text {
          padding-bottom: 0;
          width: 60%
      }
        }
@media only screen and (min-width: 1200px) {
.is-philosophy .l-story .l-inner .l-text {
          width: 565px
      }
        }
.is-philosophy .l-story .l-inner .title-01 {
        margin-bottom: 20px;
      }
@media only screen and (min-width: 660px) {
.is-philosophy .l-story .l-inner .title-01 {
          margin-bottom: 35px
      }
        }
.is-philosophy .l-story .l-inner .text {
        font-size: 16px;
        padding-bottom: 10px;
        line-height: 1.5;
      }
@media only screen and (min-width: 660px) {
.is-philosophy .l-story .l-inner .text {
          font-size: 18px;
          padding-bottom: 20px
      }
        }
.is-philosophy .l-story .l-inner .l-img {
        background-color: #fff;
        border: 1px solid #707070;
        padding: 40px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;


      }
@media only screen and (min-width: 660px) {
.is-philosophy .l-story .l-inner .l-img {
          padding: 40px;
          width: 38%;
          margin: 0


      }
        }
@media only screen and (min-width: 1200px) {
.is-philosophy .l-story .l-inner .l-img {
          padding: 70px 10px;
          width: 622px


      }
        }
.is-philosophy .l-story .l-inner .l-img img {
          display: block;
          margin: auto;
        }

/* ================================================== */
/* 企業情報 */
/* ================================================== */
.is-overview .l-data .l-group {
      padding-top: 30px;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-data .l-group {
        padding-top: 50px;
        display: flex;
        justify-content:space-between
    }
      }
@media only screen and (min-width: 770px) {
.is-overview .l-data .l-group {
        padding-top: 70px
    }
      }
@media only screen and (min-width: 660px) {
.is-overview .l-data .l-group .l-left,
       .is-overview .l-data .l-group .l-right {
            width: 48%
       }
          }
@media only screen and (max-width: 660px) {
               .is-overview .l-data .l-group .l-right .table-01 dl dt {
                 border-top: none;
               }
               .is-overview .l-data .l-group .l-right .table-01 dl dd {
                 border-top: none;
               }
         }
.is-overview .l-data .l-map {
      width: 100%;
      margin-top: 40px;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-data .l-map {
        margin-top: 70px
    }
      }
.is-overview .l-data .l-map iframe {
        width: 100%;
        height: 350px;
      }
@media only screen and (min-width: 660px) {
.is-overview .l-data .l-map iframe {
          height: 500px
      }
        }
.is-overview .l-profile {
    background-color: #F2F6FC;
  }
.is-overview .l-profile .l-content {
      padding-top: 30px;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-content {
        padding-top: 0;
        display: flex;
        justify-content:space-between;
        flex-direction:row-reverse
    }
      }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-content .l-text {
          width: 50%


      }
        }
@media only screen and (min-width: 1200px) {
.is-overview .l-profile .l-content .l-text {
          width: 648px


      }
        }
.is-overview .l-profile .l-content .l-img {
        width: 70%;
        margin: auto;
      }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-content .l-img {
          width: 45%;
          margin: 40px 0 0
      }
        }
@media only screen and (min-width: 1200px) {
.is-overview .l-profile .l-content .l-img {
          margin: 50px auto 0;
          width: auto
      }
        }
.is-overview .l-profile .l-content .l-img .text {
          padding-top: 13px;
          font-size: 14px;
          display: block;
          text-align: center;



        }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-content .l-img .text {
            text-align: left;
            padding-top: 10px;
            font-size: 16px



        }
          }
@media only screen and (min-width: 770px) {
.is-overview .l-profile .l-content .l-img .text {
            font-size: 18px



        }
          }
.is-overview .l-profile .l-group {
      padding-top: 30px;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-group {
        padding-top: 50px
    }
      }
.is-overview .l-profile .l-information {
      font-size: 14px;
      line-height: 1.5;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-information {
        line-height: 1.8;
        font-size: 16px
    }
      }
@media only screen and (min-width: 770px) {
.is-overview .l-profile .l-information {
        font-size: 18px
    }
      }
.is-overview .l-profile .l-information .text {
        padding-top: 10px;
      }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .l-information .text {
          padding-top: 25px
      }
        }
.is-overview .l-profile .black {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 1.5;
    }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .black {
        line-height: 1.9;
        display: table;
        padding-top: 15px;
        padding-bottom: 20px;
        font-size: 16px
    }
      }
@media only screen and (min-width: 770px) {
.is-overview .l-profile .black {
        font-size: 18px;
        padding-bottom: 30px
    }
      }
.is-overview .l-profile .black dt {
        padding-bottom: 2px;

      }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .black dt {
          padding-bottom: 0;
          display: table-cell;
          vertical-align: top;
          width: 85px

      }
        }
@media only screen and (min-width: 770px) {
.is-overview .l-profile .black dt {
          width: 95px

      }
        }
@media only screen and (min-width: 660px) {
.is-overview .l-profile .black dd {
          display: table-cell;
          vertical-align: top
      }
        }
.is-overview .tel-link {
    text-decoration: underline;
  }
@media only screen and (min-width: 660px) {
.is-overview .tel-link {
      text-decoration: none
  }
    }

/* ================================================== */
/* 事業内容 */
/* ================================================== */
.is-service .text-lead {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 30px;
    line-height: 1.5;


  }
@media only screen and (min-width: 660px) {
.is-service .text-lead {
      font-size: 20px;
      padding-top: 23px;
      padding-bottom: 50px


  }
    }
@media only screen and (min-width: 770px) {
.is-service .text-lead {
      font-size: 22px;
      padding-bottom: 70px


  }
    }
.is-service .l-consulting .box-title {
      border: 1px solid #082F6F;
      margin-bottom: 30px;


    }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title {
        margin-bottom: 50px


    }
      }
.is-service .l-consulting .box-title:last-child {
        margin-bottom: 0;
      }
.is-service .l-consulting .box-title .title {
        background-color: #082F6F;
        color: #fff;
        text-align: center;
        font-size: 20px;
        padding: 15px 10px;
        font-weight: bold;
        line-height: 1.25;


      }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .title {
          font-size: 26px;
          padding: 15px 0


      }
        }
@media only screen and (min-width: 770px) {
.is-service .l-consulting .box-title .title {
          font-size: 28px;
          padding: 26px 0


      }
        }
.is-service .l-consulting .box-title .l-content {
        padding: 20px;
      }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content {
          padding: 30px 20px 30px
      }
        }
@media only screen and (min-width: 770px) {
.is-service .l-consulting .box-title .l-content {
          padding: 40px 30px 50px
      }
        }
.is-service .l-consulting .box-title .l-content .list {
          list-style: none;
        }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .list {
            display: flex
        }
          }
.is-service .l-consulting .box-title .l-content .list li {
            list-style: none;
            margin-bottom: 20px;
          }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .list li {
              width: 32%;
              margin-right: 2%
          }
            }
@media only screen and (min-width: 1200px) {
.is-service .l-consulting .box-title .l-content .list li {
              width: 396px;
              margin-right: 25px
          }
            }
.is-service .l-consulting .box-title .l-content .list li:nth-child(3) {
              margin-right: 0;
            }
.is-service .l-consulting .box-title .l-content .list li:last-child {
              margin-bottom: 0;
            }
.is-service .l-consulting .box-title .l-content .l-group {
          background-color: #F2F6FC;
          padding: 20px;
        }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .l-group {
            padding: 25px
        }
          }
.is-service .l-consulting .box-title .l-content .l-group .heading {
            color: #082F6F;
            text-align: center;
            font-size: 18px;
            line-height: 1.25;
          }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .l-group .heading {
              font-size: 20px
          }
            }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .l-group .heading br {
                display: none
            }
              }
.is-service .l-consulting .box-title .l-content .l-group .heading span {
              font-size: 12px;
            }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .l-group .heading span {
                font-size: 12px
            }
              }
.is-service .l-consulting .box-title .l-content .l-group .text {
            font-size: 14px;
            line-height: 1.5;
            padding-top: 10px;
          }
@media only screen and (min-width: 660px) {
.is-service .l-consulting .box-title .l-content .l-group .text {
              padding-top: 4px;
              font-size: 16px;
              line-height: 2
          }
            }
.is-service .l-other {
    background-color: #F2F6FC;







  }
.is-service .l-other .list {
      list-style: none;
    }
@media only screen and (min-width: 660px) {
.is-service .l-other .list {
        display: flex;
        justify-content:space-between
    }
      }
.is-service .l-other .list li {
        list-style: none;
        margin-bottom: 20px;



      }
@media only screen and (min-width: 660px) {
.is-service .l-other .list li {
          margin-bottom: 0;
          width: 49%



      }

        }
@media only screen and (min-width: 1200px) {
.is-service .l-other .list li {
          width: 622px



      }
        }
.is-service .l-other .l-group {
      padding-bottom: 40px;
    }
@media only screen and (min-width: 660px) {
.is-service .l-other .l-group {
        padding-bottom: 100px
    }
      }
.is-service .l-other .box-white {
      background-color: #fff;
      text-align: center;
      padding: 20px;
    }
@media only screen and (min-width: 660px) {
.is-service .l-other .box-white {
        padding: 40px 35px
    }
      }
.is-service .l-other .box-white .title {
        font-size: 18px;
        font-weight: bold;
        color: #082F6F;
        position: relative;
        line-height: 1.25;



      }
@media only screen and (min-width: 660px) {
.is-service .l-other .box-white .title {
          font-size: 22px



      }
        }
.is-service .l-other .box-white .title:after {
          content: '';
          display: block;
          width: 26px;
          height: 2px;
          background-color: #082F6F;
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
        }
@media only screen and (min-width: 660px) {
.is-service .l-other .box-white .title:after {
            margin-top: 22px
        }
          }
.is-service .l-other .box-white .text {
        font-size: 14px;
        line-height: 1.5;
        padding-top: 10px;
        text-align: left;
      }
@media only screen and (min-width: 660px) {
.is-service .l-other .box-white .text {
          font-size: 18px;
          line-height: 1.8;
          padding-top: 36px
      }
        }

/* ================================================== */
/* ニュース・コラム */
/* ================================================== */

.is-news {
  background-color: #FAFAFA;






}

.is-news .l-category-select {
    padding-bottom: 0;
  }

.is-news .list-category .list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      border-color: #E5E5E5;
      border-style: solid;
      border-top-width: 1px;
      border-left-width: 1px;
      box-sizing: border-box;
    }

.is-news .list-category .list li {
        list-style: none;
        width: 50%;
        border-color: #E5E5E5;
        border-bottom-width: 1px;
        border-right-width: 1px;
        border-style: solid;
        box-sizing: border-box;
      }

@media only screen and (min-width: 660px) {

.is-news .list-category .list li {
          width: 33.33%
      }
        }

.is-news .list-category .link {
      display: block;
      background-color: #fff;
      color: #082F6F;
      text-decoration: none;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 5px;
      text-align: center;
      transition: 0.5s;
    }

@media only screen and (min-width: 660px) {

.is-news .list-category .link {
        font-size: 18px;
        height: 70px;
        line-height: 70px
    }
      }

.is-news .list-category .link.current {
        background-color: #082F6F;
        color: #fff;
      }

@media only screen and (min-width: 1200px) {

.is-news .list-category .link:hover {
          background-color: #082F6F;
          color: #fff
      }
        }

.is-news .section-large {
    padding-top: 0;
  }

.is-news .wrap-btn {
    text-align: center;
    padding: 60px 0 170px;
    width: 100%;
  }

/* ================================================== */
/* ニュース・コラム　詳細 */
/* ================================================== */

.is-news.detail {
  background-color: #FAFAFA;
  padding-bottom: 150px;


}

.is-news.detail *{
    box-sizing: border-box;
  }

.is-news.detail .l-entry-content {
    background-color: #fff;
    width: 100%;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px;
    border-radius: 2px;
  }

@media only screen and (min-width: 660px) {

.is-news.detail .l-entry-content {
      padding: 60px 50px 80px
  }
    }

@media only screen and (min-width: 660px) {

.is-news.detail .l-upper-part {
      display: flex;
      justify-content:space-between;
      align-items:center
  }
    }

.is-news.detail .l-upper-part .cate {
      padding-bottom: 5px;
      display: inline-block;
    }

@media only screen and (min-width: 660px) {

.is-news.detail .l-upper-part .cate {
        padding-bottom: 0
    }
      }

.is-news.detail .l-upper-part .cate span {
        background-color: #082F6F;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        display: inline-block;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 5px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
      }

@media only screen and (min-width: 660px) {

.is-news.detail .l-upper-part .cate span {
          min-width: 100px;
          font-size: 14px;
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          margin-right: 10px
      }
        }

.is-news.detail .l-upper-part .data {
      color: #C6C6C6;
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
    }

@media only screen and (min-width: 660px) {

.is-news.detail .l-upper-part .data {
        font-size: 16px
    }
      }

.is-news.detail .title-entry {
    font-size: 20px;
    line-height: 1.5;
    padding-top: 15px;
    font-weight: bold;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 20px;
  }

@media only screen and (min-width: 660px) {

.is-news.detail .title-entry {
      font-size: 30px;
      padding-bottom: 50px
  }
    }

.is-news.detail .m-thumbnail {
    text-align: center;
    margin-top: 30px;
    width: 100%;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }

@media only screen and (min-width: 660px) {

.is-news.detail .m-thumbnail {
      margin-top: 45px
  }
    }

.is-news.detail .m-thumbnail img {
      display: block;
      margin: auto;
    }

.is-news.detail .content {
    font-size: 16px;
    margin-top: 60px;
    line-height: 1.8;
  }

@media only screen and (min-width: 660px) {

.is-news.detail .content {
      font-size: 18px;
      margin-top: 60px
  }
    }

.is-news.detail .content p {
      padding: 0.75em 0;
      font-size: 16px;
      line-height: 1.8;
    }

@media only screen and (min-width: 660px) {

.is-news.detail .content p {
        font-size: 18px
    }
      }

.is-news.detail .content h2 {
      font-size: 20px;
      font-weight: bold;
      color: #082F6F;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      padding-top: 1.0em;
      padding-bottom: 0.25em;
    }

.is-news.detail .content h2:first-of-type {
          padding-top: 0;
      }

@media only screen and (min-width: 660px) {

.is-news.detail .content h2 {
        font-size: 24px;
        padding-top: 1.5em;
        padding-bottom: 0.5em
    }
      }

.is-news.detail .content h3 {
      font-size: 18px;
      font-weight: bold;
      color: #082F6F;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      padding-bottom: 0.25em;
    }

@media only screen and (min-width: 660px) {

.is-news.detail .content h3 {
        font-size: 20px;
        padding-bottom: 0.5em
    }
      }

.is-news.detail .content b,.is-news.detail .content strong {
      font-weight: bold;
    }

.is-news.detail .content a {
      color: #333333;
      text-decoration: underline;
    }

.is-news.detail .content a:hover {
        text-decoration: none;
      }

.is-news.detail .content img {
      max-width: 100%;
      height: auto;
      padding-top: 1em;
      padding-bottom: 1em;
    }

.is-news.detail .content ul, .is-news.detail .content ol {
        margin-left: 1em;
    }

.is-news.detail .content ul li, .is-news.detail .content ol li {
            font-weight: bold;
        }

.is-news.detail .content ul li *, .is-news.detail .content ol li * {
                font-weight: normal;
                margin-top: 0;
            }

.is-news.detail .content .aligncenter {
        text-align: center;
    }

.is-news.detail .content .alignright {
        text-align: right;
    }

.is-news.detail .content .alignleft {
        text-align: left;
    }

.is-news.detail .postnavi {
    border-color: #E5E5E5;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;



  }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi {
      margin-top: 80px;
      display: flex;
      justify-content:center;
      align-items:center



  }

    }

.is-news.detail .postnavi .navi {
      display: block;
      padding: 10px 25px;
      border-color: #E5E5E5;
      border-style: solid;

    }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi .navi {
        width:50%;
        border-left-width: 1px;
        padding: 25px

    }
      }

.is-news.detail .postnavi .navi:first-child {
        border-left: none;
      }

.is-news.detail .postnavi .navi.backnavi a:before {
            left: -10px;
            -webkit-transform:rotate(-230deg);
                    transform:rotate(-230deg);
          }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi .navi.backnavi a:before {
              left: -20px
          }
            }

.is-news.detail .postnavi .navi.nextnavi {
        text-align: right;
      }

.is-news.detail .postnavi .navi.nextnavi a:before {
            right: -10px;
            -webkit-transform:rotate(-45deg);
                    transform:rotate(-45deg);

          }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi .navi.nextnavi a:before {
              right: -20px

          }
            }

.is-news.detail .postnavi a {
      color: #333333;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      line-height: 1.25;
      position: relative;
      transition: 0.2s;
    }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi a {
        line-height: 1.8;
        font-size: 16px
    }
      }

.is-news.detail .postnavi a:before {
        content: '';
        display: block;
        border-right:2px solid #333333;
        border-bottom:2px solid #333333;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: 0.2s;
      }

@media only screen and (min-width: 660px) {

.is-news.detail .postnavi a:before {
          width: 12px;
          height: 12px
      }
        }

/* ================================================== */
/* お問い合わせ */
/* ================================================== */

.is-content {
  background-color: #FAFAFA;

}

.is-content .mainvisual-sub-page {
    background-image: url(../img/contact/mainvisual.jpg);
  }

.is-content .box-white {
    background-color: #fff;
    padding: 30px 20px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

@media only screen and (min-width: 660px) {

.is-content .box-white {
      padding: 60px
  }
    }

.is-content .l-form-section {
    padding-top: 0;
  }

@media only screen and (max-width: 660px) {

.is-content .l-form .table-01 {
        display: block
    }
      }

.is-content .l-form .table-01 dl {
        position: relative;
      }

@media only screen and (max-width: 660px) {

.is-content .l-form .table-01 dl {
          padding: 10px 0 20px
      }
        }

@media only screen and (max-width: 660px) {

.is-content .l-form .table-01 dl:before {
            content: '';
            display: block;
            width: 32%;
            height: 1px;
            background-color: #1E50A2;
            position: absolute;
            top: 0;
            left: 0
        }
          }

@media only screen and (max-width: 660px) {

.is-content .l-form .table-01 dt {
          display: block;
          border: none;
          width: 100%;
          padding: 0 0 5px
      }
        }

@media only screen and (max-width: 660px) {

.is-content .l-form .table-01 dd {
          display: block;
          border: none;
          width: 100%;
          padding: 0
      }
        }

.is-content .text {
    font-size: 14px;
    line-height: 1.8;
    padding-top: 20px;
    padding-bottom: 50px;
  }

@media only screen and (min-width: 660px) {

.is-content .text {
      font-size: 18px;
      padding-top: 35px;
      padding-bottom: 60px
  }
    }

.is-content .text.home-link {
      padding: 0;
      margin-top: -25px;
    }

@media only screen and (min-width: 660px) {

.is-content .text.home-link {
        margin-top: -30px
    }
      }

.is-content .text.home-link a {
        color: #1E50A2;
      }

@media only screen and (min-width: 1200px) {

.is-content .text.home-link a:hover {
            text-decoration: underline
        }
          }

.is-content .box-blue {
    background-color: #F2F6FC;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    color: #082F6F;
    text-align: center;
    padding: 15px 15px 20px;
    margin-top: 30px;


  }

@media only screen and (min-width: 660px) {

.is-content .box-blue {
      padding: 45px 0 40px;
      margin-top: 50px


  }
    }

.is-content .box-blue a {
      color: #082F6F;
      text-decoration: none;
    }

.is-content .box-blue .tel-link {
      text-decoration: underline;
    }

@media only screen and (min-width: 660px) {

.is-content .box-blue .tel-link {
        text-decoration: none
    }
      }

.is-content .box-blue .number {
      font-size: 28px;
      display: inline-block;
    }

@media only screen and (min-width: 660px) {

.is-content .box-blue .number {
        font-size: 50px
    }
      }

.is-content .box-blue .number span {
        font-family: 'Roboto', sans-serif;
      }

.is-content .box-blue .number .icon {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: baseline;
        border: 2px solid #082F6F;
        border-radius: 50%;
        font-size: 14px;
        width: 22px;
        height: 22px;
        line-height: 18px;
        margin-right: 5px;
        position: relative;
        top: -5px;
      }

@media only screen and (min-width: 660px) {

.is-content .box-blue .number .icon {
          border: 3px solid #082F6F;
          font-size: 28px;
          width: 38px;
          height: 38px;
          line-height: 38px;
          margin-right: 10px
      }
        }

.is-content .box-blue .note {
      display: block;
      font-size: 10px;
      padding-top: 3px;
    }

@media only screen and (min-width: 660px) {

.is-content .box-blue .note {
        font-size: 16px;
        padding-top: 8px
    }
      }

.is-content .wrap-checkbox {
    text-align: center;
    padding: 20px 0 0;
    font-size: 12px;

  }

@media only screen and (min-width: 660px) {

.is-content .wrap-checkbox {
      padding: 70px 0 0;
      font-size: 16px

  }
    }

.is-content .wrap-checkbox a {
      color: #1E50A2;
      display: inline-block;
    }

@media only screen and (min-width: 1200px) {

.is-content .wrap-checkbox a:hover {
          text-decoration: underline
      }
        }

.is-content .wrap-checkbox .error {
      display: block;
      margin-top: 15px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
      padding: 10px 0;
    }

@media only screen and (min-width: 660px) {

.is-content .wrap-checkbox .error {
        padding: 15px 0;
        margin-top: 20px
    }
      }

.is-content .wrap-btn {
    text-align: center;
    padding-top: 20px;



  }

@media only screen and (min-width: 660px) {

.is-content .wrap-btn {
      padding-top: 45px



  }
    }

.is-content .wrap-btn .btn {
      margin: 0 0 20px;
    }

@media only screen and (min-width: 660px) {

.is-content .wrap-btn .btn {
        margin: 10px 10px
    }
      }

.is-content .title-01.confirm {
      display: none;
    }

.is-content .mw_wp_form_confirm .title-01.confirm {
        display: block;
      }

.is-content .mw_wp_form_confirm .title-01.input {
        display: none;
      }

.is-content .mw_wp_form_confirm .l-contact-tel {
      display: none;
    }

.is-content .mw_wp_form_confirm .l-form-section {
      padding: 90px 0 70px;
    }

@media only screen and (min-width: 660px) {

.is-content .mw_wp_form_confirm .l-form-section {
        padding: 150px 0 100px
    }
      }

.is-content .mw_wp_form_confirm .text {
      display: none;
    }

.is-content .mw_wp_form_confirm .l-form {
      padding-top: 50px;
    }

@media only screen and (min-width: 660px) {

.is-content .mw_wp_form_confirm .l-form {
        padding-top: 60px
    }
      }

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}
