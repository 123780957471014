.l-content-banner {
  .list {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 660px) {
      display: flex;
      flex-wrap:wrap;
      list-style: none;
    }
    &.business {
      .list-business {
        display: none;
      }
    }
    &.principle {
      .list-principle {
        display: none;
      }
    }
    &.corporate-profile {
      .list-corporate-profile {
        display: none;
      }
    }

    &.news {
      .list-news {
        display: none;
      }
    }

    &.inquiry {
      .list-inquiry {
        display: none;
      }
    }


    li {
      list-style: none;
      margin: 0;
      padding: 0;
      @media only screen and (min-width: 660px) {
        width: 50%;
        max-width: 840px;
      }
    }
  }
  .link {
    display: block;
    color: #fff;
    overflow: hidden;
    position: relative;

    &:hover {
      @media only screen and (min-width: 1200px) {
        .banner {
          transform: scale(1.06);
          -webkit-backface-visibility:hidden;
          backface-visibility:hidden;
        }
      }
    }
  }

  .l-text {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
    @media only screen and (min-width: 770px) {
      top: 45px;
      left: 40px;
    }
  }

  .subtitle  {
    font-family: $font-roboto;
    font-size: 12px;
    font-weight: bold;
    display: block;
    @media only screen and (min-width: 770px) {
      font-size: 18px;
    }

  }

  .title {
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    display: block;
    @media only screen and (min-width: 770px) {
      padding-top: 10px;
      font-size: 24px;
    }
  }



  .banner {
    background-size: cover;
    color: #fff;
    width: 100%;
    max-width: 840px;
    transition: all 0.5s ease-in-out 0s;
    padding-top: 28.572%;
    transform: scale(1.01);


    &.principle {
      background-image: url(../img/common/footer-content-banner-philosophy.jpg);
    }
    &.corporate-profile {
      background-image: url(../img/common/footer-content-banner-overview.jpg);
    }
    &.news {
      background-image: url(../img/common/footer-content-banner-news.jpg);
    }
    &.inquiry {
      background-image: url(../img/common/footer-content-banner-contact.jpg);
    }
    &.business {
      background-image: url(../img/common/footer-content-banner-service.jpg);
    }
  }
}

.is-news-banner {
  .l-content-banner {
    .list {
      &.news {
        .list-news {
          display: none;
        }
      }
    }

  }

}
