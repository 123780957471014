// ==================================================
// 事業内容
// ==================================================
.is-service {
  .text-lead {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 30px;
    line-height: 1.5;
    @media only screen and (min-width: 660px) {
      font-size: 20px;
      padding-top: 23px;
      padding-bottom: 50px;
    }
    @media only screen and (min-width: 770px) {
      font-size: 22px;
      padding-bottom: 70px;
    }


  }

  .l-consulting {


    .box-title {
      border: 1px solid #082F6F;
      margin-bottom: 30px;
      @media only screen and (min-width: 660px) {
        margin-bottom: 50px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        background-color: #082F6F;
        color: #fff;
        text-align: center;
        font-size: 20px;
        padding: 15px 10px;
        font-weight: bold;
        line-height: 1.25;
        @media only screen and (min-width: 660px) {
          font-size: 26px;
          padding: 15px 0;
        }
        @media only screen and (min-width: 770px) {
          font-size: 28px;
          padding: 26px 0;
        }


      }
      .l-content {
        padding: 20px;
        @media only screen and (min-width: 660px) {
          padding: 30px 20px 30px;
        }
        @media only screen and (min-width: 770px) {
          padding: 40px 30px 50px;
        }
        .list {
          list-style: none;
          @media only screen and (min-width: 660px) {
            display: flex;
          }
          li {
            list-style: none;
            margin-bottom: 20px;
            @media only screen and (min-width: 660px) {
              width: 32%;
              margin-right: 2%;
            }
            @media only screen and (min-width: 1200px) {
              width: 396px;
              margin-right: 25px;
            }
            &:nth-child(3) {
              margin-right: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .l-group {
          background-color: #F2F6FC;
          padding: 20px;
          @media only screen and (min-width: 660px) {
            padding: 25px;
          }
          .heading {
            color: $color-bg-blue;
            text-align: center;
            font-size: 18px;
            line-height: 1.25;
            @media only screen and (min-width: 660px) {
              font-size: 20px;
            }
            br {
              @media only screen and (min-width: 660px) {
                display: none;
              }
            }
            span {
              font-size: 12px;
              @media only screen and (min-width: 660px) {
                font-size: 12px;
              }
            }
          }
          .text {
            font-size: 14px;
            line-height: 1.5;
            padding-top: 10px;
            @media only screen and (min-width: 660px) {
              padding-top: 4px;
              font-size: 16px;
              line-height: 2;
            }
          }
        }
      }


    }
  }


  .l-other {
    background-color: #F2F6FC;


    .list {
      list-style: none;
      @media only screen and (min-width: 660px) {
        display: flex;
        justify-content:space-between;
      }
      li {
        list-style: none;
        margin-bottom: 20px;
        @media only screen and (min-width: 660px) {
          margin-bottom: 0;
          width: 49%;

        }
        @media only screen and (min-width: 1200px) {
          width: 622px;
        }



      }
    }

    .l-group {
      padding-bottom: 40px;
      @media only screen and (min-width: 660px) {
        padding-bottom: 100px;
      }
    }



    .box-white {
      background-color: #fff;
      text-align: center;
      padding: 20px;
      @media only screen and (min-width: 660px) {
        padding: 40px 35px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #082F6F;
        position: relative;
        line-height: 1.25;
        @media only screen and (min-width: 660px) {
          font-size: 22px;
        }
        &:after {
          content: '';
          display: block;
          width: 26px;
          height: 2px;
          background-color: #082F6F;
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
          @media only screen and (min-width: 660px) {
            margin-top: 22px;
          }
        }



      }
      .text {
        font-size: 14px;
        line-height: 1.5;
        padding-top: 10px;
        text-align: left;
        @media only screen and (min-width: 660px) {
          font-size: 18px;
          line-height: 1.8;
          padding-top: 36px;
        }
      }
    }







  }


}
