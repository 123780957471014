/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group {
    display: flex;
    align-items: center;
    margin-top: 40px;

    & > *:not(:last-child) {
    }

    @media only screen and (min-width: 660px) {
        margin-top: 80px;
    }
}

input.btn,
button.btn {
    outline: none;
}

.btn {
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    background: $color-bg-blue;
    color: $color-white;
    border: none;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    font-family: $font-roboto;
    border-radius: 2px;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  	text-transform: uppercase;
  	transition: all .3s;
  	overflow: hidden;
  	z-index: 1;
    background-color: $color-bg-blue;
    @media only screen and (min-width: 660px) {
      width: auto;
      min-width: 282px;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
    }


    /* &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-bg-blue;
        border-radius: 2px;
        z-index: -2;
    }
 */

/*
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $color-bg-blue-dark;
        border-radius: 2px;
        transition: all .3s;
        z-index: -1;
    } */


    &:hover {
      @media only screen and (min-width: 770px) {
        opacity: 0.8;
      }

      /*
        &:before {
            width: 100%;
        }
         */
    }

    &.btn-gray {
      background-color: #C4C4C4;
      @media only screen and (min-width: 660px) {
        width: 146px;
        min-width: auto;
      }

    }



    &.btn-small {
        min-width: 150px;
        height: 45px;
        line-height: 45px;

        @media only screen and (min-width: 660px) {
            margin-left: inherit;
        }
    }

    &.disabled,
    &:disabled,
    &[readonly] {
        &,
        &:hover {
            background-color: #777;
            box-shadow: 0 0 0 2px #777 inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;

            &::after {
                transform: none;
            }
        }
    }
}

.btn-red {
    text-transform: uppercase;
    transition: all .3s;
    overflow: hidden;
    z-index: 1;
    position: relative;
    color: $color-white;
    background-color: $color-red;

    /*
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-red;
        border-radius: 2px;
        z-index: -2;
    }
     */
     /*
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $color-red-dark;
        border-radius: 2px;
        transition: all .3s;
        z-index: -1;
    }
     */
    &:hover {
      @media only screen and (min-width: 770px) {
        opacity: 0.8 !important;
      }

        /* &:before {
            width: 100%;
        } */
    }
}

.btn-white {
    box-shadow: 0 0 0 2px #fff inset;

    /* ボタンの文字色、背景色、枠線の色(box-shadow) */
    &,
    &.btn-fill {
        background-color: #fff;
        color: $color-btn;

        &:hover {
            background-color: transparent;
            color: #fff;
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $color-btn;
        }
    }
    &.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #fff;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    /*
    box-shadow: 0 0 0 2px #777 inset;
    border-radius: 4px;
    */

    & > * {
        padding: 0.75em;
        min-width: calc(1em + 0.75em + 0.75em);
        min-height: calc(1em + 0.75em + 0.75em);

        // .btn { } からコピペ
        border: none;
        font-size: 1em;
        text-align: center;
        line-height: 1;
        box-shadow: 0 0 0 2px #777 inset;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        box-sizing: border-box;

        /*
        // &.btn-outline {  } からコピペ
        background-color: transparent;
        color: $color-btn;

        &:hover {
            background-color: $color-btn;
            color: #fff;

            box-shadow: 0 0 0 2px $color-btn inset;
            position: relative;
            z-index: 1000;
        }

        // &.btn-fill { } からコピペ
        background-color: $color-btn;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: $color-btn;

            box-shadow: 0 0 0 2px $color-btn inset;
            position: relative;
            z-index: 1000;
        }
        */

        // &.btn-skeleton { } からコピペ
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;

            position: relative;
            z-index: 1000;
        }
    }

    & > *:not(:first-child) {
        margin-left: -2px;
    }

    & > *.current,
    & > *.current:hover {
        box-shadow: 0 0 0 2px $color-btn inset;
        background-color: $color-btn;
        position: relative;
        z-index: 1000;
    }

    & > span,
    & > *.disabled,
    & > *:disabled,
    & > *[readonly] {
        &,
        &:hover {
            background-color: #eee;
            box-shadow: 0 0 0 2px #eee inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;

            &::after {
                transform: none;
            }
        }
    }

    & > *:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    & > *:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
