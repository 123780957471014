// ==================================================
// 企業理念
// ==================================================
.is-philosophy {

  * {
    box-sizing: border-box;
  }
  .title-philosophy {
    font-size: 22px;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    @media only screen and (min-width: 660px) {
      font-size: 30px;
    }
    @media only screen and (min-width: 770px) {
      font-size: 36px;
    }


  }



  .obj-01 {
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 660px) {
      margin-top: 60px;
      width: 70%;
    }
    @media only screen and (min-width: 770px) {
      width: auto;
    }
  }

  .list-philosophy {
    list-style: none;
    margin-top: 30px;
    padding-bottom: 20px;
    @media only screen and (min-width: 660px) {
      display: flex;
      justify-content:space-between;
      margin-top: 60px;
      padding-bottom: 50px;
    }
    @media only screen and (min-width: 770px) {
      padding-bottom: 60px;
    }

    li {
      width: 100%;
      list-style: none;
      margin-bottom: 20px;
      @media only screen and (min-width: 660px) {
        margin-bottom: 0;
        width: 32%;
      }
      @media only screen and (min-width: 1200px) {
        width: 396px;
      }
      &:last-child {
        margin-bottom: 0;
      }


    }
    .m-box {
      border: 1px solid #C4C4C4;
      padding: 25px 10px 20px;
      @media only screen and (min-width: 660px) {
        padding: 40px 10px 25px;
      }
      .heading {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        @media only screen and (min-width: 660px) {
          font-size: 22px;
        }
        &.color-1 {
          color: $color-bg-blue;
        }
        &.color-2 {
          color: #0051B1;
        }
        &.color-3 {
          color: #007CAE;
        }
      }
      .text {
        font-size: 16px;
        padding-top: 10px;
        text-align: center;
        line-height: 1.5;
        @media only screen and (min-width: 660px) {
          font-size: 18px;
          padding-top: 25px;
        }
        @media only screen and (min-width: 770px) {
          padding-top: 38px;
        }


      }
    }
  }

  .text-comment {
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 1.8;
    @media only screen and (min-width: 660px) {
      font-size: 18px;
      padding-bottom: 20px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .l-inner {
  }

  .l-story {
    background-color: #F2F6FC;
    .l-inner {
      padding-top: 0;
      @media only screen and (min-width: 660px) {
        display: flex;
        justify-content:space-between;
      }
      .l-text {
        padding-bottom: 20px;
        @media only screen and (min-width: 660px) {
          padding-bottom: 0;
          width: 60%;
        }
        @media only screen and (min-width: 1200px) {
          width: 565px;
        }
      }

      .title-01 {
        margin-bottom: 20px;
        @media only screen and (min-width: 660px) {
          margin-bottom: 35px;
        }
      }

      .text {
        font-size: 16px;
        padding-bottom: 10px;
        line-height: 1.5;
        @media only screen and (min-width: 660px) {
          font-size: 18px;
          padding-bottom: 20px;
        }
      }

      .l-img {
        background-color: #fff;
        border: 1px solid #707070;
        padding: 40px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (min-width: 660px) {
          padding: 40px;
          width: 38%;
          margin: 0;
        }
        @media only screen and (min-width: 1200px) {
          padding: 70px 10px;
          width: 622px;
        }

        img {
          display: block;
          margin: auto;
        }


      }
    }




  }





}
