img {
    max-width: 100%;
}

figure {
    figcaption {
        margin-top: 1em;
        text-align: center;
        line-height: $line-height-title;
    }

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

/* 将来的にCSS「object-fit」で実装できるようになる */
/* https://developer.mozilla.org/ja/docs/Web/CSS/object-fit */

/* 新thumbnail */
/*----------------------------------------------------------------------------*/
/*
.thumbnail {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.thumbnail > img {
    max-width: initial;
    max-height: initial;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity 0.2s ease 0.0s;
    display: block;
    position: absolute;
    margin: auto;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1;
}

.thumbnail:not(.contain).landscape > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
.thumbnail:not(.contain).portrait > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.landscape > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.portrait > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
*/


/* 旧thumbnail */
/*----------------------------------------------------------------------------*/
.thumbnail {
    display: block;
    position: relative;
    /* min-height: 180px; */
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 76.276%;
    box-sizing: border-box;
    @media only screen and (min-width: 660px) {
        height: 260px;
        padding-top: 0;
    }
}
.thumbnail > * {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.thumbnail > img {
    max-width: none;
    max-width: initial;
    max-height: none;
    max-height: initial;
    opacity: 0.0;
    transition: opacity 0.2s ease 0.2s;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1.0;
}
.thumbnail.landscape > img {
    max-width: none;
    max-width: initial;
    max-height: 100%;

    height: 100%;
    left: 50%;
    right: auto;
}
.thumbnail.portrait > img {
    max-width: 100%;
    max-height: auto;
    max-height: initial;

    width: 100%;
    top: 50%;
    bottom: auto;
}
.thumbnail.contain > img,
.thumbnail.contain.landscape > img,
.thumbnail.contain.portrait > img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* ↓.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */
.thumbnail.contain.landscape > img {
    width: 100%;
    height: auto;
}
.thumbnail.contain.portrait > img {
    width: auto;
    height: 100%;
}
/* ↑.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */
