// ==================================================
// お問い合わせ
// ==================================================

.is-content {
  background-color: $color-bg;

  .mainvisual-sub-page {
    background-image: url(../img/contact/mainvisual.jpg);
  }

  .box-white {
    background-color: #fff;
    padding: 30px 20px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 660px) {
      padding: 60px;
    }
  }

  .l-form-section {
    padding-top: 0;
  }
  .l-form {
    .table-01 {
      @media only screen and (max-width: 660px) {
        display: block;
      }


      dl {
        position: relative;
        @media only screen and (max-width: 660px) {
          padding: 10px 0 20px;
        }
        &:before {
          @media only screen and (max-width: 660px) {
            content: '';
            display: block;
            width: 32%;
            height: 1px;
            background-color: #1E50A2;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }


      dt {
        @media only screen and (max-width: 660px) {
          display: block;
          border: none;
          width: 100%;
          padding: 0 0 5px;
        }
      }
      dd {
        @media only screen and (max-width: 660px) {
          display: block;
          border: none;
          width: 100%;
          padding: 0;
        }
      }
    }
  }


  .text {
    font-size: 14px;
    line-height: 1.8;
    padding-top: 20px;
    padding-bottom: 50px;
    @media only screen and (min-width: 660px) {
      font-size: 18px;
      padding-top: 35px;
      padding-bottom: 60px;
    }
    &.home-link {
      padding: 0;
      margin-top: -25px;
      @media only screen and (min-width: 660px) {
        margin-top: -30px;
      }
      a {
        color: #1E50A2;
        &:hover {
          @media only screen and (min-width: 1200px) {
            text-decoration: underline;
          }
        }
      }
    }
  }




  .box-blue {
    background-color: #F2F6FC;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    color: #082F6F;
    text-align: center;
    padding: 15px 15px 20px;
    margin-top: 30px;
    @media only screen and (min-width: 660px) {
      padding: 45px 0 40px;
      margin-top: 50px;
    }

    a {
      color: #082F6F;
      text-decoration: none;
    }

    .tel-link {
      text-decoration: underline;
      @media only screen and (min-width: 660px) {
        text-decoration: none;
      }
    }

    .number {
      font-size: 28px;
      display: inline-block;
      @media only screen and (min-width: 660px) {
        font-size: 50px;
      }
      span {
        font-family: $font-roboto;
      }
      .icon {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: baseline;
        border: 2px solid #082F6F;
        border-radius: 50%;
        font-size: 14px;
        width: 22px;
        height: 22px;
        line-height: 18px;
        margin-right: 5px;
        position: relative;
        top: -5px;
        @media only screen and (min-width: 660px) {
          border: 3px solid #082F6F;
          font-size: 28px;
          width: 38px;
          height: 38px;
          line-height: 38px;
          margin-right: 10px;
        }
      }
    }

    .note {
      display: block;
      font-size: 10px;
      padding-top: 3px;
      @media only screen and (min-width: 660px) {
        font-size: 16px;
        padding-top: 8px;
      }
    }


  }


  .wrap-checkbox {
    text-align: center;
    padding: 20px 0 0;
    font-size: 12px;
    @media only screen and (min-width: 660px) {
      padding: 70px 0 0;
      font-size: 16px;
    }
    a {
      color: #1E50A2;
      display: inline-block;
      &:hover {
        @media only screen and (min-width: 1200px) {
          text-decoration: underline;
        }
      }
    }

    .error {
      display: block;
      margin-top: 15px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
      padding: 10px 0;
      @media only screen and (min-width: 660px) {
        padding: 15px 0;
        margin-top: 20px;
      }
    }

  }

  .wrap-btn {
    text-align: center;
    padding-top: 20px;
    @media only screen and (min-width: 660px) {
      padding-top: 45px;
    }

    .btn {
      margin: 0 0 20px;
      @media only screen and (min-width: 660px) {
        margin: 10px 10px;
      }
    }



  }

  .title-01 {
    &.confirm {
      display: none;
    }
  }


  .mw_wp_form_confirm {
    .title-01 {
      &.confirm {
        display: block;
      }
      &.input {
        display: none;
      }
    }
    .l-contact-tel {
      display: none;
    }
    .l-form-section {
      padding: 90px 0 70px;
      @media only screen and (min-width: 660px) {
        padding: 150px 0 100px;
      }
    }
    .text {
      display: none;
    }
    .l-form {
      padding-top: 50px;
      @media only screen and (min-width: 660px) {
        padding-top: 60px;
      }
    }
  }

}
