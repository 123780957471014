// ==================================================
// 企業情報
// ==================================================
.is-overview {
  .l-data {
    .l-group {
      padding-top: 30px;
      @media only screen and (min-width: 660px) {
        padding-top: 50px;
        display: flex;
        justify-content:space-between;
      }
      @media only screen and (min-width: 770px) {
        padding-top: 70px;
      }


       .l-left,
       .l-right {
          @media only screen and (min-width: 660px) {
            width: 48%;
          }
       }
       .l-right {
         @media only screen and (max-width: 660px) {
           .table-01 {
             dl {
               dt {
                 border-top: none;
               }
               dd {
                 border-top: none;
               }
             }
           }
         }
       }
    }



    .l-map {
      width: 100%;
      margin-top: 40px;
      @media only screen and (min-width: 660px) {
        margin-top: 70px;
      }
      iframe {
        width: 100%;
        height: 350px;
        @media only screen and (min-width: 660px) {
          height: 500px;
        }
      }
    }

  }

  .l-profile {
    background-color: #F2F6FC;
    .l-content {
      padding-top: 30px;
      @media only screen and (min-width: 660px) {
        padding-top: 0;
        display: flex;
        justify-content:space-between;
        flex-direction:row-reverse;
      }


      .l-text {
        @media only screen and (min-width: 660px) {
          width: 50%;
        }
        @media only screen and (min-width: 1200px) {
          width: 648px;
        }


      }
      .l-img {
        width: 70%;
        margin: auto;
        @media only screen and (min-width: 660px) {
          width: 45%;
          margin: 40px 0 0;
        }
        @media only screen and (min-width: 1200px) {
          margin: 50px auto 0;
          width: auto;
        }

        .text {
          padding-top: 13px;
          font-size: 14px;
          display: block;
          text-align: center;
          @media only screen and (min-width: 660px) {
            text-align: left;
            padding-top: 10px;
            font-size: 16px;
          }
          @media only screen and (min-width: 770px) {
            font-size: 18px;
          }



        }
      }
    }

    .l-group {
      padding-top: 30px;
      @media only screen and (min-width: 660px) {
        padding-top: 50px;
      }
    }
    .l-information {
      font-size: 14px;
      line-height: 1.5;
      @media only screen and (min-width: 660px) {
        line-height: 1.8;
        font-size: 16px;
      }
      @media only screen and (min-width: 770px) {
        font-size: 18px;
      }

      .text {
        padding-top: 10px;
        @media only screen and (min-width: 660px) {
          padding-top: 25px;
        }
      }
    }

    .black {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 1.5;
      @media only screen and (min-width: 660px) {
        line-height: 1.9;
        display: table;
        padding-top: 15px;
        padding-bottom: 20px;
        font-size: 16px;
      }
      @media only screen and (min-width: 770px) {
        font-size: 18px;
        padding-bottom: 30px;
      }
      dt {
        padding-bottom: 2px;
        @media only screen and (min-width: 660px) {
          padding-bottom: 0;
          display: table-cell;
          vertical-align: top;
          width: 85px;
        }
        @media only screen and (min-width: 770px) {
          width: 95px;
        }

      }
      dd {
        @media only screen and (min-width: 660px) {
          display: table-cell;
          vertical-align: top;
        }
      }
    }
  }

  .tel-link {
    text-decoration: underline;
    @media only screen and (min-width: 660px) {
      text-decoration: none;
    }
  }

}
