/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/



/*
body .xdsoft_datetimepicker .xdsoft_next {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(90deg);
    }
}

body .xdsoft_datetimepicker .xdsoft_prev {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(-90deg);
    }
}
*/
body .xdsoft_datetimepicker .xdsoft_today_button {
    display: none;
}

.datetimepicker-date {
    padding-right: 32px;
    background-image: url(../img/common/icon-calendar-margin.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 32px 16px;
    cursor: pointer;
}
body {
    .xdsoft_calendar {
        table {
            display: table;

            thead {
                display: table-header-group;
            }
            tbody {
                display: table-row-group;
            }
            tfoot {
                display: table-footer-group;
            }
            tr {
                display: table-row;
            }
            th,
            td {
                display: table-cell;
            }
        }
    }

    /*
    .xdsoft_datetimepicker,
    .xdsoft_noselect {
        display: block !important;
    }
    */
    .xdsoft_datetimepicker {
        padding: 10px;
    }

    .xdsoft_datetimepicker .xdsoft_datepicker {
    	width: 260px;
    	float: none;
    	/// margin-left: 8px;
        margin: 0;
    }
    .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
    	float: none;
    	// margin-right: 8px;
    	// margin-left: 0;
        margin: 0;
    }

    .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
    	width: 256px;
    }

    .xdsoft_datetimepicker {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .xdsoft_datetimepicker .xdsoft_year {
        width: 60px;
    }
    /*
    .xdsoft_datetimepicker .xdsoft_label i,
    .xdsoft_datetimepicker .xdsoft_next,
    .xdsoft_datetimepicker .xdsoft_prev,
    .xdsoft_datetimepicker .xdsoft_today_button {
        background: none;

        &::before {
            content: url(../img/common/icon-arrow-simple.svg);
            display: block;
        }
    }
    */

    // マス
    .xdsoft_datetimepicker .xdsoft_calendar table,
    .xdsoft_datetimepicker .xdsoft_calendar thead,
    .xdsoft_datetimepicker .xdsoft_calendar tbody,
    .xdsoft_datetimepicker .xdsoft_calendar tfoot {
        border-left: none;
        border-right: none;
    }

    .xdsoft_datetimepicker .xdsoft_calendar td,
    .xdsoft_datetimepicker .xdsoft_calendar th {
        color: $color-text;
        border: 1px solid $color-border;
        border-left: none;
        border-right: none;
    }

    // 見出し
    .xdsoft_datetimepicker .xdsoft_calendar th {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        background: none;
        height: auto;
    }

    .xdsoft_datetimepicker .xdsoft_calendar td {
        height: 38px;
        text-align: center;
    }
    .xdsoft_datetimepicker .xdsoft_calendar td > div {
        padding: 0;
    }

    // 今日
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
        background-color: $color-base !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 0.25;
    }
    // 選択中
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
    .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
        background-color: $color-link !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
    // ホバー
    .xdsoft_datetimepicker .xdsoft_calendar td:hover,
    .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
        background-color: $color-link-hover !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
    // 前月、翌月
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
    .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
    .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
        opacity: 0.25;
    }

    // select
    .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
        background-color: $color-link !important;
        color: #fff !important;
        box-shadow: none !important;
    }
    .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
        background-color: $color-link-hover !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
}
/*
select,
body select[size="1"] {

    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1;

    position: relative;
}
*/
